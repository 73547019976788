import Login from './pages/login';
import Admin from './admin';
import Home from './pages/home';
import Congress from './pages/congress';
import Venue from './pages/congress/venue';
import Introduction from './pages/congress/introduction';
import Abstract from './pages/abstract/submission';
import Past from './pages/congress/past';
import About from './pages/about';
import News from './pages/news';

import LateBreaking from './pages/abstract/late-breaking';

import Welcome from './pages/welcome';
import Contact from './pages/contact';

// 注册
import Instruction from './pages/register/instruction';
import Hotel from './pages/register/hotel';
import StepOne from './pages/register/stepOne';
import StepTwo from './pages/register/stepTwo';
import PersonalInformation from './pages/register/personal-infomation';
import RegisterSuccess from './pages/register/success';

// user
import AdminCenter from './admin.center';
import Center from './pages/user/account';
import AbstractUpload from './pages/user/upload';
import AbstractUploadSuccess from './pages/user/upload/success';
import Voucher from './pages/user/voucher';
import Fee from './pages/user/fee';
import PaySuccessResult from './pages/user/fee/pay-success';
import UpdatePassword from './pages/user/updatePassword';
import Forgot from './pages/user/forgot';
import Reset from './pages/user/forgot/reset';
import Reload from './pages/user/reload';
import Await from './pages/user/forgot/await';
import PrePage from './pages/user/pre';
import Common from './common';
import PosterUpload from './pages/poster/upload';

import RegistrationList from './pages/registration/list';
import RegistrationSubmission from './pages/registration/submission';

export default [
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/pay/:type',
        name: '/pay',
        component: PaySuccessResult,
        auth: false,
    },
    {
        path: '/common',
        name: 'common',
        component: Common,
        children: [
            {
                path: '/common/forgot',
                name: '/common/forgot',
                component: Forgot,
            },
            {
                path: '/common/reset/:userEmail',
                name: '/common/reset/userEmail',
                component: Reset,
            },
            {
                path: '/common/await',
                name: '/common/await',
                component: Await,
            },
            {
                path: '/common/reload',
                name: '/common/reload',
                component: Reload,
            },
            {
                path: '/common/order/detail/:orderId',
                name: '/common/reload',
                component: Reset,
            },
            {
                path: '/common/register/success/:params',
                name: '/common/register/success/:params',
                component: RegisterSuccess,
            },
        ],
    },
    {
        path: '/admin',
        name: '/admin',
        component: AdminCenter,
        children: [
            {
                path: '/admin/register/information',
                name: '/admin/register/information',
                component: PersonalInformation,
                auth: true,
            },
            {
                path: '/admin/information/update',
                name: '/admin/information/update',
                component: PersonalInformation,
                auth: true,
            },
            {
                path: '/admin/center',
                name: '/admin/center',
                component: Center,
                auth: true,
            },
            {
                path: '/admin/upload',
                name: '/admin/upload',
                component: AbstractUpload,
                auth: true,
            },
            {
                path: '/admin/upload-update/:id',
                name: '/admin/upload-update',
                component: AbstractUpload,
                auth: true,
            },
            {
                path: '/admin/uploadSuccess',
                name: '/admin/uploadSuccess',
                component: AbstractUploadSuccess,
                auth: true,
            },
            {
                path: '/admin/fee',
                name: '/admin/fee',
                component: Fee,
                auth: true,
            },
            {
                path: '/admin/voucher',
                name: '/admin/voucher',
                component: Voucher,
                auth: true,
            },
            {
                path: '/admin/updatePassword',
                name: '/admin/updatePassword',
                component: UpdatePassword,
                auth: true,
            },
            {
                path: '/admin/pre',
                name: '/admin/pre',
                component: PrePage,
                auth: true,
            },
        ],
    },
    {
        path: '/',
        name: '/',
        component: Admin,
        children: [
            {
                path: '/home',
                name: '/home',
                component: Home,
            },
            {
                path: '/congress/info',
                name: '/congress/info',
                component: Congress,
            },
            {
                path: '/congress/venue',
                name: '/congress/venue',
                component: Venue,
            },
            {
                path: '/congress/introduction',
                name: '/congress/introduction',
                component: Introduction,
            },
            {
                path: '/congress/welcome',
                name: '/congress/welcome',
                component: Welcome,
            },
            {
                path: '/congress/past',
                name: '/congress/past',
                component: Past,
            },
            {
                path: '/about',
                name: '/about',
                component: About,
            },
            {
                path: '/abstract/submission',
                name: '/abstract/submission',
                component: Abstract,
            },
            {
                path: '/abstract/late',
                name: '/abstract/late',
                component: LateBreaking,
            },
            {
                path: '/contact',
                name: '/contact',
                component: Contact,
            },
            {
                path: '/congress/news',
                name: '/congress/news',
                component: News,
            },
            {
                path: '/register/instruction',
                name: '/register/instruction',
                component: Instruction,
            },
            {
                path: '/register/StepOne',
                name: '/register/StepOne',
                component: StepOne,
            },
            {
                path: '/register/StepTwo/:params',
                name: '/register/StepTwo/:params',
                component: StepTwo,
            },
            {
                path: '/register/hotel',
                name: '/register/hotel',
                component: Hotel,
            },
            {
                path: '/registration/list',
                name: 'registration.list',
                component: RegistrationList,
            },
            {
                path: '/registration/submission',
                name: 'registration.submission',
                component: RegistrationSubmission,
            },
            {
                path: '/registration/submission-edit/:id',
                name: 'registration.submission.edit',
                component: RegistrationSubmission,
            },
            {
                path: '/poster/upload',
                name: 'poster.upload',
                auth: true,
                component: PosterUpload,
            },
        ],
    },
];
