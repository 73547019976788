import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Popconfirm, Table } from 'antd';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import {uploadEndDatetime} from '../../../config';
import axios from '../../../axios/api.request';
import './index.less';

class Center extends Component {
    tableColumns = [
        {
            title: 'Title of Abstract',
            dataIndex: 'title_of_abstract',
        },
        {
            title: 'Abstract Category',
            dataIndex: 'abstract_category_name',
        },
        {
            title: 'Keywords',
            dataIndex: 'keywords',
        },
        {
            width: 140,
            title: 'Action',
            align: 'center',
            render: (_, record) => {
                const {showUploadBtn} = this.state;

                if (!showUploadBtn) {
                    return <NavLink
                        to={`/admin/upload-update/${record.id}`}
                        className="ant-btn ant-btn-link ant-btn-sm"
                    >View</NavLink>;
                }

                return (
                    <>
                        <Popconfirm
                            title="Do you want to confirm the deletion ?"
                            onConfirm={() => {
                                axios.request({
                                    url: `/contribute/${record.id}`,
                                    data: {
                                        api_token: this.props.userToken,
                                    },
                                    method: 'DELETE',
                                }).then(rs => {
                                    const { code } = rs.data;

                                    if (code !== 200) {
                                        throw rs.data;
                                    }

                                    this.getItems();
                                }).catch(err => {
                                    Modal.error({
                                        title: 'Error',
                                        content: err.message || 'Deletion failed.',
                                    });
                                });
                            }}
                        >
                            <Button type="link" size="small" danger>Delete</Button>
                        </Popconfirm>
                        <NavLink to={`/admin/upload-update/${record.id}`}
                                 className="ant-btn ant-btn-link ant-btn-sm">Edit</NavLink>
                    </>
                );
            },
        },
    ];

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            total: 0,
            timer: undefined,
            isEdit: false,
            showUploadBtn: false,
        }
    }

    componentDidMount () {
        this.getItems();

        const check = () => {
            const showUploadBtn = Date.now() < uploadEndDatetime.getTime();

            this.setState({
                showUploadBtn: showUploadBtn,
            });

            if (!showUploadBtn) {
                clearInterval(timer);

                this.setState({
                    timer: undefined,
                });
            }
        };

        const timer = setInterval(check, 1000);

        this.setState({
            timer: timer,
        });
    }

    componentWillUnmount () {
        if (this.state.timer) {
            clearInterval(this.state.timer);

            this.setState({
                timer: undefined,
            });
        }
    }

    /**
     * 获取列表数据
     */
    getItems() {
        axios.request({
            url: '/contribute',
            params: {
                api_token: this.props.userToken,
            },
        }).then(rs => {
            const {items, total} = rs.data.data;

            this.setState({
                items: items || [],
                total: total || 0,
            });
        }).catch(err => {
            Modal.error({
                title: 'Error',
                content: err.message || 'The list content query failed.',
            });
        });
    }

    render() {
        return (
            <div className="container pre-page">
                <div className="title">
                    <h1>ABSTRACT UPLOAD</h1>
                    <div className="desc">Deadline of abstract submission is 16 May 2024 24:00（China Standard Time，CST）</div>
                </div>

                {this.state.showUploadBtn && (
                    <div className="actions">
                        <NavLink
                            to="/abstract/submission"
                            disabled={this.state.total >= 5}
                            className="ant-btn ant-btn-primary ant-btn-lg"
                        >
                            <PlusOutlined/> Abstract Upload
                        </NavLink>
                    </div>
                )}

                <Table
                    rowKey="id"
                    columns={this.tableColumns}
                    bordered
                    dataSource={this.state.items}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        userInfo: state.userInfo,
        userToken: state.userToken,
    };
};

export default connect(mapStateToProps)(withRouter(Center));
