import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { uploadEndDatetime } from '../../../config';
import axios from '../../../axios/api.request';

import {
    Button,
    Checkbox,
    Col,
    DatePicker,
    Form,
    Input,
    message,
    Modal,
    notification,
    Radio,
    Row,
    Select,
    // Upload,
} from 'antd';
// import { baseUrl } from '../../../config';
import './index.less';
import { LeftOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';

// const uploadFileApi = `${baseUrl}/upload`;

const defaultAuthorItem = {
    email: undefined,
    city: undefined,
    country: undefined,
    institute: undefined,
    last_name: undefined,
    first_name: undefined,
};

class UploadAbstract extends React.Component {
    state = {
        id: undefined,
        config: {
            country_regions: [],
            abstract_categories: [],
            submission_agreements: [],
        },
        loading: false,
        authors: [],
        editable: Date.now() < uploadEndDatetime.getTime(),
        fileList: [],
        confirmDirty: false,
        submitter_date: '',
        presenter_infos: [],
        presenter_abstract: undefined,
    };

    formRef = React.createRef();

    componentDidMount () {
        axios.request({
            url: '/contribute/options',
            params: {
                api_token: this.props.userToken,
            },
        }).then(rs => {
            const config = rs.data.data;

            this.setState({
                config: {
                    ...this.state.config,
                    ...config,
                },
            });

            this.getUploadDetail();
        }).catch(err => {
            Modal.error({
                title: 'Error',
                content: err.message || 'The options query failed.',
            });
        });
    }

    beforeUpload (file) {
        const handleRemoveThisFile = () => {
            setTimeout(() => {
                const filter = this.state.fileList.filter(v => v.uid === file.uid);

                if (filter.length > 0) {
                    this.setState({
                        fileList: this.state.fileList.filter(v => v.uid !== file.uid),
                    });
                }
            }, 200);
        };

        if (file.type !== 'application/pdf') {
            Modal.warning({
                title: 'This is a Warning Message',
                content: 'You can only upload  pdf max. 50 MB',
            });

            handleRemoveThisFile();

            return false;
        }

        if (file.size / 1024 / 1024 >= 50) {
            Modal.warning({
                title: 'This is a Warning Message',
                content: 'File Must Smaller Than 50MB',
            });

            handleRemoveThisFile();

            return false;
        }

        if (this.state.fileList.length >= 5) {
            Modal.warning({
                title: 'This is a Warning Message',
                content: 'Maximum 5 Files Upload.',
            });

            handleRemoveThisFile();

            return false;
        }

        return true;
    }

    /**
     * 查询投稿详情
     */
    getUploadDetail () {
        const { match } = this.props;

        if (!match.params.id) {
            this.formRef.current.setFieldsValue({
                authors: [{ ...defaultAuthorItem }],
            });

            return;
        }

        axios.request({
            url: `/contribute/${match.params.id}`,
            params: {
                api_token: this.props.userToken,
            },
        }).then(res => {
            if (res.data.code !== 200) {
                throw res.data;
            }

            const detail = res.data.data;

            let authors = [];
            let presenter_infos = [];

            if (detail && detail.authors && detail.authors.length) {
                authors = detail.authors;
            } else {
                authors.push({ ...defaultAuthorItem });
            }

            if (detail && detail.presenter_infos && detail.presenter_infos.length) {
                presenter_infos = detail.presenter_infos;
            } else {
                presenter_infos.push({ ...defaultAuthorItem });
            }

            this.formRef.current.setFieldsValue({
                ...detail,
                authors,
                submitter_date: detail && detail.submitter_date ? moment(detail.submitter_date) : undefined,
                presenter_infos,
            });

            this.setState({
                id: detail && detail.id ? detail.id : undefined,
                fileList: detail && detail.upload_files && detail.upload_files.length ? detail.upload_files.map(v => {
                    return {
                        uid: v,
                        name: v,
                        status: 'done',
                        response: {
                            data: v,
                        },
                    };
                }) : [],
                presenter_abstract: detail && detail.presenter_abstract != null ? detail.presenter_abstract : undefined,
            });
        }).catch(err => {
            Modal.error({
                title: 'Error',
                content: err.message || 'Content query failed.',
                onOk: () => {
                    this.props.history.replace('/admin/pre');
                },
            });
        });
    }

    handleChange (info) {
        let { fileList, file } = info;

        const status = file.status;

        if (status === 'done') {
            fileList = fileList.map(v => {
                if (v.response && v.response.code === 200 && v.name !== v.response.data) {
                    v.name = v.response.data;
                }

                return v;
            });

            this.formRef.current.setFieldsValue({
                upload_files: this.state.fileList,
            });

            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }

        if (status !== 'done') {
            this.setState({
                fileList: [...fileList],
            });
        }
    }

    normFile (e) {
        if (Array.isArray(e)) {
            return e;
        }

        return e && e.fileList;
    }

    onDatePickerChange (date, dateStrings) {
        this.setState({
            submitter_date: dateStrings,
        });
    }

    /**
     * 验证单词数量
     *
     * @param value
     * @param limit
     * @param type max or min
     * @returns {boolean}
     */
    validatorWordsLimit (value, limit, type = 'max') {
        const words = value.replace(/[\W_]+/g, ' ').trim().split(/\s+/);
        const englishWords = words.filter(word => /^[a-zA-Z]+$/.test(word));

        if (type === 'min') {
            return englishWords.length >= limit;
        }

        return englishWords.length <= limit;
    }

    fullNameDom (name, restField) {
        const {editable} = this.state;

        return (
            <>
                <Row gutter={24} type="flex" align="middle">
                    <Col xs={24} sm={24} md={{ span: 12 }}>
                        <Form.Item
                            {...restField}
                            name={[name, 'last_name']}
                            label="Last / Family Name"
                            rules={[
                                {
                                    message: 'Please enter.',
                                    required: true,
                                },
                            ]}
                        >
                            <Input readOnly={!editable} placeholder="Please enter."/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={{ span: 12 }}>
                        <Form.Item
                            {...restField}
                            name={[name, 'first_name']}
                            label="First / Given Name/s"
                            rules={[
                                {
                                    message: 'Please enter.',
                                    required: true,
                                },
                            ]}
                        >
                            <Input readOnly={!editable} placeholder="Please enter."/>
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item
                    {...restField}
                    name={[name, 'email']}
                    label="Contact Email"
                    rules={[
                        {
                            type: 'email',
                            message: 'Please enter the correct email.',
                        },
                        {
                            message: 'Please enter.',
                            required: true,
                        },
                    ]}
                >
                    <Input readOnly={!editable} placeholder="Please enter."/>
                </Form.Item>
            </>
        );
    };

    affiliationDom (name, restField) {
        const {config, editable} = this.state;

        return (
            <Row gutter={24} type="flex" align="middle">
                <Col xs={24} sm={24} md={{ span: 8 }}>
                    <Form.Item
                        {...restField}
                        name={[name, 'institute']}
                        label="Institute"
                        rules={[
                            {
                                message: 'Please enter.',
                                required: true,
                            },
                        ]}
                    >
                        <Input readOnly={!editable} placeholder="Please enter."/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={{ span: 8 }}>
                    <Form.Item
                        {...restField}
                        name={[name, 'city']}
                        label="City"
                        rules={[
                            {
                                message: 'Please enter.',
                                required: true,
                            },
                        ]}
                    >
                        <Input readOnly={!editable} placeholder="Please enter."/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={{ span: 8 }}>
                    <Form.Item
                        {...restField}
                        name={[name, 'country']}
                        label="Country/Region"
                        rules={[
                            {
                                message: 'Please enter.',
                                required: true,
                            },
                        ]}
                    >
                        <Select disabled={!editable} showSearch placeholder="Please select.">
                            {config.country_regions.map((item, index) => (
                                <Select.Option
                                    key={`country-region-item-${index}`}
                                    value={item}
                                >{item}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        );
    };

    render () {
        const {
            config,
            loading,
            // fileList,
            editable,
        } = this.state;

        const { userInfo /*userToken*/ } = this.props;

        return (
            <div className="container abstract-upload">
                {userInfo.pay_status === 2 && (
                    <div className="container" style={{ margin: '0 auto' }}>
                        <ul className="menu-list">
                            <li className="menu"><NavLink to="/admin/center">Personal Information</NavLink></li>
                            <li className="menu active"><NavLink to="/admin/upload">Abstract Upload</NavLink></li>
                            <li><NavLink to="/admin/fee">Payment Detail</NavLink></li>
                        </ul>
                    </div>
                )}

                <h1 className="title">ABSTRACT UPLOAD</h1>

                <div style={{marginBottom: 20}}>
                    <Button
                        type="primary"
                        onClick={() => this.props.history.replace('/admin/pre')}
                    >
                        <LeftOutlined/> Back
                    </Button>
                </div>

                <Row>
                    <Col xs={24} sm={24} md={{ span: 20 }}>
                        <Form
                            ref={this.formRef}
                            layout="vertical"
                            scrollToFirstError
                            onFinish={async values => {
                                if (!editable) {
                                    Modal.error({
                                        title: 'Prompt information',
                                        content: 'Modifications are prohibited.'
                                    });

                                    return;
                                }

                                values.submitter_date = values.submitter_date.format('YYYY-MM-DD');

                                if (values.upload_files && values.upload_files.length) {
                                    delete values.upload_files;

                                    values['upload_files'] = this.state.fileList.map(item => item.response.data);
                                }

                                this.setState({ loading: true });


                                axios.request({
                                    url: this.state.id ? `/contribute/${this.state.id}` : '/contribute',
                                    data: {
                                        ...values,
                                        api_token: this.props.userToken,
                                    },
                                    method: this.state.id ? 'PUT' : 'POST',
                                }).then(res => {
                                    let type = 'success';

                                    if (res.data.code === 200) {
                                        message.success('Upload SUCCESS');

                                        const { history } = this.props;

                                        history.push('/admin/uploadSuccess');
                                    } else {
                                        type = 'warn';
                                    }

                                    notification[type]({
                                        message: 'System tips',
                                        description: res.data && res.data.message ? res.data.message : 'Operation failed.',
                                    });
                                }).catch(err => {
                                    Modal.error({
                                        title: 'Error',
                                        content: err.message || 'Submission failed.',
                                    });
                                }).finally(() => {
                                    this.setState({
                                        loading: false,
                                    });
                                });
                            }}
                            onFinishFailed={() => {
                                Modal.error({
                                    title: 'This is a Warning Message',
                                    content: 'Please complete the form.',
                                });
                            }}
                        >
                            <h2 style={{ marginBottom: 0 }}>Author(s) Information:</h2>
                            <p>Please input information for all authors</p>
                            <div className="authors">
                                <Form.List name="authors">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(({ key, name, ...restField }) => (
                                                <div key={`author-item-${key}`} className="item">
                                                    <h3>Full Name(s)</h3>
                                                    {this.fullNameDom(name, restField)}

                                                    <h3>Affiliation: Registry Name of Institute Name</h3>
                                                    {this.affiliationDom(name, restField)}

                                                    {editable && (
                                                        <div className="actions">
                                                            {fields.length > 1 && (
                                                                <Button
                                                                    type="dashed"
                                                                    danger
                                                                    onClick={() => remove(name)}
                                                                >
                                                                    <MinusOutlined/> Remove
                                                                </Button>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            ))}

                                            {editable && (
                                                <div className="item">
                                                    <div className="actions">
                                                        <Button
                                                            type="primary"
                                                            onClick={() => add()}
                                                        >
                                                            <PlusOutlined/> Add
                                                        </Button>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </Form.List>
                            </div>

                            <h2>Presenter Information (if different from author):</h2>
                            <div>
                                <Form.Item
                                    name="presenter_abstract"
                                    label="Are you the presenter of the abstract ?"
                                    rules={[
                                        {
                                            message: 'Please select.',
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Radio.Group
                                        disabled={!editable}
                                        onChange={e => {
                                            this.setState({
                                                presenter_abstract: e.target.value,
                                            });

                                            if (!this.formRef.current.getFieldValue('presenter_infos')) {
                                                this.formRef.current.setFieldsValue({
                                                    presenter_infos: [{ ...defaultAuthorItem }],
                                                });
                                            }
                                        }}
                                    >
                                        <Radio value={true}>Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </Radio.Group>
                                </Form.Item>

                                {this.state.presenter_abstract === false && (
                                    <div className="authors">
                                        <Form.List name="presenter_infos">
                                            {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map(({ key, name, ...restField }) => (
                                                        <div key={`presenter-item-${key}`} className="item">
                                                            {this.fullNameDom(name, restField)}

                                                            <h3>Affiliation(s)</h3>
                                                            {this.affiliationDom(name, restField)}
                                                        </div>
                                                    ))}
                                                </>
                                            )}
                                        </Form.List>
                                    </div>
                                )}
                            </div>

                            <h2>Abstract Details:</h2>
                            <div>
                                <Form.Item
                                    name="title_of_abstract"
                                    label="Title of Abstract"
                                    extra="write in ALLCAPS, limited to 15 words"
                                    rules={[
                                        {
                                            message: 'Please enter.',
                                            required: true,
                                        },
                                        {
                                            validator: (rule, value) => {
                                                return new Promise((resolve, reject) => {
                                                    if (!value) {
                                                        resolve();
                                                        return;
                                                    }

                                                    if (!this.validatorWordsLimit(value, 15)) {
                                                        reject('Limited to 15 words.');

                                                        return;
                                                    }

                                                    resolve();
                                                });
                                            },
                                        },
                                    ]}
                                >
                                    <Input readOnly={!editable} placeholder="Please enter."/>
                                </Form.Item>
                                <Form.Item
                                    name="abstract_category"
                                    label="Abstract Category"
                                    rules={[
                                        {
                                            message: 'Please select.',
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Select disabled={!editable} placeholder="Select here">
                                        {config.abstract_categories.map((item, index) => {
                                            if (!item.is_group || !item.children) {
                                                return (
                                                    <Select.Option
                                                        key={`abstract-categories-${index}`}
                                                        value={item.value}
                                                    >{item.label}</Select.Option>
                                                );
                                            }

                                            return (
                                                <Select.OptGroup key={`abstract-categories-${index}`}
                                                                 label={item.label}>
                                                    {item.children.map((child, key) => (
                                                        <Select.Option
                                                            key={`abstract-categories-child-${key}`}
                                                            value={child.value}
                                                        >{child.label}</Select.Option>
                                                    ))}
                                                </Select.OptGroup>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="keywords"
                                    label="Keywords"
                                    extra="3-5 keywords"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter.',
                                        },
                                        {
                                            validator: (rule, value) => {
                                                return new Promise((resolve, reject) => {
                                                    if (!value) {
                                                        resolve();
                                                        return;
                                                    }

                                                    if (!this.validatorWordsLimit(value, 3, 'min')) {
                                                        reject('Please enter at least 3 words.');

                                                        return;
                                                    }

                                                    if (!this.validatorWordsLimit(value, 5)) {
                                                        reject('Limited to 5 words.');

                                                        return;
                                                    }

                                                    resolve();
                                                });
                                            },
                                        },
                                    ]}
                                >
                                    <Input readOnly={!editable} placeholder="Please enter."/>
                                </Form.Item>
                                <Form.Item
                                    name="abstract_body"
                                    label="Abstract Body"
                                    extra="limited to a specified word count of 300"
                                    rules={[
                                        {
                                            message: 'Please enter.',
                                            required: true,
                                        },
                                        {
                                            validator: (rule, value) => {
                                                return new Promise((resolve, reject) => {
                                                    if (!value) {
                                                        resolve();
                                                        return;
                                                    }

                                                    if (!this.validatorWordsLimit(value, 300)) {
                                                        reject('Limited to 300 words.');

                                                        return;
                                                    }

                                                    resolve();
                                                });
                                            },
                                        },
                                    ]}
                                >
                                    <Input.TextArea
                                        readOnly={!editable}
                                        autoSize={{ minRows: 4, maxRows: 6 }}
                                        placeholder="Please enter."
                                    />
                                </Form.Item>
                            </div>

                            <h2>Do you intend to apply for the IACR Conference Fellowship ?</h2>
                            <div>
                                <Form.Item
                                    name="financial_support"
                                    label="Would you like to apply for financial support through the IACR conference fellowship program? If yes, have you read the guidelines and qualifications of the programme?"
                                    rules={[
                                        {
                                            message: 'Please select.',
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Radio.Group disabled={!editable}>
                                        <div>
                                            <Radio value={true}>Yes. If yes, please make sure that you have read the
                                                guidelines and will be able to qualify for support: <a
                                                    href="//www.iacr.com.fr/index.php?option=com_content&view=category&layout=blog&id=84&Itemid=542"
                                                    target="_blank" rel="noopener noreferrer">IACR
                                                    Fellowships</a></Radio>
                                        </div>
                                        <div>
                                            <Radio value={false}>No</Radio>
                                        </div>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    name="conference_iacr_fellowship"
                                    label="Will your participation in the conference be dependent on the award of the IACR Fellowship ?"
                                    rules={[
                                        {
                                            message: 'Please select.',
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Radio.Group disabled={!editable}>
                                        <div>
                                            <Radio value={true}>Yes. Aside from the guidelines, please also be aware
                                                that we have limited spots for the fellowships and are subject to
                                                the review of the fellowships committee.</Radio>
                                        </div>
                                        <div>
                                            <Radio value={false}>No</Radio>
                                        </div>
                                    </Radio.Group>
                                </Form.Item>
                            </div>

                            <h2 style={{ marginBottom: 0 }}>Additional Information:</h2>
                            <p>Disclosure of conflict of interest for all authors</p>
                            <div>
                                <Form.Item
                                    name="conflict_of_interest_disclosure"
                                    label="Conflict of Interest Disclosure"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter.',
                                        },
                                    ]}
                                >
                                    <Input.TextArea
                                        readOnly={!editable}
                                        autoSize={{ minRows: 4, maxRows: 6 }}
                                        maxLength={300}
                                        placeholder="Please enter."
                                    />
                                </Form.Item>
                            </div>

                            <h2>Submission Agreement:</h2>
                            <div>
                                <Form.Item
                                    name="agreements"
                                    label="By submitting this abstract, I/we agree to the following"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select.',
                                        },
                                    ]}
                                >
                                    <Checkbox.Group disabled={!editable}>
                                        {config.submission_agreements.map((item, index) => (
                                            <div key={`submission-agreements-${index}`}
                                                 style={{ marginTop: index > 0 ? 5 : 0 }}>
                                                <Checkbox value={item.value}>{item.label}</Checkbox>
                                            </div>
                                        ))}
                                    </Checkbox.Group>
                                </Form.Item>
                            </div>

                            <h2>Submitter's Declaration:</h2>
                            <div>
                                <Form.Item
                                    name="submitter_name"
                                    label="Submitter's Name (if different from author/presenter)"
                                >
                                    <Input readOnly={!editable} placeholder="Please enter."/>
                                </Form.Item>
                                <Form.Item
                                    name="submitter_date"
                                    label="Date"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select.',
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        mode="date"
                                        format="YYYY-MM-DD"
                                        disabled={!editable}
                                        placeholder="Please selecte."
                                        style={{ width: '100%' }}
                                        onChange={this.onDatePickerChange.bind(this)}
                                    />
                                </Form.Item>
                            </div>

                            {/*<Form.Item
                             name="upload_files"
                             label="File Upload"
                             extra="※ Only PDF files are supported for upload."
                             className="upload-list"
                             initialValue={fileList || null}
                             valuePropName="upload_files"
                             getValueFromEvent={this.normFile.bind(this)}
                             >
                             <Upload
                             name="file"
                             data={{ api_token: userToken }}
                             accept=".pdf"
                             method="post"
                             action={uploadFileApi}
                             multiple={false}
                             listType="picture"
                             fileList={fileList}
                             onChange={this.handleChange.bind(this)}
                             beforeUpload={this.beforeUpload.bind(this)}
                             >
                             <Button type="primary" className="md-btn">
                             Upload File <UploadOutlined/>
                             </Button>
                             </Upload>
                             </Form.Item>*/}

                            <Form.Item
                                wrapperCol={{
                                    xs: {
                                        span: 24,
                                        offset: 0,
                                    },
                                    sm: {
                                        span: 16,
                                        offset: 8,
                                    },
                                    md: {
                                        span: 8,
                                        offset: 8,
                                    },
                                }}
                            >
                                <Button
                                    type="primary"
                                    block
                                    loading={loading}
                                    disabled={!editable}
                                    htmlType="submit"
                                    className="submit-btn"
                                >Submit</Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        userInfo: state.userInfo,
        userToken: state.userToken,
    };
};

export default connect(mapStateToProps)(UploadAbstract);
