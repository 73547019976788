import React, { Component } from 'react';
import { Table, Button, Select, Modal, notification, Alert } from 'antd';
import axios from '../../../axios/api.request';
import { connect } from 'react-redux';
import './index.less';
import { userInfo } from '../../../redux/action';
import { NavLink } from 'react-router-dom';
import { Resizable } from 'react-resizable';
import 'react-resizable/css/styles.css';
import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';

const { Option } = Select;

const ResizeableTitle = props => {
    const { onResize, width, ...restProps } = props;

    if (!width) {
        return <th {...restProps} />;
    }

    return (
        <Resizable width={width} height={0} onResize={onResize}>
            <th {...restProps} />
        </Resizable>
    );
};

class Fee extends Component {

    constructor (props) {
        super(props);

        this.state = {
            expire: 1,
            editingKey: '', loading: false,
            columns: [
                {
                    title: 'Register',
                    dataIndex: 'register',
                    key: 'register',
                },
                {
                    title: 'Registration  No.',
                    dataIndex: 'no',
                    key: 'no',
                },
                {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: 'Registration Fee',
                    dataIndex: 'fee',
                    key: 'fee',
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    render: fee => <a>$ {fee}</a>,
                },
            ],
        };

        this.columns1 = [
            {
                title: 'Register',
                dataIndex: 'register',
                key: 'register',
                width: '20%',
            },
            {
                title: 'Registration  No.',
                dataIndex: 'no',
                key: 'no',
                width: '20%',
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                width: '20%',
            },
            {
                title: 'Gala Dinner',
                dataIndex: 'gala_dinner',
                key: 'gala_dinner',
                width: '20%',
                render: (gala_dinner, row) => (
                    this.state.expire === 1 ? <Select defaultValue={gala_dinner} style={{ width: 100 }}
                                                      onChange={this.handleChange.bind(null, row, 'gala_dinner')}>
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                    </Select> : gala_dinner
                ),
            },
            {
                title: 'Working Dinner',
                dataIndex: 'working_lunch',
                key: 'working_lunch',
                width: '20%',
                render: (working_lunch, row) => (
                    this.state.expire === 1 ? <Select defaultValue={working_lunch}
                                                      style={{ width: 100, borderBottom: 0 }}
                                                      onChange={this.handleChange.bind(null, row, 'working_lunch')}>
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                    </Select> : working_lunch
                ),
            },
        ];
    }

    handleChange = (row, key, value) => {
        let data = {
            api_token: this.props.userToken,
            id: row.id,
            type: row.type,
            [key]: value,
        };
        axios.request({
            method: 'post',
            url: '/update_repast',
            data,
        }).then(res => {
            let type = 'success';
            if (res.data.code === 200) {
                this.getTableData();
            } else {
                type = 'error';
            }
            notification[type](
                {
                    message: 'System tips',
                    description: res.data.message || 'Edit success!',
                },
            );
        });
    };

    components = {
        header: {
            cell: ResizeableTitle,
        },
    };

    handleResize = index => (e, { size }) => {
        this.setState(({ columns }) => {
            const nextColumns = [...columns];
            nextColumns[index] = {
                ...nextColumns[index],
                width: size.width,
            };
            return { columns: nextColumns };
        });
    };

    componentWillMount () {
        const { dispatch, history } = this.props;

        dispatch(userInfo(this.props.userToken));

        const { pay_status } = this.props.userInfo;

        // 已缴纳注册费
        if (pay_status === 2) {
            history.push('/admin/pre');
        }
    }

    componentDidMount () {
        this.getTableData();
    }

    // 获取注册费
    getTableData = () => {
        axios.request({
            url: '/cost_detail?api_token=' + this.props.userToken,
        }).then(res => {
            if (res.status === 200 && res.data.code === 200) {
                let ret = res.data.data;
                ret.dinner.map(v => {
                    v['key'] = v.id;
                    return v;
                });
                ret.register.map(v => {
                    v['key'] = v.no;
                    return v;
                });
                this.setState({ ...ret });
            }
        });
    };

    newWin = (url, id) => {
        var a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('target', '_blank');
        a.setAttribute('id', id);
        // 防止反复添加
        if (!document.getElementById(id)) {
            document.body.appendChild(a);
        }
        a.click();
    };

    openNewTab (link) {
        let frm = document.createElement('form');
        frm.setAttribute('method', 'get');
        frm.setAttribute('action', link);
        frm.setAttribute('target', '_blank');
        document.body.appendChild(frm);
        // var frm = $('<form   method="get" action="' + link + '" target="_blank"></form>');
        //  $("body").append(frm);
        frm.submit().remove();
    }

    //  支付
    handelPay = (type) => {
        if (type === 2 && this.state.pay_status === 1) {
            Modal.warning({
                title: 'tip',
                content: 'Please pay the registration fee first.',
                onOk: function () {
                    Modal.destroyAll();
                },
            });
            return false;
        }
        this.setState({ loading: true });
        axios.request({
            method: 'post',
            url: '/payment?api_token=' + this.props.userToken,
            data: {
                type: type,
            },
        }).then(res => {
            this.setState({ loading: false });
            Modal.destroyAll();
            if (res.data.code === 200) {
                let uri = res.data.data.url;
                Modal.confirm({
                    title: 'Tip',
                    content: 'Jump to Payment Page',
                    onOk: function () {
                        window.open(uri, '_blank');
                    },
                });
            }

        }).catch(err => {
            console.log(err, 'err----');
            this.setState({ loading: false });

        });
    };

    edit (key) {
        this.setState({ editingKey: key });
    }

    hideModal = () => {

    };

    render () {
        let { expire } = this.state;
        let { userInfo } = this.props;

        const columns = this.state.columns.map((col, index) => ({
            ...col,
            onHeaderCell: column => ({
                width: column.width,
                onResize: this.handleResize(index),
            }),
        }));

        return (
            <div className="fee-page">
                <Modal
                    className="loading-modal"
                    visible={this.state.loading}
                    centered
                    okText="确认"
                    cancelText="取消"
                    footer={null}
                    closable={false}
                    maskClosable={false}
                    destroyOnClose={true}
                    keyboard={false}
                    bodyStyle={{
                        background: 'transparent',
                        textAlign: 'center',
                    }}

                >
                    <LoadingOutlined style={{ fontSize: '60px', color: '#fff' }}/> &nbsp;&nbsp;Loading ...
                </Modal>
                {
                    userInfo.pay_status === 2 ? <div className="container">
                        <ul className="menu-list">
                            <li className="menu "><NavLink to="/admin/center">Personal Information</NavLink></li>
                            <li className="menu "><NavLink to="/admin/upload">Abstract Upload</NavLink></li>
                            <li className="menu active"><NavLink to="/admin/fee">Payment Detail</NavLink></li>
                        </ul>
                    </div> : ''
                }

                <h1 className="title">PAYMENT DETAIL</h1>

                <section className="paid-info border-bottom">
                    <div className="container inner">
                        {userInfo.pay_status === 1 && (
                            <div style={{ marginBottom: '15px' }}>
                                <Alert
                                    icon={<InfoCircleOutlined/>}
                                    type="error"
                                    showIcon
                                    description={<p className="red-bold">PLEASE PAY THE REGISTRATION FEE TO ATTEND OESO 2019 CONFERENCE.</p>}
                                />
                            </div>
                        )}

                        <dl className="clearfix">
                            <dd>Your registration number</dd>
                            <dt>{userInfo.register_code}</dt>
                        </dl>
                        <dl>
                            <dd>Registration type</dd>
                            <dt>{userInfo.regiatration_type}</dt>
                        </dl>
                    </div>
                </section>
                <section className="table-wrap ">
                    <div className="container inner">
                        <h3 className="subject-title left-line">Registration Fee <span
                            className={userInfo.pay_status === 1 ? 'unpaid' : 'paid'}>{userInfo.pay_status === 1 ? 'Unpaid' : 'Paid'}</span>
                        </h3>
                        <Table pagination={false} components={this.components} dataSource={userInfo.register}
                               columns={columns}/>
                        <h3 className="total">
                            <span>Total <strong>$ {userInfo.register_fee ? userInfo.register_fee : 0}</strong></span>
                            {userInfo.pay_status === 1 ?
                                <Button className="pay-btn" type="primary"
                                        onClick={() => this.handelPay(1)}>Checkout</Button> :
                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                <a className="pay-btn paid">Checkout</a>}
                        </h3>
                    </div>
                </section>
                <section className="table-wrap ">
                    <div className="container inner">
                        <h3 className="subject-title left-line">Payment Instruction</h3>
                        <p style={{ paddingLeft: '25x' }}>Payments are processed via Paypal. You can process the payment
                            by
                            both credit card and paypal account. Please note all service charges need to be covered by
                            the
                            delegate.</p>
                    </div>
                </section>
                <section className="table-wrap ">
                    <div className="container inner">
                        <h3 className="subject-title left-line">Dinner <span
                            className={expire === 1 ? 'unpaid' : 'paid'}>{expire === 1 ? 'Unpaid' : 'Paid'}</span>
                        </h3>
                        <p>※ Please pay before October 21, 2019</p>
                        <Table pagination={false} dataSource={userInfo.dinner} columns={this.columns1}
                               rowClassName="editable-row"/>
                        <h3
                            className="total"> Total <strong>$ {userInfo.dinner_fee ? userInfo.dinner_fee : 0}</strong>
                            {expire === 1 && userInfo.dinner_fee ?
                                <Button className="pay-btn" type="primary"
                                        onClick={() => this.handelPay(2)}>Checkout</Button> :
                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                <a className="pay-btn paid">Checkout</a>}
                        </h3>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        userToken: state.userToken,
        userInfo: state.userInfo,
    };
};
export default connect(mapStateToProps)(Fee);
