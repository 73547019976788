// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../resource/images/zhuceshuoming_pic.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "/** 常用色值 **/\n/** 常用字体大小 **/\n/** 常用宽度 **/\n.steptwo {\n  font-size: 16px;\n}\n.steptwo .explain {\n  margin: 0.3rem 0 0.5rem 0;\n}\n.steptwo i {\n  font-style: normal;\n}\n.steptwo .steps-wrap {\n  margin-bottom: 0.6rem;\n}\n.steptwo .subject-title {\n  margin: 60px 0  40px 0;\n}\n.steptwo .include-dl {\n  text-align: center;\n}\n.steptwo .include-dl dt {\n  font-size: 0.48rem;\n  font-family: AvenirLTStd-Medium;\n  font-weight: normal;\n  color: #333;\n  opacity: 0.2;\n  position: relative;\n}\n.steptwo .include-dl dt::after {\n  content: '';\n  display: inline-block;\n  height: 2px;\n  width: 8px;\n  background: #000;\n  position: absolute;\n  left: 50%;\n  bottom: 0;\n  transform: translateX(-50%);\n}\n.steptwo .include-dl dd {\n  font-size: 14px;\n  padding: 15px;\n}\n.steptwo .sm-title {\n  font-size: 16px;\n  font-family: AvenirLTStd-Medium;\n  font-weight: normal;\n  color: #2258a7;\n  margin: 15px 0;\n  display: block;\n}\n.steptwo .ad-bar {\n  height: 486px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: 100% 100%;\n  background-repeat: no-repeat;\n}\n.steptwo .categories li {\n  line-height: 2;\n  font-size: #9cb3c5;\n}\n.steptwo .registration-files-wrap {\n  background: #333;\n  padding: 0.5rem 0;\n  line-height: 1;\n}\n.steptwo .registration-files-wrap .subject-title {\n  color: #ffffff;\n  margin: 0;\n}\n.steptwo .next-btn_div {\n  text-align: center;\n  margin: 50px auto;\n}\n.steptwo .next-btn {\n  border-radius: 0;\n  background: #2258A7;\n  border-color: #2258A7;\n}\n.steptwo .prev-btn_div {\n  text-align: left;\n  margin: 50px auto;\n}\n", ""]);
// Exports
module.exports = exports;
