import React, {Component} from 'react';
import {Steps, Table, Row, Col, Card, Button, Carousel, Alert} from 'antd';
import {connect} from 'react-redux';
import sourceData from './data'
import './index.less';
import { InfoCircleOutlined, LeftOutlined, MessageOutlined, PhoneOutlined, RightOutlined } from '@ant-design/icons';

const {
          columns,
          data_src,
          arr,
          register_file,
          descript,
          relationship1,
          relationship2,
          relationship3,
          relationship4,
          relationship5,
          relationship6,
          listData,
      } = sourceData;

const Step = Steps.Step;


class Instruction extends Component {

    next() {
        window.location.href = '#register/StepOne';
    }

    handlePrev = () => {
        this.refs.swipe.prev();
    };

    handleNext = () => {
        this.refs.swipe.next();
    };

    renderCarousel() {
        var groups = arr;
        let settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            initialSlide: 1,
            focusOnSelect: true,
        };
        var result3 = window.matchMedia('(max-width:768px)');
        if (result3.matches) {
            settings.slidesToShow = 1;
        }
        return (
          <div className='carousel-wrapper'>
              <label className="pre" onClick={this.handlePrev} style={{fontSize: '0.42rem'}}>
                  <LeftOutlined/>
              </label>
              <label className="next" onClick={this.handleNext} style={{fontSize: '0.42rem'}}>
                  <RightOutlined/>
              </label>
              <Carousel {...settings} ref="swipe">
                  {
                      groups.map((items, key) => {
                          return <dl key={key} className="include-dl">
                              <dt>{items._key}</dt>
                              <dd>{items.content}</dd>
                          </dl>
                      })

                  }
              </Carousel>

          </div>
        )
    }

    render() {
        return (
          <div className="instruction">
              <div className="">
                  <h1 className="title">REGISTRATION</h1>
                  <div className="container steps-wrap">
                      <Steps progressDot current={0}>
                          <Step title="Instruction"/>
                          <Step title="Email Registration"/>
                          <Step title="Personal Infomation"/>
                          <Step title="Complete"/>
                      </Steps>
                  </div>
                  <section className="container">
                      <h3 className="subject-title left-line">Registration Fee</h3>
                      <div style={{marginBottom:"25px"}}>
                          <Alert
                              showIcon
                              icon={<InfoCircleOutlined/>}
                              description={<p className="red-bold">PLEASE PAY THE REGISTRATION FEE TO ATTEND OESO 2019 CONFERENCE.</p>}
                              type="error"
                              message=""
                          />
                      </div>
                      <div>
                          <Table bordered={false} pagination={false} columns={columns} dataSource={data_src}
                                 size="small"/>
                          <hr style={{marginBottom: "0.20rem"}}/>
                          <div className="relax">
                              <p>The above listed fees include payment service charges and a ticket for WELCOMING RECEPTION on November 7, 7:00 pm.</p>
                          </div>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <div><a className="sm-title">{descript}</a></div>
                          <ul className="categories">
                              <li><i>①</i>{relationship1}</li>
                              <li><i>②</i>{relationship2}</li>
                              <li><i>③</i>{relationship3}</li>
                              <li><i>④</i>{relationship4}</li>
                              <li><i>⑤</i>{relationship5}</li>
                              <li><i>⑥</i>{relationship6}</li>
                          </ul>
                      </div>
                  </section>
                  <section className="payment-section">
                      <div className="payment container">
                          <h3 className="subject-title left-line"
                              style={{margin: 0, fontSize: "30px", marginBottom: "20px"}}>Online Payment</h3>
                          <dl className="first-dl">
                              <dd>
                                  <ul>
                                      <li>Please log in your OESO account, process the registration and pay for the registration fee as showed on the webpage. All service charges need to be covered by the delegate.
                                      </li>
                                      <li>Payments are processed via Paypal. You can process the payment by both credit card and paypal account.</li>
                                  </ul>
                              </dd>
                          </dl>
                      </div>
                  </section>

                  <section className="container">
                      <h3 className="subject-title left-line">Full OESO2019 congress registration fees include</h3>
                      {this.renderCarousel()}
                  </section>
              </div>
              <section className="ad">
                  <img className="full-width" src={require('../../../resource/images/zhuceshuoming_pic.png')} alt="" />
              </section>
              <div>
                  <section className="container">
                      <h3 className="subject-title left-line">Congress Dinner</h3>
                      <div className="dinner-list">
                          <dl>
                              <dt className="title-absolute"><div className="title-text">Working Dinner</div></dt>
                              <dd>
                                  <ul>
                                      <li>Thursday – November 7, 2019 (19:00-21:00)</li>
                                      <li>Conference hall B, China National Convention Center, Beijing</li>
                                      <li>Tickets: NOT INCLUDED in the delegates´ registrations</li>
                                      <li>Extra ticket: 70 USD</li>
                                      <li>Contents: Delicious Chinese food, deep conversation and random communication with colleagues in China and from all over the world.</li>
                                  </ul>
                              </dd>
                          </dl>
                          <dl>
                              <dt className="title-absolute"><div className="title-text">Gala Dinner</div></dt>
                              <dd>
                                  <ul>
                                      <li>Friday – November 8, 2019 (19:30 - 21:30)</li>
                                      <li>Multi-function Room A+B, China National Convention Center, Beijing</li>
                                      <li>Tickets: NOT INCLUDED in the delegates´ registrations</li>
                                      <li>Extra ticket: 120 USD</li>
                                      <li>Dress code: Smart causal</li>
                                      <li>Contents:An interesting program will be proposed, with traditional Chinese songs, Chinese dances, and Beijing Opera.</li>
                                  </ul>
                              </dd>
                          </dl>
                          <div className="relax">
                              <p>Take some time to relax, reconnect with friends and meet new colleagues during this
                                  unforgettable
                                  cultural event.</p>
                              <p>Experience the city of Beijing while enjoying dinner and entertainment, showcasing
                                  Beijing’s cuisine
                                  and arts. </p>
                          </div>
                      </div>
                  </section>
                  <section>
                      <Card className="payment-card" bordered={false}>
                          {
                              listData.map((item, index) => {
                                  return <div key={index}>
                                      <dl className="list-dl container">
                                          <dt className={'list-dt' + index}>{item.title}</dt>
                                          <dd>
                                              <div dangerouslySetInnerHTML={{__html: item.content}}></div>
                                          </dd>
                                      </dl>
                                  </div>
                              })
                          }
                      </Card>
                  </section>
              </div>
              <img className="registration-ad-bar" src={register_file} alt="" />
              <Card bordered={false} className="service container">
                  <Row gutter={24} type="flex" align="middle" justify="space-between">
                      <Col md={{span: 12}} xs={24} sm={24}>
                          <h3 className="md-30-title">OESO World Congress 2019 registration customer service</h3>
                          <h3 className="title-absolute">
                              <div className="title-text">OESO Global Operations Center</div>
                          </h3>
                          <p>
                              <span style={{marginRight: '0.20rem'}}>
                                  <PhoneOutlined/> +86 15120032285
                              </span>
                              <span>
                                  <MessageOutlined/> OESO2019@sina.com
                              </span>
                          </p>
                      </Col>
                      <Col md={{span: 10, offset: 2}} xs={24} sm={24}>
                          <img className="zhuceshuoming_image" alt="logo"
                               src={require('../../../resource/images/zhuceshuoming_image.png')}/>
                      </Col>
                  </Row>
                  <Row className="next-btn_div" style={{margin: '66px 0'}}>
                      <Col xs={24} sm={24} md={{span: 6, offset: 9}}>
                          {
                              this.props.userToken ? '' :
                                <Button size="large" className="next-btn" block type="primary"
                                        onClick={this.next}>Next</Button>
                          }

                      </Col>
                  </Row>
              </Card>
          </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        userToken: state.userToken
    }
};
export default connect(mapStateToProps)(Instruction)
