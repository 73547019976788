import React, {Component} from 'react';
import {Carousel} from 'antd';
import './index.less'
import logo1 from '../../../../resource/images/home-banner-logos/1.png';
import logo2 from '../../../../resource/images/home-banner-logos/2.png';
import logo3 from '../../../../resource/images/home-banner-logos/3.png';
import logo4 from '../../../../resource/images/home-banner-logos/4.png';

export default class Banner extends Component {
    render() {
        return (
          <Carousel autoplay>
              {
                  this.props.imgUrls.map((item, index) => {
                      return (
                          <div key={`banner-item-${index}`}>
                              <img
                                  alt="图片"
                                  src={item}
                                  style={{width: '100%'}}
                                  useMap="#bannerMap"
                              />

                              <div className="banner-logos">
                                  <div className="item">
                                      <a href="http://www.cicams.ac.cn/" title="NCC国家癌症中心" target="_blank" rel="noopener noreferrer">
                                          <img src={logo1} alt="NCC国家癌症中心"/>
                                      </a>
                                  </div>
                                  <div className="item">
                                      <a href="http://www.IACR.com.fr/" title="IACR" target="_blank" rel="noopener noreferrer">
                                          <img src={logo2} alt="IACR"/>
                                      </a>
                                  </div>
                                  <div className="item">
                                      <a href="http://www.iarc.who.int/" title="World Health Organization" target="_blank" rel="noopener noreferrer">
                                          <img src={logo3} alt="World Health Organization"/>
                                      </a>
                                  </div>
                                  <div className="item">
                                      <a href="http://www.baoo.org.cn/" title="北京肿瘤学会" target="_blank" rel="noopener noreferrer">
                                          <img src={logo4} alt="北京肿瘤学会"/>
                                      </a>
                                  </div>
                              </div>

                              {/*<map name="bannerMap">
                                  <area
                                      shape="rect"
                                      alt=""
                                      title=""
                                      coords="72,70,454,125"
                                      href=""
                                      target="_blank"
                                  />
                                  <area
                                      shape="rect"
                                      alt=""
                                      title=""
                                      coords="485,53,765,140"
                                      href=""
                                      target="_blank"
                                  />
                                  <area
                                      shape="rect"
                                      alt=""
                                      title=""
                                      coords="795,58,1240,137"
                                      href=""
                                      target="_blank"
                                  />
                                  <area
                                      shape="rect"
                                      alt=""
                                      title=""
                                      coords="1272,58,1583,132"
                                      href=""
                                      target="_blank"
                                  />
                              </map>*/}
                          </div>
                      );
                  })
              }
          </Carousel>
        )
    }
}
