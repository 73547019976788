import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Row,
    Col,
    Card,
    Form,
    Input,
    Button,
    Radio,
    Select,
    Steps,
    notification,
    Upload,
    message,
    Badge,
} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import axios from '../../../axios/api.request';
import sourceData from './data';
import { baseUrl } from '../../../config';
import { userInfo } from '../../../redux/action';
import { withRouter } from 'react-router';
import './index.less';
import { ArrowLeftOutlined, DeleteOutlined, MinusCircleFilled, PlusOutlined, UploadOutlined } from '@ant-design/icons';

const Option = Select.Option;
const Step = Steps.Step;
const { countrys, relationship } = sourceData;

const countrys_elements = [];
for (let i = 0; i < countrys.length; i++) {
    countrys_elements.push(<Option key={countrys[i]} value={countrys[i]}>{countrys[i]}</Option>);
}

function beforeUpload (file) {
    const isJPG = file.type === 'image/jpeg';
    const isPNG = file.type === 'image/png';
    const isPDF = file.type === 'application/pdf';

    const isPic = isJPG || isPNG || isPDF;

    if (!isPic) {
        message.error('You can only upload JPG, PDF PNG,file!');
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
        message.error('Image must smaller than 5MB!');
    }
    return isPic && isLt5M;
}

class StepOne extends Component {
    state = {
        fileList: [],
    };

    formRef = React.createRef();

    constructor (props) {
        super(props);
        this.state = {
            showStep: 0,
            accompanyCount: 1,
            groupPersonCount: 10,
            hasWithAccompanying: '',
            registerTypeFeeListData: [],
            loading: false,
            formData: {},
        };
        let { dispatch, userToken } = this.props;
        dispatch(userInfo(userToken));
    }

    componentWillMount () {
        let { userToken } = this.props;
        let { history } = this.props;
        if (!userToken) { // 若已填写，则不允许再次填写个人信息
            history.push('/register/instruction');
            return false;
        }
        this.renderUpdateData();
        this.getRegisterTypeFeeListData();
    }

    // 更新用户信息
    renderUpdateData = () => {
        let { userInfo } = this.props;
        this.setState({
            formData: { ...userInfo },
        });
    };

    handleSubmit = () => {
        this.formRef.current.validateFields().then((values) => {
            if (this.state.showStep === 3 || this.state.showStep === 4) {
                let upload_file = [];
                let fileList = this.state.fileList || [];

                fileList.forEach(item => {
                    upload_file.push(item.response.data);
                });

                if (!upload_file.length) {
                    notification.warn({
                        message: 'System Prompt',
                        description: 'Please Upload Young Investigator File'
                    });

                    return;
                }

                if (this.state.showStep === 3) {
                    values['young_investigator_files'] = upload_file;
                } else {
                    values['fellow_files'] = upload_file;
                }
            }

            values['api_token'] = this.props.userToken;

            this.setState({ loading: true });

            axios.request({
                url: '/register',
                data: values,
                method: 'post',
            }).then((res) => {
                let resData = res.data;
                if (resData.code === 200) {
                    const { dispatch, history } = this.props;
                    dispatch(userInfo(this.props.userToken, 1));
                    history.push('/admin/fee');
                }

                this.setState({ loading: false });

                notification.info({
                    message: 'system notice',
                    description: res.data.message || '',
                });
            });
        });
    };

    getRegisterTypeFeeListData () {
        axios.request({
            url: '/register_type_fee',
            method: 'get',
        }).then((res) => {
            let resData = res.data;
            if (resData.code === 200) {
                this.setState({
                    registerTypeFeeListData: resData.data || [],
                });
            }

        });
    }

    sendMail = (val) => {
        let params = [];
        params.email = val;
        axios.request({
            url: '/send',
            method: 'post',
            data: params,
        });
    };

    back = () => {
        // window.location.href = '#/register/instruction';
        let { history } = this.props;
        history.goBack();
    };

    addAccoompany = () => {
        this.setState({ accompanyCount: this.state.accompanyCount + 1 });
    };
    delAccompanyingPerson = () => {
        this.setState({ accompanyCount: this.state.accompanyCount - 1 });
    };
    addGroup = () => {
        this.setState({ groupPersonCount: this.state.groupPersonCount + 1 });
    };

    delMember = () => {
        this.setState({ groupPersonCount: this.state.groupPersonCount - 1 });
    };

    registrationChange = (value) => {
        this.setState({
            showStep: value,
        });
    };
    withAccompanyingChange = (value) => {
        this.setState({
            hasWithAccompanying: value,
        });
    };
    compareToFirstPassword = (rule, value, callback) => {
        if (value && value !== this.formRef.current.getFieldValue('password')) {
            callback('Two passwords that you enter is inconsistent!');
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule, value, callback) => {
        if (value && this.state.confirmDirty) {
            this.formRef.current.validateFields(['confirm']);
        }

        callback();
    };

    getGroupInfoFields () {
        const children = [];
        for (let i = 0; i < this.state.groupPersonCount - 1; i++) {
            children.push(
                <Row gutter={24} type="flex" align="middle" key={i + 1}>
                    <Col span={3}>
                        {
                            i <= 8 ? <Button className="num-btn">NO.{i + 1}</Button> :
                                <Badge count={(
                                    <MinusCircleFilled
                                        onClick={this.delMember}
                                        style={{ color: '#CACACA', cursor: 'pointer' }}
                                    />
                                )}>
                                    <Button
                                        onClick={this.delMember}
                                        className="num-btn num-btn-primary"
                                    >NO.{i + 1} </Button>
                                </Badge>
                        }
                    </Col>
                    <Col md={5} xs={24} sm={24} span={5}>
                        <Form.Item
                            name={`groups[${i}].first_name`}
                            label="First Name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Input first name!',
                                },
                            ]}
                        >
                            <Input placeholder="please input first name"/>
                        </Form.Item>
                    </Col>
                    <Col md={5} xs={24} sm={24} span={5}>
                        <Form.Item
                            name={`groups[${i}].middle_initial`}
                            label="Middle Name"
                            rules={[
                                {
                                    required: false,
                                    message: 'Input middle name!',
                                },
                            ]}
                        >
                            <Input placeholder="please input middle name"/>
                        </Form.Item>
                    </Col>
                    <Col md={5} xs={24} sm={24} span={5}>
                        <Form.Item
                            name={`groups[${i}].last_name`}
                            label="Last Name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Input last name!',
                                },
                            ]}
                        >
                            <Input placeholder="please input last name"/>
                        </Form.Item>
                    </Col>
                    <Col md={3} xs={24} sm={24} span={5}>
                        <Form.Item
                            name={`groups[${i}].gala_dinner`}
                            label="Gala Dinner"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select Gala Dinner!',
                                },
                            ]}
                        >
                            <Radio.Group>
                                <Radio value="Yes">Yes</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col md={3} xs={24} sm={24} span={5}>
                        <Form.Item
                            name={`groups[${i}].working_lunch`}
                            label="Working Dinner"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select Working Dinner!',
                                },
                            ]}
                        >
                            <Radio.Group>
                                <Radio value="Yes">Yes</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>,
            );
        }

        children.push(
            <Row gutter={24} key="add">
                <Col md={{ span: 2 }} xs={24} sm={24} span={6}>
                    <Button
                        style={{ height: '0.42rem', lineHeight: '0.42rem', borderRadius: '4px', paddingRight: '8px' }}
                        className="submit-btn" block type="primary"
                        onClick={this.addGroup}><PlusOutlined/> Add</Button>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name="company_represent"
                        label="Company Representing"
                        rules={[
                            {
                                required: true,
                                message: 'Input Company Representing!',
                            },
                        ]}
                    >
                        <Input placeholder="please input Company Representing"/>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name="address_one"
                        label="Address 1"
                        rules={[
                            {
                                required: true,
                                message: 'Input Address 1!',
                            },
                        ]}
                    >
                        <Input placeholder="please input Address 1"/>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name="address_two"
                        label="Address 2"
                        rules={[
                            {
                                required: false,
                                message: 'Input Address 2!',
                            },
                        ]}
                    >
                        <Input placeholder="please input Address 2"/>
                    </Form.Item>
                </Col>
            </Row>,
        );
        return children;
    }

    getAccompanyInfoFields () {
        const children = [];

        for (let i = 0; i < this.state.accompanyCount; i++) {
            children.push(
                <Col md={8} xs={24} sm={24} span={8} key={1}>
                    <Form.Item
                        name={`accompany[${i}].first_name`}
                        label="First Name"
                        rules={[
                            {
                                required: true,
                                message: 'Input first name!',
                            },
                        ]}
                    >
                        <Input placeholder="please input first name"/>
                    </Form.Item>
                </Col>,
            );
            children.push(
                <Col md={8} xs={24} sm={24} span={8} key={2}>
                    <Form.Item
                        name={`accompany[${i}].middle_initial`}
                        label="Middle Name"
                        rules={[
                            {
                                required: false,
                                message: 'Input middle name!',
                            },
                        ]}
                    >
                        <Input placeholder="please input middle name"/>
                    </Form.Item>
                </Col>,
            );
            children.push(
                <Col md={8} xs={24} sm={24} span={8} key={3}>
                    <Form.Item
                        name={`accompany[${i}].last_name`}
                        label="Last Name"
                        rules={[
                            {
                                required: true,
                                message: 'Input last name!',
                            },
                        ]}
                    >
                        <Input placeholder="please input last name"/>
                    </Form.Item>
                </Col>,
            );

            children.push(
                <Col md={8} xs={24} sm={24} span={8} key={4}>
                    <Form.Item
                        name={`accompany[${i}].relationship`}
                        label="Relationship with You"
                        rules={[
                            {
                                required: true,
                                message: 'select relationship!',
                            },
                        ]}
                    >
                        <Select>
                            <Option value={relationship[0]}>{relationship[0]}</Option>
                            <Option value={relationship[1]}>{relationship[1]}</Option>
                            <Option value={relationship[2]}>{relationship[2]}</Option>
                            <Option value={relationship[3]}>{relationship[3]}</Option>
                            <Option value={relationship[4]}>{relationship[4]}</Option>
                        </Select>
                    </Form.Item>
                </Col>,
            );
            children.push(
                <Col md={8} xs={24} sm={24} span={8} key={5}>
                    <Form.Item
                        name={`accompany[${i}].occupation`}
                        label="Occupation/Title"
                        rules={[
                            {
                                required: true,
                                message: 'Input Occupation/Title!',
                            },
                        ]}
                    >
                        <Input placeholder="please input Occupation/Title"/>
                    </Form.Item>
                </Col>,
            );
            children.push(
                <Col md={8} xs={24} sm={24} span={8} key={6}>
                    <Form.Item
                        name={`accompany[${i}].organization`}
                        label="Organization Name"
                        rules={[
                            {
                                required: true,
                                message: 'Input organization name!',
                            },
                        ]}
                    >
                        <Input placeholder="please input organization name"/>
                    </Form.Item>
                </Col>,
            );

            children.push(
                <Col md={8} xs={24} sm={24} span={8} key={7}>
                    <Form.Item
                        name={`accompany[${i}].country`}
                        label="Country/Region"
                        rules={[
                            {
                                required: true,
                                message: 'Input Country/Region',
                            },
                        ]}
                    >
                        <Select>
                            {countrys_elements}
                        </Select>
                    </Form.Item>
                </Col>,
            );
            children.push(
                <Col md={8} xs={24} sm={24} span={8} key={8}>
                    <Form.Item
                        name={`accompany[${i}].mobile`}
                        label="Phone or Mobile"
                        rules={[
                            {
                                required: true,
                                message: 'Input phone or mobile!',
                            },
                        ]}
                    >
                        <Input placeholder="please input phone or mobile"/>
                    </Form.Item>
                </Col>,
            );
            children.push(
                <Col md={4} xs={24} sm={24} span={4} key={9}>
                    <Form.Item
                        name={`accompany[${i}].gala_dinner`}
                        label="Gala Dinner"
                        rules={[
                            {
                                required: true,
                                message: 'Please select Gala Dinner!',
                            },
                        ]}
                    >
                        <Radio.Group>
                            <Radio value="Yes">Yes</Radio>
                            <Radio value="No">No</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>,
            );
            children.push(
                <Col md={4} xs={24} sm={24} span={4} key={11}>
                    <Form.Item
                        name={`accompany[${i}].working_lunch`}
                        label="Working Dinner"
                        rules={[
                            {
                                required: true,
                                message: 'please select Working Dinner!',
                            },
                        ]}
                    >
                        <Radio.Group>
                            <Radio value="Yes">Yes</Radio>
                            <Radio value="No">No</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>,
            );
        }

        var arr2 = [];
        for (var i = 0; i < children.length; i = i + 10) {
            arr2.push(<Card style={{ marginBottom: '0.25rem' }} key={i + 10}>{children.slice(i, i + 10)}{i > 0 ?
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a className="remove-box"
                   onClick={this.delAccompanyingPerson}><DeleteOutlined/> remove</a> : ''} </Card>);
        }

        return <div>
            {arr2}
            <div className="add-btn_div" key={10}>
                <Button type="primary" className="next-btn" onClick={this.addAccoompany}><PlusOutlined/> Add</Button>
            </div>
        </div>;
    }

    getMembeFields () {
        return <Col md={24} xs={24} sm={24} span={24} key="1">
            <Form.Item
                name="oeso_member"
                rules={[
                    {
                        required: true,
                        message: 'Please provide your OESO member No.(shown on the receipt of payment)!',
                    },
                ]}
            >
                <Input placeholder="Please provide your OESO member No.(shown on the receipt of payment)"/>
            </Form.Item>
        </Col>;
    }

    //personal info
    getPersonalInfoFields () {
        const { formData } = this.state;
        const children = [];
        children.push(
            <Col md={8} xs={24} sm={24} span={8} key="1">
                <Form.Item
                    name="first_name"
                    label="First Name"
                    rules={[
                        {
                            required: true,
                            message: 'Input first name!',
                        },
                    ]}
                    initialValue={formData.first_name ? formData.first_name : ''}
                >
                    <Input placeholder="please input first name"/>
                </Form.Item>
            </Col>,
        );
        children.push(
            <Col md={8} xs={24} sm={24} span={8} key="2">
                <Form.Item
                    name="middle_initial"
                    label="Middle Name"
                    rules={[
                        {
                            required: false,
                            message: 'Input middle name!',
                        },
                    ]}
                >
                    <Input placeholder="please input middle name"/>
                </Form.Item>
            </Col>,
        );
        children.push(
            <Col md={8} xs={24} sm={24} span={8} key="3">
                <Form.Item
                    name="last_name"
                    label="Last Name"
                    rules={[
                        {
                            required: true,
                            message: 'Input last name!',
                        },
                    ]}
                >
                    <Input placeholder="please input last name"/>
                </Form.Item>
            </Col>,
        );

        children.push(
            <Col md={8} xs={24} sm={24} span={8} key="4">
                <Form.Item
                    name="nick_name"
                    label="Nick Name"
                    rules={[
                        {
                            required: false,
                            message: 'Input Nickname!',
                        },
                    ]}
                >
                    <Input placeholder="please input Nickname"/>
                </Form.Item>
            </Col>,
        );
        children.push(
            <Col md={8} xs={24} sm={24} span={8} key="5">
                <Form.Item
                    name="occupation"
                    label="Occupation/Title"
                    rules={[
                        {
                            required: true,
                            message: 'Input Occupation/Title!',
                        },
                    ]}
                >
                    <Input placeholder="please input Occupation/Title"/>
                </Form.Item>
            </Col>,
        );
        children.push(
            <Col md={8} xs={24} sm={24} span={8} key="6">
                <Form.Item
                    name="organization"
                    label="Organization Name"
                    rules={[
                        {
                            required: true,
                            message: 'Input organization name!',
                        },
                    ]}
                >
                    <Input placeholder="please input organization name"/>
                </Form.Item>
            </Col>,
        );

        children.push(
            <Col md={8} xs={24} sm={24} span={8} key="7">
                <Form.Item
                    name="country"
                    label="Country/Region"
                    rules={[
                        {
                            required: true,
                            message: 'Input Country/Region',
                        },
                    ]}
                >
                    <Select>
                        {countrys_elements}
                    </Select>
                </Form.Item>
            </Col>,
        );
        children.push(
            <Col md={8} xs={24} sm={24} span={8} key="8">
                <Form.Item
                    name="mobile"
                    label="Phone or Mobile"
                    rules={[
                        {
                            required: true,
                            message: 'Input phone or mobile!',
                        },
                    ]}
                >
                    <Input placeholder="please input phone or mobile"/>
                </Form.Item>
            </Col>,
        );
        children.push(
            <Col md={8} xs={24} sm={24} span={8} key="9">
                <Form.Item
                    name="regiatration_type"
                    label="Registration Type"
                    rules={[
                        {
                            required: true,
                            message: 'select registration type!',
                        },
                    ]}
                >
                    <Select onChange={this.registrationChange}>
                        {
                            this.state.registerTypeFeeListData.map((v) => (
                                <Option value={v.type} key={v.type}>{v.type_name} ${v.price}</Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                {/*<p style={{fontSize: '12px'}}><InfoCircleOutlined/>Please note: Once registration complete,*/}
                {/*registration type cannot be changed.</p>*/}
            </Col>,
        );
        children.push(
            <Col md={24} xs={24} sm={24} span={8} key="10">
                <Form.Item
                    name="gala_dinner"
                    label="Gala Dinner"
                    rules={[
                        {
                            required: true,
                            message: 'Please select Gala Dinner!',
                        },
                    ]}
                >
                    <Radio.Group>
                        <Radio value="Yes">Yes</Radio>
                        <Radio value="No">No</Radio>
                    </Radio.Group>,
                    <dl className="dinner-tips">
                        <dt>※</dt>
                        <dd>To attend the Gala Dinner on November 8, 2019, each participant needs to click “yes” and pay
                            extra $120 online.
                        </dd>
                    </dl>

                </Form.Item>
            </Col>,
        );
        children.push(
            <Col md={24} xs={24} sm={24} span={8} key="11">
                <Form.Item
                    name="working_lunch"
                    label="Working Dinner"
                    rules={[
                        {
                            required: true,
                            message: 'Please select Working Dinner!',
                        },
                    ]}
                >
                    <Radio.Group>
                        <Radio value="Yes">Yes</Radio>
                        <Radio value="No">No</Radio>
                    </Radio.Group>

                    <dl className="dinner-tips">
                        <dt>※</dt>
                        <dd>To attend the Working Dinner on November 7, 2019, each participant needs to click “yes” and
                            pay
                            extra $70 online.
                        </dd>
                    </dl>

                </Form.Item>
            </Col>,
        );

        return children;
    }

    // investigatorTemp
    handleChange = (info) => {
        let { fileList, file } = info;
        const status = file.status;
        if (status !== 'uploading') {
            fileList = fileList.filter((v) => {
                if (v.response) {
                    return v.response.code === 200;
                }
                return false;
            });
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
        //重新设置state
        this.setState({ fileList });
    };

    investigatorTpl (e) {
        const uploadFileApi = `${baseUrl}/upload`;
        const label = this.state.showStep === 4 ? 'Please upload related documents (eg. Scientific certification)' : 'Please upload related documents (eg. ID card)';
        const uploadProps = {
            onChange: this.handleChange,
            action: uploadFileApi + '?api_token=' + this.props.userToken,
            multiple: false,
            accept: '.pdf ,.jpg,.png',
            listType: 'picture',
            method: 'post',
            name: 'file',
            fileList: this.state.fileList,
        };
        return (
            <Form.Item
                name="oeso_member"
                label={label}
                rules={[
                    {
                        required: true,
                        message: 'Please upload related documents (eg. ID card)!',
                    },
                ]}
            >
                <div>
                    <Upload beforeUpload={beforeUpload} {...uploadProps}>
                        <Button type="primary" className=" upload-btn">
                            Upload File <UploadOutlined/>
                        </Button>
                    </Upload>
                    <p style={{ padding: '15px 0' }}>※The following files are allowed: pdf ,jpg,png </p>
                </div>
            </Form.Item>
        );
    }

    render () {
        return (
            <div className="Registerone">
                <div className="container ">
                    <h1 className="title"> Create New Account</h1>
                    <div className="steps-wrap">
                        <Steps progressDot current={2}>
                            <Step title="Instruction"/>
                            <Step title="Email Registration"/>
                            <Step title="Personal Infomation"/>
                            <Step title="Complete"/>
                        </Steps>
                    </div>
                    <div style={{ marginBottom: '15px' }}>
                        <Row>
                            <Col span={3}>
                                <Button
                                    onClick={this.back}
                                    block={true}
                                    type={'primary'}
                                    size={'large'}
                                >
                                    <ArrowLeftOutlined/> Back
                                </Button>
                            </Col>
                        </Row>
                    </div>

                    <Form ref={this.formRef} layout="vertical" scrollToFirstError>
                        <h3 className="title-absolute">
                            <div className="title-text">Personal Information</div>
                        </h3>
                        <Row gutter={24}>{this.getPersonalInfoFields()}</Row>
                        <h3 className="title-absolute">
                            <div className="title-text">With Accompanying Person</div>
                        </h3>
                        <Form.Item
                            name="accompanying_type"
                            label="With Accompanying Person"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please choose Y/N!',
                                },
                            ]}
                        >
                            <Select placeholder="Please choose Y/N" onChange={this.withAccompanyingChange}>
                                <Option value="yes">yes</Option>
                                <Option value="no">no</Option>
                            </Select>
                        </Form.Item>
                        <Row
                            gutter={24}>{this.state.hasWithAccompanying === 'yes' ? this.getAccompanyInfoFields() : ''}</Row>

                        {/*step2*/}
                        {this.state.showStep === 2 ? <h3 className="title-absolute">
                            <div className="title-text"><label style={{ color: '#f00' }}>*</label> OESO Member</div>
                        </h3> : ''}
                        <Row gutter={24}>{this.state.showStep === 2 ? this.getMembeFields() : ''}</Row>
                        {/*step3*/}
                        {this.state.showStep === 3 ? <h3 className="title-absolute">
                            <div className="title-text">Young Investigator</div>
                        </h3> : ''}
                        <Row gutter={24}
                             style={{ paddingLeft: '12px' }}>{this.state.showStep === 3 ? this.investigatorTpl() : ''}</Row>
                        {/*step7*/}
                        {this.state.showStep === 7 ? <h3 className="title-absolute">
                            <div className="title-text"> Group Information</div>
                        </h3> : ''}
                        {this.state.showStep === 7 && (
                            <Form.Item
                                name="group_name"
                                label="Group Name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm your group name!',
                                    },
                                ]}
                            >
                                <Input placeholder="please input group name"/>
                            </Form.Item>
                        )}
                        {this.state.showStep === 7 ? this.getGroupInfoFields() : ''}
                        {/*step 4 fellow*/}
                        {this.state.showStep === 4 ? <h3 className="title-absolute">
                            <div className="title-text">Fellow</div>
                        </h3> : ''}
                        <Row gutter={24}
                             style={{ paddingLeft: '12px' }}>{this.state.showStep === 4 ? this.investigatorTpl() : ''}</Row>
                        {/*step5*/}
                        {/*{this.state.showStep == 5 ? <h3 className="title-absolute">*/}
                        {/*<div className="title-text">With Accompanying Personal</div>*/}
                        {/*</h3> : ''}*/}

                        <Row>
                            <Col xs={24} sm={24} md={{ span: 6, offset: 8 }}>
                                <FormItem>
                                    <Button loading={this.state.loading} className="submit-btn" size="large"
                                            type="primary" block onClick={this.handleSubmit}>Checkout</Button>
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        userToken: state.userToken,
        userInfo: state.userInfo,
    };
};
export default connect(mapStateToProps)(withRouter(StepOne));
