import React from 'react';
import {Row, Col, Card} from 'antd';
import sourceData from './data'
import "./index.less";

export default class About extends React.Component {

  // 渲染数据项
  renderListData = () => {
    const {imgs, listData} = sourceData;
    return <Row gutter={24} type="flex" justify="center" align="bottom">
      <Col span={14} xs={24} sm={24} md={14}>
        <Card bordered={false}>
          {listData.map((item, index) => {
            return (
              <div key={index}>
                {index === 0 ? <div className="line"></div> : ''}
                <div dangerouslySetInnerHTML={{__html: item.content}}></div>
              </div>
            )
          })}
        </Card>
      </Col>
      <Col span={10} xs={24} sm={24} md={10}>
        {imgs.map((item, index) => {
          return (
            <Card key={index} bordered={false} className="cover"
                  cover={<img alt="pic" style={{width: "100%", height: "3.16rem", padding: "0.2rem"}} className="card-item_cover"  src={item}/>}>
            </Card>
          )
        })}
      </Col>
    </Row>
  };

  render() {
    return (
      <section className="venue-part container">
        <div className="venue">
          <h1 className="title">INFORMATION OF BEIJING</h1>
        </div>
        <div  className="ad">
          <img alt="pic" className="full-width" src={require('../../resource/images/beijing_bg.png')}/>
        </div>
        <div className="venue-content">
          <h3 className="about-text">About Beijing</h3>
          {this.renderListData()}
        </div>
      </section>
    )
  }
}