export const hosts = {
	dev: 'http://iacr.dev.cn',
	pro: 'https://www.iacr2024.com',
};

export const host = process.env.NODE_ENV === 'development' ? hosts.dev : hosts.pro;

export const baseUrl = `${host}/api/v1/admin`;

/**
 * @description 联系邮箱
 */
export const contactEmail = 'service@bjreinforce.com';

/**
 * @description 投稿截止日期
 *
 * @type {Date}
 */
export const uploadEndDatetime = new Date('2024-05-16 23:59:59');

export default {
	/**
	 * @description token在Cookie中存储的天数，默认1天
	 */
	cookieExpires: 1,
	/**
	 * @description 是否使用国际化，默认为false
	 *              如果不使用，则需要在路由中给需要在菜单中展示的路由设置meta: {title: 'xxx'}
	 *              用来在菜单中显示文字
	 */
	useI18n: false,
};
