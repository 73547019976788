import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { updateUser, userInfo } from '../../../redux/action';
import { Row, Col, Button, Form, Input, Select } from 'antd';
import './index.less';
import sourceData from '../../register/personal-infomation/data';

const Option = Select.Option;
const { countrys } = sourceData;
const countrys_elements = [];
for (let i = 0; i < countrys.length; i++) {
    countrys_elements.push(<Option key={countrys[i]} value={countrys[i]}>{countrys[i]}</Option>);
}

class Center extends Component {
    formRef = React.createRef();

    constructor (props) {
        super(props);
        this.state = {
            isEdit: false,
        };
    }

    componentWillMount () {
        let { dispatch, history } = this.props;
        dispatch(userInfo(this.props.userToken));
        let pay_status = this.props.userInfo;
        if (pay_status === 1) {
            history.push('/admin/pre');
        }
    }

    handleSubmit = () => {
        this.formRef.current.validateFields().then((values) => {
            let { dispatch } = this.props;
            dispatch(updateUser(values, this.props.userToken));
            this.setState({
                isEdit: false,
            });
        }).catch(() => {
            //
        });
    };
    handleEdit = () => {
        this.setState({
            isEdit: !this.state.isEdit,
        });
        this.handleReset();
    };
    handleReset = () => {
        this.formRef.current.resetFields();
    };

    render () {
        const { userInfo } = this.props;
        const { isEdit } = this.state;

        return (
            <div className="account-page" style={{ marginBottom: '0.5rem' }}>
                {userInfo.pay_status === 2 && (
                    <div className="container">
                        <ul className="menu-list">
                            <li className="menu active"><NavLink to="/admin/center">Personal Information</NavLink></li>
                            <li className="menu "><NavLink to="/admin/upload">Abstract Upload</NavLink></li>
                            <li><NavLink to="/admin/fee">Payment Detail</NavLink></li>
                        </ul>
                    </div>
                )}

                <h1 className="title">PERSONAL INFORMATION</h1>
                <section className="border-bottom">
                    <div className="inner container paid-info">
                        <dl>
                            <dd>Your name</dd>
                            <dt>{userInfo.first_name + userInfo.middle_initial + ' ' + userInfo.last_name}</dt>
                        </dl>
                        <dl>
                            <dd>Your registration number</dd>
                            <dt>{userInfo.register_code}</dt>
                        </dl>
                    </div>
                </section>
                {/*登录信息*/}
                <section className="border-bottom">
                    <div className="inner container">
                        <h6 className="title-absolute" style={{ margin: '0.3rem 0' }}>
                            <div className="title-text">Login Information</div>
                        </h6>
                        <Form ref={this.formRef} layout="vertical" scrollToFirstError>
                            <Row gutter={24}>
                                <Col xs={24} sm={24} md={{ span: 8 }}>
                                    <Form.Item label="Email" required>
                                        <Input disabled value={userInfo.email} style={{ color: 'rgba(0,0,0,.25)' }}
                                               placeholder="Username"/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={{ span: 8 }}>
                                    <Form.Item label="Current password" required>
                                        <Input disabled type="password" value="123456"
                                               style={{ color: 'rgba(0,0,0,.25)' }}
                                               placeholder="Username"/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={{ span: 4, offset: 4 }}>
                                    <Form.Item style={{ textAlign: 'right' }}>
                                        <Button type="primary" ghost size="small"
                                                href="#/admin/updatePassword">Change Password</Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </section>
                {/*个人信息*/}
                <section className="border-bottom">
                    <div className="inner container">
                        <h6 className="title-absolute" style={{ margin: '0.3rem 0' }}>
                            <div className="title-text">Personal Information</div>
                            <div className="btn-a">
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a href="#" onClick={this.handleEdit}>{isEdit ? 'Cancel' : 'Edit'}</a>
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                {isEdit ? <a href="#" onClick={this.handleSubmit}>Save</a> : ''}
                            </div>
                        </h6>
                        <Form layout="vertical" scrollToFirstError>
                            <Row gutter={24}>
                                <Col span={8} xs={24} sm={24} md={{ span: 8 }}>
                                    <Form.Item
                                        name="nick_name"
                                        label="Nickname"
                                        rules={[
                                            {
                                                message: 'Please input your nick_name!',
                                                required: false,
                                            },
                                        ]}
                                        initialValue={userInfo.nick_name}
                                    >
                                        {isEdit ? <Input placeholder="Nick name"/> : <p>{userInfo.nick_name}</p>}
                                    </Form.Item>
                                </Col>
                                <Col span={8} xs={24} sm={24} md={{ span: 8 }}>
                                    <Form.Item
                                        name="occupation"
                                        label="Occupation/title"
                                        rules={[
                                            {
                                                message: 'Please input your Occupation/title!',
                                                required: true,
                                            },
                                        ]}
                                        initialValue={userInfo.occupation}
                                    >
                                        {isEdit ? <Input placeholder="occupation"/> : <p>{userInfo.occupation}</p>}
                                    </Form.Item>
                                </Col>
                                <Col span={8} xs={24} sm={24} md={{ span: 8 }}>
                                    <Form.Item
                                        name="organization"
                                        label="Organization Name"
                                        rules={[
                                            {
                                                message: 'Please input your Organization Name!',
                                                required: true,
                                            },
                                        ]}
                                        initialValue={userInfo.organization}
                                    >
                                        {isEdit ? <Input placeholder="organization"/> : <p>{userInfo.organization}</p>}
                                    </Form.Item>
                                </Col>
                                <Col span={8} xs={24} sm={24} md={{ span: 8 }}>
                                    <Form.Item
                                        name="country"
                                        label="Country/Region"
                                        rules={[
                                            {
                                                message: 'Please input your country!',
                                                required: true,
                                            },
                                        ]}
                                        initialValue={userInfo.country}
                                    >
                                        {isEdit ? <Select>{countrys_elements}</Select> : <p>{userInfo.country}</p>}
                                    </Form.Item>
                                </Col>
                                <Col span={8} xs={24} sm={24} md={{ span: 8 }}>
                                    <Form.Item
                                        name="mobile"
                                        label="Phone or mobile"
                                        rules={[
                                            {
                                                message: 'Please input your mobile!',
                                                required: true,
                                            },
                                        ]}
                                        initialValue={userInfo.mobile}
                                    >
                                        {isEdit ? <Input placeholder="mobile"/> : <p>{userInfo.mobile}</p>}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </section>
                <section>
                    <div className="inner container">
                        <h6 className="title-absolute" style={{ margin: '0.3rem 0' }}>
                            <div className="title-text">Registration type</div>
                        </h6>
                        <div>{userInfo.regiatration_type}</div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        userInfo: state.userInfo,
        userToken: state.userToken,
    };
};

export default connect(mapStateToProps)(Center);
