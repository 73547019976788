import React from 'react';
import './index.less';
import { Row, Col } from 'antd';
import { BulbOutlined, FilterOutlined, GlobalOutlined } from '@ant-design/icons';

export default class Introduction extends React.Component {
    render () {

        return (
            <section className="introduction-part ">
                <h1 className="title">OESE INTRODUCTION</h1>
                <div className="container ">
                    <Row className="content" type="flex" align="middle">
                        <Col style={{ padding: '0.5rem 0 0 0.3rem', borderLeft: '1px solid #2258A7' }}>
                            <h3 className="sm-title">About OESO</h3>
                            <p><strong className="first-letter">S</strong>ince its founding in 1978 by Robert GIULI,
                                M.D., FACS, Professor of Surgery at the University of Paris, the structure of OESO has
                                been that of an international network, created to set up multi-disciplinary multicenter
                                studies on esophageal disease. </p>
                        </Col>
                    </Row>
                </div>
                <Row className="list-intro" type="flex" align="middle">
                    <Col span={14} xs={24} sm={24} md={{ span: 10, offset: 4 }} className="list-intro_img">
                        <div className="list-intro_img__text">
                            <img alt="pic" className="full-width"
                                 src={require('../../../resource/images/logo-bg.png')}/>
                        </div>
                    </Col>
                    <Col span={10} xs={24} sm={24} md={10} className="list-intro_context">
                        <ul>
                            <li><BulbOutlined/> esophageal disease</li>
                            <li><GlobalOutlined/> international network</li>
                            <li><FilterOutlined/> multi-disciplinary</li>
                        </ul>
                    </Col>

                </Row>
                <div className="container">
                    <div className="content ">
                        <p>From the start, the unique goal of this endeavor has been to bring together specialists from
                            all disciplines involved in the then new science of esophagology, in order to carry out
                            research in the diverse fields contributing to better care of patients suffering from a
                            disease of the esophagus.</p>
                        <p>The first President of Honor of OESO was Professor Jean-Louis LORTAT-JACOB (Paris). Then, and
                            up until 2000, Professor Guido N.J. TYTGAT (Amsterdam), world renowned gastroenterologist,
                            and Professor Tom R. DeMEESTER (Los Angeles), equally renowned in the field of esophageal
                            surgery, shared this role.</p>
                        <p>Professor Robert Giuli has acted as Scientific Director of the network since its
                            creation.</p>
                        <p>Since 2012, he has been sitting on the Board as Founder - Deputy Director.</p>
                        <p>In 2011, OESO counts 14,000 corresponding members in 85 countries of the world.</p>
                        <p>Since its creation, OESO has represented a unique joint endeavor, and its multi-disciplinary
                            approach has given to this network a particular place in the international community. By its
                            original, multi-disciplinary nature, by its international assembly of leading specialists
                            from 19 disciplines, all interested in a single organ, the esophagus, OESO has gained a
                            particular place as a body for clinical and fundamental research, and this Organization has
                            progressively attained the status of a world reference in the domain of esophagology.</p>
                        <p>This unique, joint endeavor henceforth renders tangible, and directly useful in daily
                            practice, the very specific achievement of a network of excellence bringing together, in
                            each discipline, many of the most prominent representatives of gastroenterology in the
                            world.</p>
                    </div>
                </div>
            </section>
        );
    }

}
