import React, {Component} from 'react';
import 'babel-preset-es2015-ie';
import { hot } from 'react-hot-loader/root';
import 'es5-shim';
import 'console-polyfill';
import 'babel-polyfill';
import './App.css';

class App extends Component {
	render() {
		return (
			<div className="app-wrapper">
				{this.props.children}
			</div>
		);
	}
}

export default hot(App);
