import React           from 'react';
import ReactDOM        from 'react-dom';
import './index.css';
import Router          from './router';
import {Provider}      from 'react-redux';
import configureStrore from './redux/store/configureStrore';
import {PersistGate}   from 'redux-persist/lib/integration/react';

// 兼容IE
import 'babel-preset-es2015-ie';
import 'es5-shim';
import 'console-polyfill';
import 'babel-polyfill';

const {persistor, store} = configureStrore();

const scale = 1;

document.querySelector('meta[name="viewport"]').setAttribute('content', 'initial-scale=' + scale + ', maximum-scale=' + scale + ', minimum-scale=' + scale + ', user-scalable=no');

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Router/>
        </PersistGate>
    </Provider>,
    document.getElementById('root'),
);
