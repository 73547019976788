const data = {
    countrys: ['Angola','Afghanistan','Albania','Algeria','Andorra','Anguilla','Antigua and Barbuda','Argentina',
                  'Armenia','Ascension','Australia','Austria','Azerbaijan','Bahamas','Bahrain','Bangladesh','Barbados',
                  'Belarus','Belgium','Belize','Benin','Bermuda Is.','Bolivia','Botswana','Brazil','Brunei','Bulgaria','Burkina-faso',
                  'Burma','Burundi','Cameroon','Canada','Cayman Is.','Central African Republic','Chad','Chile','China','Colombia','Congo',
                  'Cook Is.','Costa Rica','Cuba','Cyprus','Czech Republic','Denmark','Djibouti','Dominica Rep.','Ecuador','Egypt','EI Salvador',
                  'Estonia','Ethiopia','Fiji','Finland','France','French Guiana','Gabon','Gambia','Georgia','Germany','Ghana','Gibraltar','Greece',
                  'Grenada','Guam','Guatemala','Guinea','Guyana','Haiti','Honduras','Hongkong','Hungary','Iceland','India','Indonesia','Iran',
                  'Iraq','Ireland','Israel','Italy','Ivory Coast','Jamaica','Japan','Jordan','Kampuchea (Cambodia)','Kazakstan','Kenya','Korea',
                  'Kuwait','Kyrgyzstan','Laos','Latvia','Lebanon','Lesotho','Liberia','Libya','Liechtenstein','Lithuania','Luxembourg','Macao',
                  'Madagascar','Malawi','Malaysia','Maldives','Mali','Malta','Mariana Is','Martinique','Mauritius','Mexico','Moldova,Republic of',
                  'Monaco','Mongolia','Montserrat Is','Morocco','Mozambique','Namibia','Nauru','Nepal','Netheriands Antilles','Netherlands','New Zealand',
                  'Nicaragua','Niger','Nigeria','North Korea','Norway','Oman','Pakistan','Panama','Papua New Cuinea','Paraguay','Peru','Philippines','Poland',
                  'French Polynesia','Portugal','Puerto Rico','Qatar','Reunion','Romania','Russia','Saint Lueia','Saint Vincent','Samoa Eastern','Samoa Western',
                  'San Marino','Sao Tome and Principe','Saudi Arabia','Senegal','Seychelles','Sierra Leone','Singapore','Slovakia','Slovenia','Solomon Is',
                  'Somali','South Africa','Spain','Sri Lanka','St,Lucia','St,Vincent','Sudan','Suriname','Swaziland','Sweden','Switzerland','Syria','Taiwan',
                  'Tajikstan','Tanzania','Thailand','Togo','Tonga','Trinidad and Tobago','Tunisia','Turkey','Turkmenistan','Uganda','Ukraine',
                  'United Arab Emirates','United Kiongdom','United States of America','Uruguay','Uzbekistan','Venezuela',
                  'Vietnam','Yemen','Yugoslavia','Zimbabwe','Zaire','Zambia'],
    relationship: ['Parents','Offspring','Spouse','Students','Friends'],
};
export default data;