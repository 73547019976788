import React from 'react'
import {Row, Col} from 'antd';
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import Header from './components/Header'
import Footer from './components/Footer'
// import NavLeft from './components/NavMenu'
import './style/common.less'
import './style/calc.less';
import logo from './resource/images/logo.png';
import {saveUserInfo, saveUserToken, saveUserID, userInfo} from "./redux/action";

class Admin extends React.Component {
    state = {};

    constructor(props) {
        super(props);
        this.state = {
            logo: logo,
            collapsed: false,
            isMobile: true,
            userToken: this.props.userToken,
            currentUser: {
                name: this.props.userInfo ? this.props.userInfo.email : '',
                userToken: this.props.userToken,
                status: this.props.userInfo ? this.props.userInfo.status : '',
                payStatus: this.props.userInfo ? this.props.userInfo.pay_status : '',
                dinnerStatus: Object.keys(userInfo).length ? this.props.userInfo.repast_status : 1,
            }
        }
    }

    componentWillReceiveProps(nextProps, nextState) {
        let storage = JSON.parse(localStorage.getItem('persist:root'));
        let {userID, userInfo, userToken} = storage;
        if (userToken === undefined) {
            return false
        }
        userInfo = JSON.parse(userInfo);
        let usToken = JSON.parse(userToken);
        let {dispatch} = this.props;
        if (nextProps.userToken !== usToken) {
            dispatch(saveUserToken(usToken));
            dispatch(saveUserID(JSON.parse(userID)));
            dispatch(saveUserInfo(userInfo));

        }
        let currentUser = {
            name: Object.keys(userInfo).length ? userInfo.email : '',
            userToken,
            status: Object.keys(userInfo).length ? this.props.userInfo.status : 1,
            payStatus: Object.keys(userInfo).length ? this.props.userInfo.pay_status : 1,
            dinnerStatus: this.props.userInfo ? this.props.userInfo.repast_status : 1,

        };
        this.setState({
            currentUser: currentUser
        });
    }

    handleClickMenu = (menu) => {
        const {userInfo, history} = this.props;
        const flag = (Number(userInfo.register_status) === 1 || Number(userInfo.register_status) === 2) && (menu === '/admin/fee' || menu === '/admin/upload');
        if (flag) {
            history.push('/common/reload');
            return false;
        }

        history.push(menu);
    };

    // 刷新用户
    handleRefresh = () => {
        const {dispatch} = this.props;

        dispatch(userInfo(this.props.userToken))
    };

    render() {
        return (
          <div className="admin-center">
              <Row type="flex" align="middle" justify="center">
                  <Col span={24} className="nav-left">
                      <div
                          className="header-wrapper"
                          style={{
                              width: '100%',
                              zIndex: 3,
                              position: 'fixed',
                              background: '#fff'
                          }}
                      >
                          <Header
                              onClickMenu={this.handleClickMenu}
                              onRefresh={this.handleRefresh}
                              {...this.state}
                          />

                          {/* <div style={{margin: '0 auto'}}>
                              <NavLeft/>
                          </div> */}
                      </div>
                  </Col>
                  <Col span={24} className="router-view main has-padding" style={{paddingTop: 58}}>
                      <div className="content">
                          {this.props.children}
                      </div>

                      <Footer/>
                  </Col>
              </Row>
          </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        userInfo: state.userInfo,
        userToken: state.userToken
    }
};
export default connect(mapStateToProps)(withRouter(Admin))
