import React from 'react';
import './index.less'
import {Tabs} from 'antd';

const TabPane = Tabs.TabPane;
export default class About extends React.Component {

    render(h) {
        return (<div className="about-page">
            <h1 className="title">LATE-BREAKING ABSTRACTS</h1>
            <section>
                <h3 className="subject-title">Late-Breaking Abstracts (LBA)</h3>
                <p>Late-breaking abstracts (LBA) may be submitted for high quality, new research findings with
                    implications for clinical practice or understanding of disease processes. Researchers with
                    well-designed, high impact trials would be candidates for late-breaking abstracts even if the
                    results are negative.</p>
                <p>If a submitter wishes to submit a late-breaking abstract, a skeleton abstract indicating the type of
                    data that will be available by the late-breaking abstract deadline must be submitted online by July
                    31, 2019 (11:59 GMT). Please note that accepted late-breaking abstracts will be notified by
                    September 1, 2019. </p>
                <p>The skeleton abstract will need to describe the type of data that will be submitted by the
                    late-breaking data submission deadline, indicating the primary clinical endpoint for analysis,
                    planned statistical plan for analysis, and date of planned analysis.</p>
                <p>Submitters of preliminary versions receiving approval to proceed must submit the final late-breaking
                    abstract by October 15, 2019 (11:59 GMT).</p>
                <img alt="pic" style={{width: "100%", margin: "0.4rem auto"}}
                     src={require('../../../resource/images/latebreaking_pic.png')}/>
            </section>
            <section>
                <h3 className="subject-title">Submission Guidelines</h3>
                <dl>
                    <dt className="title-absolute">
                        <div className="title-text">General</div>
                    </dt>
                    <dd className="text"><i>①</i>Abstracts will be accepted in English only</dd>
                    <dd className="text"><i>②</i>An international panel of reviewers will review all abstracts
                        anonymously
                    </dd>
                    <dd className="text"><i>③</i>There is no fee for submitting an abstract</dd>
                    <dd className="text"><i>④</i>There is no limit to the number of abstracts you may submit (however an
                        individual may not be offered more than 2 oral presentations)
                    </dd>
                    <dd className="text"><i>⑤</i>The presenting author must have registered for OESO 2019 at the time of
                        initial abstract submission
                    </dd>
                    <dd className="text"><i>⑥</i>The final submission must contain results and conclusions.</dd>
                    <dd className="text"><i>⑦</i>All abstracts accepted by the Scientific Committee will be published in
                        the Conference website prior to the meeting and it is mandatory to agree to this at the time of
                        submission
                    </dd>
                    <dd className="text"><i>⑧</i>Start your abstract submission by creating an account for the OESO 2019
                        abstract submission system. Please note that the abstract submission system account is different
                        from your ISDE membership account.
                    </dd>

                </dl>
                <dl>
                    <dt className="title-absolute">
                        <div className="title-text">Late-Breaking Abstract Structure</div>
                    </dt>
                    <dd className="text"><i>①</i>Background</dd>
                    <dd className="text"><i>②</i>Methods</dd>
                    <dd className="text"><i>③</i>Results – please indicate ‘Results not yet available’ as a placeholder
                        in this text field
                    </dd>
                    <dd className="text"><i>④</i>Conclusion – please indicate ‘Conclusion not yet available’ as a
                        placeholder in this text field
                    </dd>
                </dl>
            </section>
            <section>
                <Tabs defaultActiveKey="1" bottomOffset={80}>
                    <TabPane tab="Step  1" key="1" style={{height: 200}}>
                        <dl>
                            <dt>Title & Topic</dt>
                            <dd><i>①</i>The abstract title must not exceed 150 characters in length; this includes
                                spaces.
                            </dd>
                        </dl>
                    </TabPane>
                    <TabPane tab="Step  2" key="2">
                        <dl>
                            <dt>Authors</dt>
                            <dd><i>①</i>The presenting author of an accepted abstract must be registered for the OESO
                                Congress by July 30, 2019
                            </dd>
                            <dd><i>②</i>There is no limit to the number of co-authors per abstract</dd>

                            <dd><i>③</i>The submitter can view the status of co-author disclosures by clicking on
                                ‘Authors’ sections of the abstract. Those with incomplete disclosures are denoted in red
                            </dd>
                            <dd><i>④</i>If you would like an assistant or other person to receive notifications
                                regarding the status of an abstract, please enter their email address on the text field
                                provided below the list of co-authors
                            </dd>
                            <dd><i>⑤</i>Please note your preferred presentation format. If you would not be comfortable
                                giving an oral presentation, please select Poster Only. NOTE: Selecting Poster/Oral does
                                not guarantee you will be assigned an oral abstract presentation upon acceptance; the
                                final presentation type is determined by the International Scientific Abstract Review
                                Committee
                            </dd>
                            <dd><i>⑥</i>Author and institution information must NOT be included in the body of the
                                abstract. The inclusion of either of these in the body of the abstract will result in
                                rejection of the abstract
                            </dd>
                            <dd><i>⑧</i>The presenting author must have registered for ISDE 2019 at the time of initial
                                abstract submission
                            </dd>

                        </dl>
                    </TabPane>
                    <TabPane tab="Step 4" key="4">
                        <dl>
                            <dt>Abstract Text</dt>
                            <dd><i>①</i>Do not include title or author data in the body of your abstract as there are
                                specific places for title and author (steps 1 & 2)
                            </dd>
                            <dd><i>②</i>Abstracts may be entered directly into the site or copied and pasted</dd>
                            <dd><i>③</i>Research work should be original and innovative. Work can be presented prior to
                                OESO 2019, but MUST include new information/data
                            </dd>
                            <dd><i>④</i>Abstracts must not exceed 450 words in length (not including title and authors)
                            </dd>
                            <dd><i>⑤</i>A maximum of 1 table may be included and will count as 100 words</dd>
                            <dd><i>⑥</i>A maximum of 1 image may be included and will count as 100 words</dd>
                            <dd><i>⑦</i>Tables, images and graphs must be uploaded in JPG or TIFF format at 300 dpi and
                                100% size. Any higher resolution is acceptable.
                            </dd>
                        </dl>
                    </TabPane>
                    <TabPane tab="Step 5" key="5">
                        <dl>
                            <dt>Preview & Submit</dt>
                            <dd><i>①</i>Please proofread your submission prior to submitting</dd>
                            <dd><i>②</i>Once submitted, you will no longer be able to make edits to the abstract. If you
                                need to make changes prior to the submission deadline, please contact us at e-mail
                                (OESO2019@sina.com)
                            </dd>
                        </dl>
                    </TabPane>
                </Tabs>
            </section>
        </div>)

    }
}