import React from 'react';
import { Row, Col } from 'antd';
import './index.less'


export default class NoMatch extends React.Component {
  render() {
    return (
      <section className="container">
        <div className="news">
          <h1 className="title">CONGRESS NEWS</h1>
          <Row gutter={20}>
            <Col md={{span:17}} xs={24} sm={24}>
              <img alt="图片" className="full-width" src={require("../../resource/images/news-bg.png")}/>
            </Col>
            <Col md={{span:7}} xs={24} sm={24}>
              <h6>More News</h6>
              <div className="line"></div>
              <div className="block">Coming soon...</div>
            </Col>
          </Row>
        </div>

      </section>
    )
  }
}