/**
 * reducer
 */
// import { combineReducers } from 'redux'
import { type } from '../action';
const ebikeData = (state, action) => {
    switch (action.type) {
        case type.SWITCH_MENU:
            return {
                ...state,
                menuName:action.menuName
            };
        case type.USER_TOKEN:
            return {
                ...state,
                userToken:action.userToken
            };
        case type.USER_ID:
            return {
                ...state,
                userID:action.userID
            };
        case type.USER_INFO:
            return {
                ...state,
                userInfo:action.userInfo
            };
        default:
            return {...state};
    }
};

export default ebikeData;