import React, { Component } from 'react';
import { Row, Col, Form, Input, Button, Card, message } from 'antd';
import axios from '../../../axios/api.request';
import './index.less';
import { withRouter } from 'react-router-dom';

class RegistrationForm extends Component {
    constructor (props) {
        super(props);

        this.state = {
            loading: false,
        };
    }

    // 发送邮箱
    sendEmail = (params) => {
        params['url'] = 'www.baidu.com';

        this.setState({
            loading: true,
        });

        axios.request({
            url: '/forgot_password',
            method: 'post',
            data: params,
        }).then(res => {
            if (res.status === 200 && res.data.code === 200) {
                this.props.history.push('/common/await');

                return;
            }

            message.warn(res.data.message || 'Failed to send');
        }).catch(err => {
            message.warn(err.message || 'Failed to send');
        }).finally(() => {
            this.setState({
                loading: false,
            });
        });
    };

    render () {
        return (
            <div className="forgot-page">
                <div className="container">
                    <div className="login-content">
                        <Row gutter={24} type="flex" align="middle">
                            <Col md={{ span: 20, offset: 4 }}>
                                <h3 className="subject-title">Are you having trouble signing in?</h3>
                            </Col>
                            <Col span={12} xs={24} sm={24} md={{ span: 8, offset: 4 }}>
                                <Card bordered={false}>
                                    <h3 className="sm-title">Please enter your E-mail to get started.</h3>
                                    <Form
                                        layout="vertical"
                                        scrollToFirstError
                                        onFinish={this.sendEmail}
                                    >
                                        <Form.Item
                                            name="email"
                                            label="E-mail"
                                            rules={[
                                                {
                                                    type: 'email',
                                                    message: 'The input is not valid E-mail!',
                                                },
                                                {
                                                    message: 'Please input your E-mail!',
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Please input your E-mail!"/>
                                        </Form.Item>
                                        <Form.Item>
                                            <Button
                                                block
                                                type="primary"
                                                loading={this.state.loading}
                                                htmlType="submit"
                                                className="submit-btn"
                                            >Continue</Button>
                                        </Form.Item>
                                    </Form>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(RegistrationForm);
