import React, {Component} from 'react';
import {Card}             from 'antd';
import {withRouter}       from 'react-router';
import './index.less';

class OCard extends Component {
    onClickItem = (e) => {
        e.preventDefault();

        const {path} = this.props;

        if (!path) {
            return;
        }

        if (path.startsWith('http')) {
            window.open(path);
            return;
        }

        this.props.history.push(path);
    };

    render() {

        return (
            <Card
                onClick={this.onClickItem}
                className="home-card-item ocard"
                hoverable
                cover={<img className="home-card-item_cover" alt="example" src={this.props.imgUrl}/>}
            >
                <div className="mask">
                    <div className="click-a">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a>{this.props.text}</a>
                        <p className="arrow">→</p>
                    </div>
                </div>
            </Card>
        );
    }
}

export default withRouter(OCard);
