import React from 'react';
import { Card, Form, Input, Button, notification } from 'antd';
import './index.less';
import axios from '../../axios/api.request';
import { NavLink } from 'react-router-dom';
import { contactEmail } from '../../config';

const FormItem = Form.Item;

class FormLogin extends React.Component {
    formRef = React.createRef();

    constructor (props) {
        super(props);
        let code_result = localStorage.getItem('code_result');

        if (code_result === null || code_result === 1) {
            code_result = 1;
        } else {
            code_result = 0;
        }

        let count = Number(localStorage.getItem('count') || 60);

        this.state = {
            count: count,
            timer: {},
            isShow: false,
            countDown: '',
            codeResult: Number(code_result),
        };
    }

    componentDidMount () {
        let isRun = Number(localStorage.getItem('code_valid'));

        if ((!this.state.codeResult) && isRun) {
            this.countTime();
        }
    }

    componentWillUnmount () {
        this.setState({
            codeResult: 1,
            count: 60,
            timer: {},
        });

        clearTimeout(this.state.timer);

        localStorage.removeItem('count');
        localStorage.removeItem('code_result');
        localStorage.removeItem('code_valid');
    }

    //60秒验证码
    countTime = () => {
        var vm = this;
        let count = vm.state.count;
        if (count === 0) {
            this.setState({
                codeResult: 1,
                count: 60,
            });
        } else {
            this.setState({
                codeResult: 0,
                count: count - 1,
            });

            vm.state.timer = setTimeout(() => {
                vm.countTime();
            }, 1000);
        }

        localStorage.setItem('count', this.state.count);
        localStorage.setItem('code_result', this.state.codeResult);

        this.setState({
            countDown: 'Resend activation email(' + count + ')s',
        });
    };

    getCodeHandler = () => {
        let that = this;
        const params = this.formRef.current.getFieldsValue();
        if (params.email) {
            axios.request({
                url: '/send',
                method: 'post',
                data: {
                    email: params.email,
                },
            }).then((res) => {
                let resData = res.data;
                if (Number(resData.code) === 200) {
                    that.countTime();
                    localStorage.setItem('code_valid', 1);
                }

                notification.info({
                    message: 'system notice',
                    description: res.data.message || 'register success',
                });
            });
        } else {
            notification.info({
                message: 'system notice',
                description: 'Please input email',
            });
        }
    };

    render () {
        return (
            <Card bordered={false}>
                <Form
                    ref={this.formRef}
                    layout="vertical"
                    className="login-form"
                    scrollToFirstError
                    onFinish={this.props.loginSubmit}
                >
                    <FormItem
                        name="email"
                        label="Email"
                        rules={[
                            {
                                type: 'email',
                                message: 'The input is not valid E-mail!',
                            },
                            {
                                message: 'Please input your E-mail!',
                                required: true,
                            },
                        ]}
                    >
                        <Input placeholder="Please enter your email"/>
                    </FormItem>
                    <FormItem
                        name="password"
                        label="Password"
                        rules={[
                            {
                                message: 'Please enter your password!',
                                required: true,
                            },
                        ]}
                    >
                        <Input.Password type="password" placeholder="Please enter your password"/>
                    </FormItem>
                    <FormItem>
                        <Button
                            type="primary"
                            block
                            loading={this.props.loading}
                            htmlType="submit"
                            className="submit-btn"
                        >Login</Button>
                    </FormItem>
                    <ul>
                        <li>
                            <span style={{ marginRight: 5 }}>Still need to activate your account ?</span>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            {this.state.codeResult ? <a onClick={this.getCodeHandler}>Resend activation email ?</a> :
                                <span>{this.state.countDown}</span>}
                        </li>
                        <li>
                            <span style={{ marginRight: 5 }}>Don’t have an IACR2024 ID ?</span>
                            <NavLink to="/register/StepOne">Create one now</NavLink>
                        </li>
                        <li>
                            <span style={{ marginRight: 5 }}>Forgot your password ?</span>
                            <NavLink to="/common/forgot">Reset it ?</NavLink>
                        </li>
                        <li>
                            <span style={{ marginRight: 5 }}>Need help ?</span>
                            <span style={{ marginRight: 5 }}>Contact us</span>
                            <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
                        </li>
                        <li style={{ marginTop: 20 }}>
                            <span>Due to certain reasons, if you do not receive the registration activation email, please register using a <b style={{color: 'red'}}>Gmail</b> address instead.</span>
                        </li>
                    </ul>
                </Form>
            </Card>
        );
    }
}

export default FormLogin;
