import React, {Component} from 'react';
import './index.less';
import {connect} from 'react-redux';
import {NavLink} from "react-router-dom";
import {userInfo} from "../../../redux/action";

class PaySuccessResult extends Component {
    state = {};

    componentWillMount() {
        let {dispatch} = this.props;
        dispatch(userInfo(this.props.userToken));
    }

    render() {
        let params = this.props.match.params;
        return (<div className="pay-result-page container">
            {
                params.type === 'success'
                    ? <h1 className="title"><img className="icon-status"
                                                 src={require('../../../resource/images/pay-success.png')} alt=""/>Payment
                        Successful！</h1>
                    : <h1 className="title danger-text"><img className="icon-status"
                                                             src={require('../../../resource/images/pay-fail.png')}
                                                             alt=""/>Payment Failed！</h1>
            }
            <section className="paid-info">
                <NavLink to="/admin/fee">Back to Payment Detail</NavLink>
            </section>
        </div>)
    }
}


const mapStateToProps = state => {
    return {
        userToken: state.userToken,
    };
};
export default connect(mapStateToProps)(PaySuccessResult)

