import axios          from 'axios';
import JsonP          from 'jsonp';

let qs = require('qs');
let pending = [];
const CancelToken = axios.CancelToken;
let cancelPending = (config) => {
	pending.forEach((item, index) => {
		if (config) {
			if (item.UrlPath === config.url) {
				item.Cancel(); // 取消请求
				pending.splice(index, 1); // 移除当前请求记录
			}

		} else {
			item.Cancel();// 取消请求
			pending.splice(index, 1); // 移除当前请求记录
		}
	});
};

class HttpRequest {
	constructor(baseUrl) {
		this.baseUrl = baseUrl;
		this.queue = {};
	}

	static jsonp(options) {
		return new Promise((resolve, reject) => {
			JsonP(options.url, {
				param: 'callback',
			}, function (err, response) {
				if (response.status === 'success') {
					resolve(response);
				} else {
					reject(response.messsage);
				}
			});
		});
	}


	getInsideConfig() {
		return {
			baseURL: this.baseUrl,
			headers: {
				//
			},
		};
	}


	distroy(url) {
		delete this.queue[url];
		if (!Object.keys(this.queue).length) {
			// Spin.hide()
		}
	}

	interceptors(instance, url) {
		// 请求拦截
		instance.interceptors.request.use(config => {
			// 添加全局的loading...
			if ((config.method.toLowerCase()).indexOf('post') !== -1) {
                if (!(config.data instanceof FormData)) {
                    config.data = qs.stringify({...config.data});
                }
			}

			if (!Object.keys(this.queue).length) {
				// Spin.show() // 不建议开启，因为界面不友好
			}

			this.queue[url] = true;

			cancelPending(config);
			config.cancelToken = new CancelToken(res => {
				pending.push({'UrlPath': url, 'Cancel': res});
			});

			return config;
		}, (error) => {
			return Promise.reject(error);
		});
		// 响应拦截
		instance.interceptors.response.use(res => {
			this.distroy(url);
			const {data, status} = res;
			cancelPending(res.config);
			return {data, status};
		}, (error) => {
			if (error.response) {
				let status = error.response.status;
				switch (status) {
					case 401:
						window.location.href = '#/home';
						localStorage.clear();
						break;
					default:
						break;
				}
				this.distroy(url);
				if (axios.isCancel(error)) { // 取消请求的情况下，终端Promise调用链
					return new Promise(() => {
					});
				}
			}

			return Promise.reject(error);
		});
	}

	request(options) {
		const instance = axios.create();
		options = Object.assign(this.getInsideConfig(), options);
		this.interceptors(instance, options.url);

		return instance(options);
	}
}

export default HttpRequest;
