import React, { Component } from 'react';
import { Card,Avatar } from 'antd';
import './index.less';
const { Meta } = Card;
export default class MessageItem extends Component { 
    
    render() { 
        const item = this.props.item;
        return (
            <Card className="list-card-item">
                <Meta
                    className="user-name user-header-info"
                    avatar={<Avatar src={item.avatar} />}
                    title={item.name}
                    description={<div className="description">{item.description}</div>}/>
                <img alt="图片"  style={{maxWidth:"100%",margin:"0.3rem 0"}} src={item.logo} />
                <div dangerouslySetInnerHTML={{__html: item.content}}></div>
                
               
           </Card>
        )
    }
}