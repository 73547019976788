import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { NavLink } from 'react-router-dom';

class Success extends React.Component {
    getDom () {
        const { status, pay_status, repast_status } = this.props.userInfo;

        if (status === 1 && pay_status === 1) {
            return (
                <NavLink to="/admin/register/information">To complete profile</NavLink>
            );
        }

        if (repast_status === 1 && pay_status === 2) {
            return (
                <NavLink to="/admin/fee">BACK TO Payment Detail</NavLink>
            );
        }

        if (status === 2 && pay_status === 1) {
            return (
                <NavLink to="/admin/information/update">To complete profile</NavLink>
            );
        }

        return (
            <NavLink to="/admin/pre" replace>BACK TO ABSTRACT SUBMISSION</NavLink>
        );
    }

    render () {
        return (
            <div className="success-page container">
                <h1 className="title">SUBMISSION SUCCEED</h1>
                <h3 className="subject-title">Your abstract has been successfully submitted!</h3>
                <p>Please wait for further information of the feedback.<br/>The result will be sent to your e-mail if
                    your
                    abstract has been elected for the conference.<br/>Thank you !</p>

                {this.getDom()}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        userToken: state.userToken,
        userInfo: state.userInfo,
    };
};

export default connect(mapStateToProps)(Success);
