import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, Input, Button, notification } from 'antd';
import axios from '../../../axios/api.request';
import './index.less';

// const Step = Steps.Step;

class StepOne extends Component {
    state = {
        fileList: [],
    };

    formRef = React.createRef();

    constructor (props) {
        super(props);

        this.state = {
            showStep: 0,
            isShow: false,
            loading: false,
            codeResult: 1,
            countDown: '',
            count: 60,
            timer: {},
        };
    }

    componentDidMount () {
        let { history } = this.props;
        if (this.props.userToken) {
            history.push('/register/instruction');
        } else {
            let code_result = localStorage.getItem('code_result_0');
            if (code_result === null || code_result === 1) {
                code_result = 1;
            } else {
                code_result = 0;
            }
            let count = Number(localStorage.getItem('count_0')) || 60;
            this.setState({
                codeResult: code_result, count: count,
            });
            let isRun = Number(localStorage.getItem('code_valid_0'));
            if ((!this.state.codeResult) && isRun) {
                this.countTime();
            }
        }
    }

    componentWillUnmount () {
        localStorage.removeItem('code_result_0');
        localStorage.removeItem('count_0');
        localStorage.removeItem('code_valid_0');
        this.setState({
            codeResult: 1, count: 60, timer: {},
        });
        clearTimeout(this.state.timer);
    }

    // componentWillUnmount
    getCodeHandler = () => {
        let that = this;
        const params = this.formRef.current.getFieldsValue();
        axios.request({
            url: '/send', method: 'post', data: {
                email: params.email,
            },
        }).then((res) => {
            let resData = res.data;
            if (Number(resData.code) === 200) {
                that.countTime();
                localStorage.setItem('code_valid_1', 1);
            }
            notification.info({
                message: 'system notice', description: res.data.message || 'register success',
            });

        });

    };

    //60秒验证码
    countTime = () => {
        var vm = this;
        let count = vm.state.count;
        if (count === 0) {
            this.setState({
                codeResult: 1, count: 60,
            });
        } else {
            this.setState({
                codeResult: 0, count: count - 1,
            });
            vm.state.timer = setTimeout(() => {
                vm.countTime();
            }, 1000);
        }
        localStorage.setItem('code_result_0', this.state.codeResult);
        localStorage.setItem('count_0', this.state.count);
        this.setState({
            countDown: 'Resend activation email(' + count + ')s',
        });
    };

    handleSubmit = () => {
        let { history } = this.props;
        this.formRef.current.validateFields().then((values) => {
            this.setState({ loading: true });
            axios.request({
                url: '/email_register',
                method: 'post',
                data: values,
            }).then((res) => {
                let resData = res.data;

                if (Number(resData.code) === 200) {
                    history.push(`/common/register/success/${values.email}`);
                }

                notification[resData.code === 200 ? 'success' : 'info']({
                    message: 'system notice',
                    description: res.data.message || 'register success',
                });
            }).finally(() => {
                this.setState({
                    loading: false,
                });
            });
        }).catch(() => {
            //
        });
    };

    back = () => {
        let { history } = this.props;
        history.goBack();
    };

    compareToFirstPassword = (rule, value) => {
        return new Promise((resolve, reject) => {
            if (value && value !== this.formRef.current.getFieldValue('password')) {
                reject('Two passwords that you enter is inconsistent!');

                return;
            }

            resolve();
        })
    };

    validateToNextPassword = (rule, value) => {
        return new Promise(resolve => {
            if (value && this.state.confirmDirty) {
                this.formRef.current.validateFields(['confirm']);
            }

            resolve();
        })
    };

    validateEmail = (rule, value) => {
        return new Promise((resolve, reject) => {
            if (!value) {
                resolve();

                return;
            }

            axios.request({
                url: '/check_email',
                method: 'get',
                params: { email: value },
            }).then(res => {
                let resData = res.data;
                if (resData.code === 200) {
                    resolve();
                } else {
                    if (resData.code === -101) {
                        this.setState({
                            isShow: true,
                        });
                    } else {
                        this.setState({
                            isShow: false,
                        });
                    }

                    reject(resData.message);
                }
            }).finally(() => {
                this.setState({
                    loading: false,
                });
            });
        })
    };

    render () {
        return (
            <div className="Registerone">
                <div className="container ">
                    <h1 className="title">Create New Account</h1>
                    {/* <div className="steps-wrap">
                     <Steps progressDot current={1}>
                     <Step title="Instruction"/>
                     <Step title="Email Registration"/>
                     <Step title="Personal Infomation"/>
                     <Step title="Complete"/>
                     </Steps>
                     </div>
                     <div className="prev-btn_div">
                     <Button type="primary" onClick={this.back}><LeftOutlined/> Back</Button>
                     </div> */}

                    <h3 className="title-absolute">
                        <div className="title-text">Login information</div>
                    </h3>
                    <Form ref={this.formRef} layout="vertical" scrollToFirstError>
                        <Row gutter={24} type="flex" align="middle">
                            <Col md={12} xs={24} sm={24}>
                                <Form.Item
                                    name="email"
                                    label="Email"
                                    rules={[
                                        {
                                            message: 'Please input your E-mail!',
                                            required: true,
                                        },
                                        {
                                            validator: this.validateEmail,
                                        },
                                    ]}
                                    validateTrigger="onBlur"
                                >
                                    <Input placeholder="please input email"/>
                                </Form.Item>
                            </Col>
                            {this.state.isShow && (
                                <Col md={6} xs={0} sm={0}>
                                    {this.state.codeResult ? (
                                        <Button
                                            type="primary"
                                            onClick={this.getCodeHandler}
                                        >Resend Activation Email</Button>
                                    ) : (
                                        <Button type="primary" disabled>{this.state.countDown}</Button>
                                    )}
                                </Col>
                            )}
                        </Row>
                        <Row>
                            <Col md={12} xs={24} sm={24}>
                                <Form.Item
                                    name="password"
                                    label="Password"
                                    rules={[
                                        {
                                            message: 'Please input your password!',
                                            required: true,
                                        },
                                        {
                                            validator: this.validateToNextPassword,
                                        },
                                    ]}
                                >
                                    <Input.Password type="password" placeholder="please input password"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} xs={24} sm={24}>
                                <Form.Item
                                    name="confirm"
                                    label="Confirm Password"
                                    rules={[
                                        {
                                            message: 'Please confirm your password!',
                                            required: true,
                                        },
                                        {
                                            validator: this.compareToFirstPassword,
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        type="password"
                                        onBlur={this.handleConfirmBlur}
                                        placeholder="please input password"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={24} sm={24} md={{ span: 6 }}>
                                <Button
                                    size="large"
                                    type="primary"
                                    block
                                    loading={this.state.loading}
                                    className="submit-btn"
                                    onClick={this.handleSubmit}
                                >Registration</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        userToken: state.userToken,
    };
};

export default connect(mapStateToProps)(StepOne);
