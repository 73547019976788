import React from 'react';


export default class NoMatch extends React.Component {
  render() {
    return (
      <section className="container" style={{'textAlign':"center","padding":"10vh 0"}}>
        <div className="venue">
          {/*<h1 className="title" style={{'textAlign':"center"}}>*/}
              {/*<img style={{"display":"inline-block"}} src={require('../../resource/images/404@3x.png')} />*/}
          {/*</h1>*/}
            <h3 style={{"fontSize":"0.6rem","fontWeight":"normal","fontFamily":"AvenirLTStd-Medium","margin":"1rem 0"}}>COMING SOON</h3>
        </div>

      </section>
    )
  }
}