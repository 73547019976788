import axios from '../../axios/api.request';

import {
	message,
} from 'antd';

/*
 * action 类型
 */

export const type = {
	SWITCH_MENU  : 'SWITCH_MENU',
	USER_TOKEN   : 'USER_TOKEN',
	USER_ID      : 'USER_ID',
	USER_INFO    : 'USER_INFO',
	GET_USER_INFO: 'GET_USER_INFO',

};

// 菜单点击切换，修改面包屑名称
export function switchMenu(menuName) {
	return {
		type: type.SWITCH_MENU,
		menuName,
	};
}

// 用户登录，保存token
export function saveUserToken(userToken) {
	return {
		type: type.USER_TOKEN,
		userToken,
	};
}

// 用户登录，保存id
export function saveUserID(userID) {
	return {
		type: type.USER_ID,
		userID,
	};
}

// 获取用户个人信息
export function saveUserInfo(userInfo) {
	return {
		type    : type.USER_INFO,
		userInfo: {...userInfo},
	};
}

// 获取用户个人信息
export function userInfo(token, type, status, pay_status) {
	return dispatch => {
		axios.request({
			url   : '/get_person_info',
			params: {
				api_token: token,
			},
		}).then(res => {
			if (res.status !== 200 || res.data.code !== 200) {
				message.error('Logon failed, please try again');

				return;
			}

			let _uinfo = res.data.data;

			dispatch(saveUserID(_uinfo.id));
			dispatch(saveUserInfo(_uinfo));
			dispatch(saveUserToken(token));

			if (type) {
				//  判断是否注册完成，注册完成则跳转到首页，否则跳转到支付页
				if (_uinfo.status === 2) { // 已注册，已填写填写用户信息
					if (_uinfo.pay_status === 1) { // 未交费
						window.location.href = '#/registration/list';
					} else if (_uinfo.pay_status === 2) { // 已缴费
						window.location.href = '#/registration/list';
					} else if (_uinfo.pay_status === 2 && _uinfo.repast_status === 2) {
						window.location.href = '#/';
					}

					return;
				}

				// 已注册，未填写用户信息
				window.location.href = '#/registration/list';
			}
		}).catch(err => {
			message.error(err.message || 'Logon failed, please try again');
		});
	};
}

// 更新用户
export function updateUser(data, token) {
	return dispatch => {
		axios.request({
			url   : '/update?api_token=' + token,
			method: 'post',
			data,
		}).then(res => {
			if (res.status === 200 && res.data.code === 200) {
				message.success(res.data.message || 'Edit success');
				dispatch(userInfo(token));
			} else {
				message.error('Update failed, please resubmit');
			}
		});
	};
}

// 重发邮箱激活码
export function resendActivationCode(data, token) {
	return dispatch => {
		axios.request({
			url   : '/send',
			method: 'post',
			data,
		}).then(res => {
			if (res.status === 200 && res.data.code === 200) {
				message.success(res.data.message || 'Edit success');
			} else {
				message.error('Update failed, please resubmit');
			}

		});
	};
}

// 登录
export function login({user, pwd}) {
	return dispatch => {
		axios.post('/user/login', {
			user,
			pwd,
		}).then(res => {

		});
	};
}

// 登出
export function logout() {
	return dispatch => {
		dispatch(saveUserID(''));
		dispatch(saveUserToken(''));
		dispatch(saveUserInfo(''));
		// window.location.href="#/login"
	};
}
