import JsonP from 'jsonp'
import axios from 'axios'
import {Modal} from 'antd'
import {baseUrl} from '../config'

export default class Axios {
    static jsonp(options) {
        return new Promise((resolve, reject) => {
            JsonP(options.url, {
                param: 'callback'
            }, function (err, response) {
                if (response.status === 'success') {
                    resolve(response);
                } else {
                    reject(response.messsage);
                }
            })
        })
    }

    static ajax(options) {
        return new Promise((resolve, reject) => {
            axios({
                url: options.url,
                method: options.method || 'get',
                baseURL: baseUrl,
                timeout: 30000,
                params: (options.data && options.data.params) || ''
            }).then(response => {
                if (response.status === 200) {
                    const res = response.data;

                    if (res.code !== 200) {
                        Modal.info({
                            title: 'Tip',
                            content: res.message,
                        });
                    }

                    resolve(res);
                } else {
                    reject(response.data);
                }
            }).catch(reject);
        });
    }
}