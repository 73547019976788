import React, { Component } from 'react';
import { connect } from 'react-redux';
import './index.less';
import { Row, Col, Button } from 'antd';
import { Upload, message } from 'antd';
import axios from '../../../axios/api.request';
import { host, baseUrl } from '../../../config';
import { userInfo } from '../../../redux/action';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

const uploadFileApi = `${baseUrl}/upload`;

function beforeUpload (file) {
    const isJPG = file.type === 'image/jpeg';
    const isPNG = file.type === 'image/png';
    const isPic = isJPG || isPNG;

    if (!isPic) {
        message.error('You can only upload JPG, PNG file!');
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
        message.error('Image must smaller than 5MB!');
    }
    return isPic && isLt5M;
}

class Voucher extends Component {
    state = {
        loading: false,
        imageUrl: '',
    };

    componentWillMount () {
        this.setState({
            imageUrl: this.props.userInfo.payment_voucher ? this.props.userInfo.payment_voucher : '',
        });
    }

    handleChange = (info) => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {
            this.setState({
                imageUrl: info.file.response.data,
            });
        }
    };
    submitHandle = () => {
        axios.request({
            url: '/upload_documents?api_token=' + this.props.userToken,
            method: 'post',
            params: {
                payment_voucher: this.state.imageUrl,
            },
        }).then(res => {
            if (res.data.code === 200 && res.status === 200) {
                message.success('Upload success!');
                let { dispatch } = this.props;
                dispatch(userInfo(this.props.userToken, 1));
            }
        });
    };

    render () {
        const imageUrl = this.state.imageUrl;
        const uploadProps = {
            action: uploadFileApi + '?api_token=' + this.props.userToken,
            listType: 'picture-card',
            showUploadList: false,
            className: 'avatar-uploader',
            onChange: this.handleChange,
            method: 'post',
            name: 'file',
        };
        const uploadButton = (
            <div>
                {this.state.loading ? <LoadingOutlined/> : <PlusOutlined/>}
                <div className="ant-upload-text">UPLOAD</div>
                <p className="ant-upload-hint"> Only supports JPG/PNG, <br/>the size does not exceed 5M</p>
            </div>
        );

        return (<div className="container vourcher-page">
            <h1 className="title">Payment Voucher Upload</h1>
            <h3 className="subject-title">Please upload your payment voucher</h3>
            <Row className="upload-div" gutter={24}>
                <Col span={12} xs={24} sm={24} md={10}>
                    <h5 className="sm-title">Example</h5>
                    <img alt="图片" className="full-width payment-pic"
                         src={require('../../../resource/images/payment_pic.png')}/>
                </Col>
                <Col span={12} xs={24} sm={24} md={{ span: 10, offset: 2 }}>
                    <h5 className="sm-title">Payment Voucher Upload</h5>
                    <Upload beforeUpload={beforeUpload} {...uploadProps} >
                        {imageUrl ?
                            <div className="img-cover">
                                <img style={{ width: '100%', height: '4.99rem' }} src={host + imageUrl}
                                     alt="avatar"/>
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <div className="reupload-div"><a className="reupload-btn">reupload</a></div>
                            </div>
                            : uploadButton}
                    </Upload>
                </Col>
            </Row>
            <Row className="submit-div">
                <Col sm={24} xs={24} md={{ span: 8, offset: 7 }}>
                    <Button size="large" block className="submit-btn" type="primary"
                            onClick={this.submitHandle}>Save</Button>
                </Col>
            </Row>
        </div>);
    }
}

const mapStateToProps = state => {
    return {
        userInfo: state.userInfo,
        userToken: state.userToken,
    };
};
export default connect(mapStateToProps)(Voucher);
