import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Popconfirm, Space, Table } from 'antd';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import axios from '../../../axios/api.request';
import './index.less';

class RegistrationList extends Component {
    state = {
        items: [],
        total: 0,
    };

    tableColumns = [
        {
            title: 'Status',
            dataIndex: 'payment_status',
            render: (text, record) => {
                if (record.payment_status === 2) {
                    return 'Checking';
                }

                if (record.payment_status === 1) {
                    return 'Paid';
                }

                if (record.payment_status === -1) {
                    return 'Cancelled';
                }

                if (record.payment_status === -2) {
                    return 'Refunding';
                }

                return <NavLink to={`/registration/submission?checksum=${record.checksum}`}>Unpaid</NavLink>;
            },
        },
        {
            title: 'View order details',
            dataIndex: 'details',
            render: (_, record) => {
                return `${record.rate === '650.00' ? 'Standard' : 'Local'} Rate + Accompanying Person * ${record.accompanying_person} + Post-conference activities * ${record.post_conference_activities_person}`;
            },
        },
        {
            title: 'Total',
            dataIndex: 'amount_required',
            render: text => {
                return `$${text}`;
            },
        },
        {
            width: 140,
            title: 'Action',
            align: 'center',
            render: (text, record) => {
                if (record.payment_status === 1) {
                    return (
                        <>
                            <Popconfirm
                                title={(
                                    <>
                                        <p>With regard to cancellations and refunds, we followed standard policies of academic conferences.</p>

                                        <div>
                                            <p>Cancellation charges：</p>
                                            <div>Before October 1, a refund fee of $50 will be charged per order</div>
                                            <div>Before October 15, a refund fee of 50% of the order amount will be charged per order</div>
                                            <div>After October 15, No refund</div>
                                        </div>
                                    </>
                                )}
                                placement="topRight"
                                onConfirm={() => {
                                    axios.request({
                                        url: `/registration/submission/refund/${record.id}`,
                                        data: {
                                            api_token: this.props.userToken,
                                        },
                                        method: 'POST',
                                    }).then(rs => {
                                        const { code } = rs.data;

                                        if (code !== 200) {
                                            throw rs.data;
                                        }

                                        this.getItems();
                                    }).catch(err => {
                                        Modal.error({
                                            title: 'Error',
                                            content: err.message || 'Cancel failed.',
                                        });
                                    });
                                }}
                            >
                                <Button type="link" size="small">Cancel</Button>
                            </Popconfirm>
                            <Button
                                type="link"
                                size="small"
                                onClick={() => {
                                    this.props.history.push(`/registration/submission-edit/${record.id}`);
                                }}
                            >Edit</Button>
                        </>
                    );
                }

                return (
                    <Space>
                        <Popconfirm
                            title="Do you want to confirm the deletion ?"
                            disabled={record.payment_status === -2 || record.payment_status === 2}
                            onConfirm={() => {
                                axios.request({
                                    url: `/registration/submission/${record.id}`,
                                    data: {
                                        api_token: this.props.userToken,
                                    },
                                    method: 'DELETE',
                                }).then(rs => {
                                    const { code } = rs.data;

                                    if (code !== 200) {
                                        throw rs.data;
                                    }

                                    this.getItems();
                                }).catch(err => {
                                    Modal.error({
                                        title: 'Error',
                                        content: err.message || 'Deletion failed.',
                                    });
                                });
                            }}
                        >
                            <Button
                                type="link"
                                size="small"
                                danger
                                disabled={record.payment_status === -2 || record.payment_status === 2}
                            >Delete</Button>
                        </Popconfirm>
                        <Button
                            type="link"
                            size="small"
                            disabled={record.payment_status !== 0}
                            onClick={() => {
                                this.props.history.push(`/registration/submission-edit/${record.id}`);
                            }}
                        >Edit</Button>
                    </Space>
                );
            },
        }
    ];

    componentDidMount () {
        if (!this.props.userToken) {
            this.props.history.push('/login');

            return;
        }

        this.getItems();
    }

    getItems() {
        axios.request({
            url: '/registration',
            params: {
                api_token: this.props.userToken,
            },
        }).then(rs => {
            const { items, total } = rs.data.data;

            this.setState({
                items: items || [],
                total: total || 0,
            });
        }).catch(err => {
            Modal.error({
                title: 'Error',
                content: err.message || 'The list content query failed.',
            });
        });
    }

    render () {
        return (
            <div className="container registration-list-page">
                <div className="title">
                    <h1>Registration</h1>
                    <div className="desc">Registration for 2024 lACR Scientific Conference & Chinese Cancer Registry
                        Annual Conference opens on 18th July 2024
                    </div>
                </div>

                <div className="actions">
                    <NavLink
                        to="/registration/submission"
                        disabled={this.state.total >= 5}
                        className="ant-btn ant-btn-primary ant-btn-lg"
                    >
                        <PlusOutlined/> Registration
                    </NavLink>
                </div>

                <Table
                    rowKey="order_no"
                    columns={this.tableColumns}
                    bordered
                    dataSource={this.state.items}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        userInfo: state.userInfo,
        userToken: state.userToken,
    };
};

export default connect(mapStateToProps)(withRouter(RegistrationList));
