import React, {Component} from 'react';
import {contactEmail} from '../../config';
import "./index.less";
// import {NavLink} from "react-router-dom";

export default class Footer extends Component {
    render() {
        return (
            <footer className="app-footer">
                <div className="container footer-content">
                    <h1 className="logo">
                        <img alt="logo" src={require('../../resource/images/logo.png')}/>
                    </h1>

                    <div className="content">
                        <h3>Contact Address and Emails:</h3>

                        <div style={{ marginTop: 20 }}>
                            International Association of Cancer Registries (IACR)<br/>
                            c/o the International Agency for Research on Cancer, 25 Avenue Tony Garnier, 69007 Lyon,
                            France
                        </div>

                        <div style={{ marginTop: 20 }}>
                            National Cancer Center/Cancer Hospital, Chinese Academy of Medical Sciences, 17# South
                            Panjiayuan Lane, P. O. Box 2258, Beijing 100021 China
                        </div>

                        <div style={{ marginTop: 20 }}>PRESENTERS (ORAL AND POSTERS): FOR INQUIRIES, PLEASE CONTACT <a
                            href={`mailto:${contactEmail}`}>{contactEmail}</a></div>
                    </div>
                </div>

                {/* {!this.props.hideMenu && (
                 <div className="footer-link">
                 <ul className="container">
                 <li><NavLink to="/congress/welcome">CONGRESS INFORMATION</NavLink></li>
                 <li><NavLink to="/PROGRAM">SCHEDULE & PROGRAM</NavLink></li>
                 <li><NavLink to="/abstract/submission">ABSTRACT</NavLink></li>
                 <li><NavLink to="/register/instruction">REGISTRATION & ACCOMODATION</NavLink></li>
                 <li><NavLink to="/sponsorship">SPONSORSHIP</NavLink></li>
                 <li><NavLink to="/MEDIA">PRESS & MEDIA</NavLink></li>
                 <li><NavLink to="/about">ABOUT BEIJING</NavLink></li>
                 <li><NavLink to="/contact">CONTACT US</NavLink></li>
                 </ul>
                 </div>
                 )} */}

                <div className="copyright">
                    <div className="container">
                        {/* eslint-disable-next-line react/jsx-no-target-blank */}
                        <a href="//www.iacr2024.com/law/law.html" ref="noopener noreferrer" target="_blank">Specified Commercial Transaction Act</a>
                    </div>
                </div>
            </footer>
        );
    }
}
