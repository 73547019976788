import congressvenue_banne from '../../resource/images/beijing_bg.png';
import congressvenue_pic1 from '../../resource/images/beijing_pic1.png';
import congressvenue_pic2 from '../../resource/images/beijing_pic2.png';

const pageData = {
  banner: congressvenue_banne,
  imgs: [congressvenue_pic1, congressvenue_pic2],
  listData: [
    {
      content: '<p><strong class="first-letter">B</strong>eijing is high in the northwest and low in the southeast. It is surrounded by mountains in the west, north and northeast. The main rivers that flow through the territory include yongding river, chaobai river, north canal, and juma river, etc., most of which originate from the mountains in the northwest, pass through the mountains, meander through the plain in the southeast, and finally flow into the bohai sea. Beijing is a typical warm temperate semi-humid continental monsoon city; hot and rainy in summer, cold and dry in winter, and short in spring and autumn.</p>'
    },
    {

      content: '<p>eijing is high in the northwest and low in the southeast. It is surrounded by mountains in the west, north and northeast. The main rivers that flow through the territory include yongding river, chaobai river, north canal, and juma river, etc., most of which originate from the mountains in the northwest, pass through the mountains, meander through the plain in the southeast, and finally flow into the bohai sea. Beijing is a typical warm temperate semi-humid continental monsoon city; hot and rainy in summer, cold and dry in winter, and short in spring and autumn.</p>'
    },
    {
      content: '<p>Governed as a municipality under the direct administration of the national government, Beijing is divided into 14 urban and suburban districts and two rural counties. It is a major transportation hub, with dozens of railways, roads and motorways passing through the city, and the destination of many international flights to China.</p>'
    },
    {
      content: '<p>Few cities in the world have served as long as the political and cultural center of an area as immense. Beijing is one of the Four Great Ancient Capitals of China. It has been the heart of China’s history for centuries, and there is scarcely a major building of any age in Beijing that does not have at least some national historical significance. The city is renowned for its opulent palaces, temples, and huge stone walls and gates. Its art treasures and universities have long made it a center of culture and art in China.</p>'
    }
  ]

};
export default pageData;