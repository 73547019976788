import congressvenue_banne from '../../../resource/images/congressvenue_banner.png';
import congressvenue_pic1 from '../../../resource/images/congressvenue_pic1.png';
import congressvenue_pic2 from '../../../resource/images/congressvenue_pic2.png';
const pageData = {
    banner: congressvenue_banne,
    listData: [
        {
            cover: congressvenue_pic1,
            content: '<p><strong class="first-letter">C</strong>hina National Convention Center is formerly the main Press Center (MPC), International Broadcasting Center (IBC) and the venue for fencing and pistol shooting competitions during the Olympic Games in 2008.</p > <p>It is located on the landmark site adjacent to the National Stadium (Bird’s Nest) and the National Aquatics Center (Water Cube) on the Olympic Green boulevard. It only takes 25 minutes (26 km) from the Beijing Capital International Airport (BCIA). </p>'
        },
        {
            cover: congressvenue_pic2,
            content: '<p><strong class="first-letter">C</strong>hina National Convention Center is an efficient transport system including subway and buses links the location to hotels as well as major tourist attractions and is linked directly to the major road system for freight access, surrounded by a range of over 30 three, four and five-star hotels with more than 10,000 hotel rooms within 10 minutes (5 km). Besides, it is closely near to universities, research institutes and commercial districts.</p > <p>With integrated facilities, advanced equipment and convenient transportation, China National Convention Center is a perfect place for all the participants.</p>'
        }
    ]

}
export default pageData;