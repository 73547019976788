import React, { Component } from 'react';
import { Button } from 'antd';
import connect from 'react-redux/es/connect/connect';
import './index.less';
import { ArrowRightOutlined } from '@ant-design/icons';

class Abstract extends Component {
    navigateToAbstract = () => {
        let { userToken, history } = this.props;
        if (userToken) {
            history.push('/admin/upload');
        } else {
            history.push('/login');
        }
    };

    render () {
        return (
            <section className="abstract-page">
                <h1 className="title">ONLINE ABSTRACT SUBMISSION GUIDELINES</h1>

                <div className="container submission-info">
                    <div className="title-text">
                        <b>Plenary themes</b> under which authors can submit their abstracts:
                    </div>
                    <ul>
                        <li>
                            <div>Plenary topics:</div>
                            <ol>
                                <li>National standard, regulation, and law on cancer registry in different countries
                                </li>
                                <li>Stage, comorbidities, and other prognostic information in cancer registration</li>
                                <li>Descriptive epidemiology including special populations (e.g., children, adolescents
                                    and young adults)
                                </li>
                                <li>Registry innovation, research and health policy</li>
                                <li>Collaboration, innovation and training in registry practice</li>
                            </ol>
                        </li>
                    </ul>

                    <div className="title-text" style={{ marginTop: 20 }}>
                        <b>The submission deadline is 16 May 2024 24:00（China Standard Time，CST）.</b> Please make sure your abstract meets the
                        following requirements listed below:
                    </div>
                    <ul>
                        <li>Registration is not a pre-condition for abstract submission, but you are encouraged to
                            register as soon as possible.
                        </li>
                        <li>Choose the theme that is most relevant to your abstract.</li>
                        <li><b>Authors.</b> including the main author can be included in the author list. The list of
                            authors is not included in the total word count.
                        </li>
                        <li>An author may submit a maximum of five (5) abstracts.</li>
                        <li>
                            <b>Abstract Format:</b>
                            <ol>
                                <li>The abstract title should be limited to 15 words (but this will not be included in
                                    word count) and typed in UPPERCASE.
                                </li>
                                <li>
                                    <div>The body of the abstract should include four parts:</div>
                                    <ol>
                                        <li>Background</li>
                                        <li>Methods</li>
                                        <li>Results</li>
                                        <li>Discussion and Conclusion</li>
                                    </ol>
                                </li>
                                <li>Do not include tables or pictures.</li>
                                <li>The maximum word count is 300 words, written in English.</li>
                                <li>Please do not use hyphenation to separate a word at the end of a line to start a new
                                    line. The ends of line will not necessarily match with the printing layout.
                                </li>
                                <li>Authors may indicate their presentation preference: oral or poster presentation. The
                                    decision on presentation format will be confirmed by the conference’s Scientific
                                    Program Committee who is tasked with evaluating the submitted abstracts.
                                </li>
                            </ol>
                        </li>
                    </ul>

                    <div className="title-text" style={{ marginTop: 20 }}>
                        <b>Submission.</b> Abstracts must be submitted through the 2024 conference portal.
                    </div>

                    <div className="title-text" style={{ marginTop: 20 }}>
                        <b>Acceptance of abstracts</b> into the conference programme as oral or poster presentations
                        will be based on the evaluation the <b>IACR Abstract Review Committee.</b>
                    </div>
                    <ul className="list-style-dot">
                        <li>Authors will receive a notification on the evaluation of their respective abstracts via the
                            email addressed specified during the abstract submission.
                        </li>
                        <li>Authors of accepted abstracts will be required to confirm their attendance and finalize
                            their registration prior to final inclusion in the conference program.
                        </li>
                        <li>Accepted abstracts (oral and poster presentations) will be published in the 2024 Book of
                            Abstracts. Abstracts may be edited by the organizers to ensure conformity in presentation,
                            language, syntax. Authors will have the opportunity to check that the contents have been
                            respected.
                        </li>
                    </ul>

                    <div className="title-text" style={{ marginTop: 20 }}>
                        <b>FELLOWSHIPS.</b> Authors whose abstracts are accepted for oral presentation may apply for
                        IACR fellowships to support travel to the conference: more details and application for
                        fellowships here: You may open the survey in your web browser by clicking the link below:
                    </div>
                    <ul className="list-style-dot">
                        <li>
                            IACR 2024 Fellowship Application (hyperlink to: <a
                            href="https://www.iacr.com.fr/index.php?option=com_content&view=category&layout=blog&id=84&Itemid=542"
                            rel="noopener noreferrer"
                            target="_blank">https://www.iacr.com.fr/index.php?option=com_content&view=category&layout=blog&id=84&Itemid=542</a>)
                        </li>
                    </ul>

                    <div style={{ width: 200, margin: '0 auto', padding: '120px 0 20px' }}>
                        <Button
                            type="primary"
                            size="large"
                            block
                            className="submit-a"
                            onClick={this.navigateToAbstract}
                        >
                            Submit <ArrowRightOutlined/>
                        </Button>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = state => {
    return {
        userInfo: state.userInfo,
        userToken: state.userToken,
    };
};

export default connect(mapStateToProps)(Abstract);
