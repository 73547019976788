import React from 'react';
import Item from './Item'
import sourceData from './data'


export default class Hotel extends React.Component {
    render() {
        return (
          <section className="container">
              <div className="venue">
                  <h1 className="title">HOTEL RESERVATION</h1>
                  {
                      sourceData.map((v, k) => {
                          return <Item key={k + '01'} item={v}/>
                      })
                  }
              </div>
          </section>
        )
    }
}