import React from "react";
import ad_pic from '../../../resource/images/unsplash.png';

const data = {
    columns: [
        {
            title: ' All fees are shown in U.S. dollars',
            dataIndex: 'name',
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            render: name => <a href="#" style={{color: "#2258A7"}}>{name}</a>
        },
        {
            title: 'EARLY   (Before Aug. 09)',
            dataIndex: 'one',
        },
        {
            title: 'ADVANCE   (Aug. 10 - Nov. 5)',
            dataIndex: 'two',
        },
        {
            title: 'Meeting Duration （Nov.06-Nov.10）',
            dataIndex: 'three',
        }
    ],
    data_src: [
        {
            key: '1',
            name: 'Regular Participants',
            one: '$800',
            two: '$900',
            three: '$1010',
        },
        {
            key: '2',
            name: 'Invited Chairs & Speakers',
            one: '$740',
            two: '$740',
            three: '$740',
        },
        {
            key: '3',
            name: 'OESO Member',
            one: '$740',
            two: '$800',
            three: '$850',
        },
        {
            key: '4',
            name: 'Young Investigator',
            one: '$740',
            two: '$850',
            three: '$960',
        },
        {
            key: '5',
            name: 'Fellow',
            one: '$640',
            two: '$740',
            three: '$850',
        },
        {
            key: '6',
            name: 'Groups (no less than 10 delegates)',
            one: '$740',
            two: '$850',
            three: '$960',
        },
        {
            key: '7',
            name: 'Accompanying Person',
            one: '$210',
            two: '$260',
            three: '$320',
        }
    ],
    arr: [
        {
            content: 'Access to all scientific sessions',
            _key: '01'
        }, {
            content: 'Access to the Exhibition Hall and Poster viewing area during opening hours',
            _key: '02'
        }, {
            content: 'Access to all OESO2019 working lunches and tea breaks. (For details and the final schedule please visit the program page.)',
            _key: '03'
        }, {
            content: 'Tea  breaks and Working lunches on November 8 & 9',
            _key: '04'
        }, {
            content: 'Access to the Opening Ceremony and welcome reception on November 7, 2019',
            _key: '05'
        }, {
            content: 'Online access to  program information',
            _key: '06'
        }, {
            content: 'Registered delegates are entitled to register for Gala Dinner, Working Dinner and other social activities (additional fees)',
            _key: '07'
        },


    ],

    payment: [
        {
            title: 'Payment',
            content: 'Please note that all bank charges need to be covered by the delegate.'
        },
        {
            title: 'By Bank transfer',
            content: 'Bank transfer payment needs to be identified by the registration number and participant’s full name included in the payment details.'
        },
        {
            title: 'Onsite Registration',
            content: 'Please note that on-site registration payment can be processed by credit card or paid in cash.'
        },
    ],
    listData: [
        {
            title: 'Confirmation',
            content: 'Each participant will receive an email notification confirming the received payment and the amount outstanding after the completion of the Online Registration.'
        }, {
            title: 'Cancellation / Refund of the Registration fees',
            content: '<p>Cancellations must be made in writing by e-mail to the congress secretariat (OESO2019@sina.com). Cancellations will be accepted until October 25, 2019.All the handling charges need to be covered by the candidate. No refunds will be made for cancellations received after this date. Refunds will only be made via the original payment method after the congress.</p>'
        }],
    descript: 'Description of membership types and registration categories',
    relationship1: 'Regular participant: Audience',
    relationship2: 'Invited Chairs & Speakers: special registration fee of $740 whatever the date of registration.',
    relationship3: 'OESO Members: those who apply for OESO member registration must provide an OESO ID subject to OESO confirmation.',
    relationship4: 'Young Investigators : born on or after 1984, who have finished training and are currently pursuing an interest in basic or clinical research (ID requested during the registration process).',
    relationship5: 'Fellow: enrolled in an accredited fellowship program and will finish their training before July 1, 2021. The fellows who fulfil the above requirement qualify for a reduced registration fee. (Certification or a recommendation letter may be requested during the registration process).',
    relationship6: 'Accompanying Person: Access to Opening Ceremony (Thursday, Nov. 7, 6:00 pm) and welcoming reception.',

    relax: 'Take some time to relax, reconnect with friends and meet new colleagues during this unforgettable cultural event. Experience the city of Beijing while enjoying dinner and entertainment, showcasing Beijing’s cuisine and arts.',
    relax_friends: 'Take some time to relax, reconnect with friends and meet new colleagues during this unforgettable cultural event. Experience the city of Beijing while enjoying dinner and entertainment, showcasing Beijing’s cuisine and arts.',
    register_file: ad_pic,
    dinner_link: '',
    subject_dinner_link: '',

};
export default data;