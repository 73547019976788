import React from 'react';
import { Row, Col, Card } from 'antd';
import './index.less'


export default class Concat extends React.Component {
  render() {
    return (
      <section className="container">
        <div className="contact">
          <h1 className="title">CONTACT US</h1>
          <h3 className="md-title">Scientific coordination</h3>
          <div className="sub-title"><label className="inb">R. Giuli</label>MD/FACS/Professor of Surgery</div>
          <Row gutter={24} type="flex" style={{marginBottom:'0.8rem'}}>
            <Col xs={24}  md={{span:8}}>
              <Card className='full-height'>
                <dl>
                  <dt>
                    <img alt="图片" src={require("../../resource/images/b-phone.png")} />
                  </dt>
                  <dd>
                    <h5>Phone</h5>
                    <p>+33 (0)1 55 37 90 15</p>
                  </dd>
                </dl>
              </Card>
            </Col>
            <Col xs={24}  md={{span:8}}>
              <Card  className='full-height'>
                <dl>
                  <dt>
                    <img alt="图片"  src={require("../../resource/images/b-location.png")} />
                  </dt>
                  <dd>
                    <h5>Address</h5>
                    <p>OESO – 2 Blvd Pershing 75017</p>
                    <p>Paris / France</p>
                  </dd>
                </dl>
              </Card>
            </Col>
            <Col xs={24}  md={{span:8}}>
              <Card className='full-height'>
                <dl>
                  <dt>
                    <img alt="图片"  src={require("../../resource/images/b-email.png")} />
                  </dt>
                  <dd>
                    <h5>Email</h5>
                    <p>robert.giuli@oeso.org</p>
                  </dd>
                </dl>
              </Card>
            </Col>
          </Row>
          <div>
            <h3 className="md-title">Organizing secretariat</h3>
            <div className="sub-title"><label className="inb">Ms. Violet Liu</label>Beijing FC Vision Commercial Service Ltd</div>
            <Row gutter={24} type="flex" style={{marginBottom:'0.8rem'}}>
              <Col xs={24}  md={{span:8}}>
                <Card className='full-height'>
                  <dl>
                    <dt>
                      <img alt="图片"  src={require("../../resource/images/b-phone.png")} />
                    </dt>
                    <dd>
                      <h5>Phone</h5>
                      <p>+86 1512 0032 285</p>
                    </dd>
                  </dl>
                </Card>
              </Col>
              <Col xs={24}  md={{span:8}}>
                <Card className='full-height'>
                  <dl>
                    <dt>
                      <img alt="图片"  src={require("../../resource/images/b-location.png")} />
                    </dt>
                    <dd>
                      <h5>Address</h5>
                      <p> Dongzhimenwai Street, Dongcheng </p>
                      <p> district, Beijing</p>
                    </dd>
                  </dl>
                </Card>
              </Col>
              <Col xs={24}  md={{span:8}}>
                <Card className='full-height'>
                  <dl>
                    <dt>
                      <img alt="图片"  src={require("../../resource/images/b-email.png")} />
                    </dt>
                    <dd>
                      <h5>Email</h5>
                      <p>OESO2019@sina.com</p>
                    </dd>
                  </dl>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </section>
    )
  }
}