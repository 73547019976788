import React, { Component } from 'react';
import { Row, Col, Form, Input, Button, message, Card } from 'antd';
import '../index.less';
import axios from '../../../../axios/api.request';

class Reset extends Component {
    formRef = React.createRef();

    componentDidMount () {
        let userEmail = this.props.match.params.userEmail;
        this.setState({
            userEmail,
        });
    }

    handleSubmit = () => {
        this.formRef.current.validateFields().then(values => {
            if (this.state.userEmail) {
                this.handleReset(values);
            }
        });
    };
    // 重置密码
    handleReset = (params) => {
        params['email'] = this.state.userEmail;
        axios.request({
            url: '/reset_password',
            method: 'post',
            data: params,
        }).then(res => {
            if (res.status === 200 && res.data.code === 200) {
                this.props.history.push('/login');
            } else {
                message.error(res.data.message || 'message: "Mailbox does not exist');
            }
        });
    };
    // 新密码校验
    compareToFirstPassword = (rule, value, callback) => {
        if (value && value !== this.formRef.current.getFieldValue('new_password')) {
            callback('Two passwords that you enter is inconsistent!');
        } else {
            callback();
        }
    };
    // 确认密码校验
    validateToNextPassword = (rule, value, callback) => {
        if (value && this.state.confirmDirty) {
            this.formRef.current.validateFields(['confirm_password']);
        }
        callback();
    };

    render () {
        return (
            <div className="forgot-page">
                <div className="container">
                    <div className="login-content">
                        <Row gutter={24} type="flex" align="middle">
                            <Col md={{ span: 20, offset: 4 }}>
                                <h3 className="subject-title"
                                    style={{ fontSize: '0.3rem', marginBottom: '0.7rem' }}>Please reset your
                                    password</h3>
                            </Col>
                            <Col span={12} xs={24} sm={24} md={{ span: 8, offset: 4 }}>
                                <Card bordered={false}>
                                    <Form ref={this.formRef} layout="vertical" scrollToFirstError>
                                        <Form.Item
                                            name="new_password"
                                            label="Password"
                                            rules={[
                                                {
                                                    required: true, message: 'Please input your new password!',
                                                },
                                                {
                                                    validator: this.validateToNextPassword,
                                                },
                                            ]}
                                        >
                                            <Input.Password type="password"/>
                                        </Form.Item>
                                        <Form.Item
                                            name="confirm_password"
                                            label="Confirm Password"
                                            rules={[
                                                {
                                                    required: true, message: 'Please confirm your password!',
                                                },
                                                {
                                                    validator: this.compareToFirstPassword,
                                                },
                                            ]}
                                        >
                                            <Input.Password type="password" onBlur={this.handleConfirmBlur}/>
                                        </Form.Item>
                                        <Form.Item>
                                            <Button
                                                className="submit-btn"
                                                block
                                                type="primary"
                                                onClick={this.handleSubmit}
                                            >Sign in</Button>
                                        </Form.Item>
                                    </Form>
                                </Card>
                            </Col>
                            <Col xs={24} sm={24} md={{ span: 8, offset: 4 }}>
                                <Card bordered={false}>
                                    <h4 className="md-title">15th OESO World Conference-</h4>
                                    <p>Global Perspective and Novel Technologies for Esophageal Diseases</p>
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <a>Don’t have an OESO ID? Create one now.</a>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default Reset;
