import hotel11 from '../../../resource/images/hotel1-1.png';
import hotel12 from '../../../resource/images/hotel1-2.png';
import hotel13 from '../../../resource/images/hotel1-3.png';
import hotel14 from '../../../resource/images/hotel1-4.png';

import hotel21 from '../../../resource/images/hotel2-1.png';
import hotel22 from '../../../resource/images/hotel2-2.png';
import hotel23 from '../../../resource/images/hotel2-3.png';
import hotel24 from '../../../resource/images/hotel2-4.png';

import hotel31 from '../../../resource/images/hotel3-1.png';
import hotel32 from '../../../resource/images/hotel3-2.png';
import hotel33 from '../../../resource/images/hotel3-3.png';
import hotel34 from '../../../resource/images/hotel3-4.png';

import hotel41 from '../../../resource/images/hotel4-1.png';
import hotel42 from '../../../resource/images/hotel4-2.png';
import hotel43 from '../../../resource/images/hotel4-3.png';
import hotel44 from '../../../resource/images/hotel4-4.png';

import hotel51 from '../../../resource/images/hotel5-1.png';
import hotel52 from '../../../resource/images/hotel5-2.png';
import hotel53 from '../../../resource/images/hotel5-3.png';
import hotel54 from '../../../resource/images/hotel5-4.png';

import hotel61 from '../../../resource/images/hotel6-1.png';
import hotel62 from '../../../resource/images/hotel6-2.png';
import hotel63 from '../../../resource/images/hotel6-3.png';
import hotel64 from '../../../resource/images/hotel6-4.png';


const data = [
    {
        title: 'China National Convention Center',
        location: 'Building 1, Courtyard 8, Beichen West Road Chaoyang District Beijing 100105 China',
        imgs: [hotel11, hotel12, hotel13, hotel14],
        fee: 'From $144',
        htmlText: [
            {
                text: 'Distance from China National Convention Center',
                desc: '<dl class="prex"><dt>50 meters</dt> </dl>'
            },
            {
                text: 'Transportation',
                desc: '<dl class="prex"><dt>2 mins’ walking</dt><dd>(the hotel building is connected with China National Convention</dd></dl>'
            }
        ],
        list: [{
            icon: 'tel',
            text: '+86 10 8437 2008       400 698 2008'
        }, {
            icon: 'print',
            text: '+86 10 8437 0387'
        }, {
            icon: 'email',
            text: 'cncc@cnccchina.com'
        }, {
            icon: 'global',
            text: '<a href="http://meeting.cnccgrandhotel.com/m158/hotel_s_reg.aspx" target="_blank">http://meeting.cnccgrandhotel.com/m158/hotel_s_reg.aspx</a>'
        }]
    },
    {
        title: 'Grand Skylight Catic Hotel',
        location: 'No.18 Beichen East Road Chaoyang District Beijing 100101 China',
        fee: 'From $147',
        imgs: [hotel21, hotel22, hotel23, hotel24],
        htmlText: [
            {
                text: 'Distance from China National Convention Center',
                desc: '<dl class="prex"><dt>1500 meters </dt> </dl>'
            },
            {
                text: 'Transportation',
                desc: '<dl class="prex"><dt>22 mins’ walking</dt></dl><dl class="prex"><dt>10 mins’ taxi</dt></dl>'
            }
        ],
        list: [{
            icon: 'tel',
            text: '+86 10 8497 1188'
        }, {
            icon: 'global',
            text: '<a href="http://grandskylightbeijing.com/" target="_blank">http://grandskylightbeijing.com/</a>'
        }]

    },
    {
        title: 'Pangu 7 Star Hotel Beijing',
        location: 'No.27 North 4th Ring Road (Pangu Daguan) Chaoyang District Beijing 100101 China',
        imgs: [hotel31, hotel32, hotel33, hotel34],
        fee: 'From $392',
        htmlText: [
            {
                text: 'Distance from China National Convention Center',
                desc: '<dl class="prex"><dt>760 meters </dt> </dl>'
            },
            {
                text: 'Transportation',
                desc: '<dl class="prex"><dt>11 mins’ walking</dt></dl>'
            }
        ],
        list: [{
            icon: 'tel',
            text: '+86 10 5906 7777'
        }, {
            icon: 'print',
            text: '+86 10 5906 7888'
        }, {
            icon: 'email',
            text: 'info@panguhotel.com'
        }, {
            icon: 'global',
            text: '<a href="http://www.panguhotel.com/en/" target="_blank">http://www.panguhotel.com/en/</a>'
        }]
    },
    {
        title: 'Marco Polo Parkside Beijing',
        location: 'No.78 Anli Road Chaoyang District Beijing 100101 China',
        imgs: [hotel41, hotel42, hotel43, hotel44],
        fee: 'From $256',
        htmlText: [
            {
                text: 'Distance from China National Convention Center',
                desc: '<dl class="prex"><dt>1800 meters </dt> </dl>'
            },
            {
                text: 'Transportation',
                desc: '<dl class="prex"><dt>metro (line 15, from Anli Road to Olympic park )</dt></dl><dl class="prex"><dt>24 mins’ walking</dt></dl><dl class="prex"><dt>9 mins’ taxi</dt></dl>'
            }
        ],
        list: [{
            icon: 'tel',
            text: '(86 10) 5963-6688'
        }, {
            icon: 'print',
            text: '(86 10) 5963-6500'
        }, {
            icon: 'email',
            text: 'parkside@marcopolohotels.com'
        }, {
            icon: 'global',
            text: '<a href="https://www.marcopolohotels.com/hotels/china/beijing/marco_polo_parksideindex.html" target="_blank">https://www.marcopolohotels.com/hotels/china/beijing/marco_polo_parksideindex.html</a>'
        }]
    },
    {
        title: 'V-Continent Beijing Parkview Wuzhou Hotel',
        location: 'No.8 North 4th Ring Road Chaoyang District Beijing 100101 China ',
        fee: 'From $173',
        imgs: [hotel51, hotel52, hotel53, hotel54],
        htmlText: [
            {
                text: 'Distance from China National Convention Center',
                desc: '<dl class="prex"><dt>2100 meters </dt> </dl>'
            },
            {
                text: 'Transportation',
                desc: '<dl class="prex"><dt>Bus (Bus 82, National Stadium East to Beijing National Indoor Stadium Bus 379, Anhui Bridge north to Wali Nankou)30 mins’ walking or 11 mins’ taxi</dt></dl><dl class="prex"><dt>30 mins’ walking</dt></dl><dl class="prex"><dt>11 mins’ taxi</dt></dl>'
            }
        ],
        list: [{
            icon: 'tel',
            text: '+86 10 8498 2288'
        }, {
            icon: 'email',
            text: 'reservation@v-continent.com'
        }, {
            icon: 'global',
            text: '<a href="https://www.v-continent.com/en-gb" target="_blank">https://www.v-continent.com/en-gb</a>'
        }]
    },
    {
        title: 'Zhi Resort',
        location: 'No.1 Ziyu East Road Chaoyang District Beijing 100012 China',
        imgs: [hotel61, hotel62, hotel63, hotel64],
        fee: 'From $162',
        htmlText: [
            {
                text: 'Distance from China National Convention Center',
                desc: '<dl class="prex"><dt>2800 meters </dt> </dl>'
            },
            {
                text: 'Transportation',
                desc: '<dl class="prex"><dt>metro (line 15, from Anli Road to Olympic park )</dt></dl>'
            }
        ],
        list: [{
            icon: 'tel',
            text: '+86 10 6491 7488'
        }, {
            icon: 'global',
            text: '<a href="http://www.zhiresort.cn/" target="_blank">http://www.zhiresort.cn/</a>'
        }]
    }
];

export default data