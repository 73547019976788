import React from 'react';
import {Row, Col, Card, Carousel} from 'antd';
import './index.less'
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

export default class ListItem extends React.Component {
    handlePrev = () => {
        this.refs.swipe.prev();
    };
    handleNext = () => {
        this.refs.swipe.next();
    };
    carouselChange = (a, b) => {
        if (this.refs.swipeBigPic) {
            this.refs.swipeBigPic.slick.innerSlider.slickGoTo(a);
        }
    };

    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 1,
            // centerMode: true,
            focusOnSelect: true,

        };
        const settings1 = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            focusOnSelect: true,
        };
        const item = this.props.item;
        return (
          <div className="item">
              <Card bordered={false}>
                  <dl className="item-dl">
                      <dt>{item.title}</dt>
                      <dd><i className="icon icon-location"></i>{item.location}</dd>
                  </dl>
                  <Row>
                      <Col xs={24} sm={24} md={{span: 10}} className='left-content'>
                          <div className="carousel-box">
                              <div className="cover">
                                  <Carousel  {...settings1} ref="swipeBigPic">
                                      {
                                          item.imgs.map((v, k) => {
                                              return (
                                                <div key={k * 10}>
                                                    <img className="full-width" src={v} alt="" />
                                                </div>
                                              )
                                          })
                                      }
                                  </Carousel>
                                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                  <a className="price">{item.fee}</a>
                              </div>
                              <div className="carousel-list">
                                  <Row>
                                      <Col xs={20} sm={20} md={{span: 20}}>
                                          <Carousel afterChange={this.carouselChange} {...settings} ref="swipe">
                                              {
                                                  item.imgs.map((v, k) => {
                                                      return (
                                                        <div key={k * 20}>
                                                            <img className="full-width" src={v} alt="" />
                                                        </div>
                                                      )
                                                  })
                                              }
                                          </Carousel>
                                      </Col>
                                      <Col xs={4} sm={4} md={{span: 4}}>
                                          <div className="check-btn pre" onClick={this.handlePrev}>
                                              <LeftOutlined/>
                                          </div>
                                          <div className="check-btn next" onClick={this.handleNext}>
                                              <RightOutlined/>
                                          </div>
                                      </Col>
                                  </Row>
                              </div>
                          </div>
                      </Col>
                      <Col xs={24} sm={24} md={{span: 10, offset: 1}}>
                          {
                              item.htmlText.map((v, k) => {
                                  return (<div key={k * 30}>
                                      <h5>{v.text}</h5>
                                      <div dangerouslySetInnerHTML={{__html: v.desc}}></div>
                                  </div>)
                              })
                          }
                          <ul className="relation-list">
                              {
                                  item.list.map((v, k) => {
                                      return (
                                        <li key={k * 40}><i className={`icon  icon-${v.icon}`}></i>
                                            <div dangerouslySetInnerHTML={{__html: v.text}}></div>
                                            </li>
                                      )
                                  })
                              }
                          </ul>
                      </Col>
                  </Row>
              </Card>
          </div>
        )
    }
}
