import React, { Component, createRef } from 'react';
import {
    Button,
    Checkbox,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber, message,
    Modal,
    Radio,
    Row,
    Select,
    Space,
    Upload,
} from 'antd';
import connect from 'react-redux/es/connect/connect';
import './index.less';
import { ArrowLeftOutlined, ArrowRightOutlined, LinkOutlined, UploadOutlined } from '@ant-design/icons';
import FormItem from 'antd/lib/form/FormItem';
import axios from '../../../axios/api.request';
import moment from 'moment';

class RegistrationSubmission extends Component {
    state = {
        id: undefined,
        step: 1,
        form: {
            step2: {
                rate: undefined,
                accompanying_person: 0,
                post_conference_activities_person: 0,
                post_conference_activities_attach: undefined,
            },
            step3: {
                title: undefined,
                position: undefined,
                last_name: undefined,
                first_name: undefined,
                gala_dinner: undefined,
                organization: undefined,
                primary_email: undefined,
                morning_workshop: undefined,
                welcome_reception: undefined,
                afternoon_workshop: undefined,
                dietary_requirements: undefined,
                primary_address_country: undefined,
                special_assistance_requirements: undefined,
                other_dietary_requirements_allergies: undefined,
            },
            step4: {
                gender: undefined,
                nationality: undefined,
                superior_double_room: 0,
                superior_twin_room: 0,
                ancient_double_room: 0,
                ancient_twin_room: 0,
                superior_deluxe_room: 0,
                superior_suite_room: 0,
                executive_deluxe_room: 0,
                executive_suite_room: 0,
                phone_number: undefined,
                visa_required: undefined,
                date_of_birth: undefined,
                check_in_date: undefined,
                check_out_date: undefined,
                passport_number: undefined,
                date_of_check_out: undefined,
                other_room_request: undefined,
                phone_number_prefix: undefined,
                hotel_book_required: undefined,
                for_visa_support_letter: undefined,
                issue_date_of_passport: undefined,
                expiry_date_of_passport: undefined,
                full_name_as_on_passport: undefined,
                date_of_arrival_check_in: undefined,
                accommodation_reservation: undefined,
                front_page_of_the_passport: undefined,
                other_info_required_by_chinese_embassy_for_visa_support: undefined,
            },
        },
        order: {
            payment_status: undefined,
        },
        config: {
            country_regions: [],
            phone_number_prefixes: [],
        },
        isEdit: false,
        loading: false,
        checksum: undefined,
        total_amount: 0,
        step2FormRef: createRef(),
        step3FormRef: createRef(),
        step4FormRef: createRef(),
        payment_status: 0,
        loading_payment: false,
        front_page_of_the_passport_file_list: [],
    };

    componentDidMount () {
        const { match } = this.props;

        const isEdit = match.path === '/registration/submission-edit/:id';

        this.setState({
            id: match.params.id,
            isEdit,
        }, () => {
            if (!this.checkLoginState()) {
                return;
            }

            const { history, userToken } = this.props;

            if (isEdit) {
                if (!match.params.id) {
                    Modal.error({
                        title: 'Error',
                        content: 'The id is required.',
                        onOk: () => {
                            history.replace('/registration/submission');
                        },
                    });

                    return;
                }

                this.getDetail();
            } else {
                const params = new URLSearchParams(this.props.location.search);
                const checksum = params.get('checksum');

                if (checksum) {
                    axios.request({
                        url: `/registration/detail/${checksum}`,
                        params: {
                            api_token: userToken,
                        },
                    }).then(({ data }) => {

                        if (data.code !== 200) {
                            throw data;
                        }

                        this.setState({
                            id: data.data.id,
                            step: 5,
                            form: {
                                ...this.state.form,
                                step2: {
                                    ...this.state.form.step2,
                                    rate: data.data.rate ? parseFloat(data.data.rate) : undefined,
                                    accompanying_person: data.data.accompanying_person,
                                    post_conference_activities_person: data.data.post_conference_activities_person,
                                },
                            },
                            checksum: checksum,
                            payment_status: data.data.payment_status || 0,
                        }, () => {
                            this.getDetail();
                        });
                    }).catch(err => {
                        Modal.error({
                            title: 'Error',
                            content: err.message || 'The details query failed.',
                            onOk: () => {
                                this.setState({
                                    step: 1,
                                });

                                this.props.history.replace('/registration/submission');
                            },
                        });
                    });
                } else {
                    this.updateTotalAmount();
                }
            }

            axios.request({
                url: '/registration/options',
                params: {
                    api_token: userToken,
                },
            }).then(rs => {
                const options = rs.data.data;

                this.setState({
                    config: {
                        ...this.state.config,
                        country_regions: options.country_regions || [],
                        phone_number_prefixes: options.phone_number_prefixes || [],
                    },
                });
            }).catch(err => {
                Modal.error({
                    title: 'Error',
                    content: err.message || 'The options query failed.',
                });
            });
        });
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (prevState.form.step2 !== this.state.form.step2) {
            this.updateTotalAmount();
        }
    }

    getDetail () {
        if (!this.state.id) {
            return;
        }

        axios.request({
            url: `/registration/submission/${this.state.id}`,
            params: {
                api_token: this.props.userToken,
            },
        }).then(rs => {
            const { data } = rs;

            if (data.code !== 200) {
                throw data;
            }

            const form = { ...this.state.form };
            const { order, detail } = data.data;

            for (const key in this.state.form) {
                for (const fKey in this.state.form[key]) {
                    if (fKey === 'rate') {
                        if (detail[fKey] != null) {
                            detail[fKey] = Number(detail[fKey]);
                        }
                    } else if (fKey === 'front_page_of_the_passport') {
                        if (detail[fKey]) {
                            this.setState({
                                front_page_of_the_passport_file_list: [
                                    {
                                        uid: detail[fKey],
                                        url: detail[fKey],
                                        name: detail[fKey],
                                    },
                                ],
                            });
                        }
                    } else if (detail[fKey] && [
                        'date_of_birth',
                        'check_in_date',
                        'check_out_date',
                        'date_of_check_out',
                        'issue_date_of_passport',
                        'expiry_date_of_passport',
                        'date_of_arrival_check_in',
                    ].includes(fKey)) {
                        detail[fKey] = moment(detail[fKey]);
                    }

                    form[key][fKey] = detail[fKey];
                }
            }

            if (order.payment_status === 1) {
                this.setState({
                    step: 4,
                });
            }

            this.setState({
                form,
                order: {
                    ...this.state.order,
                    ...order,
                },
            }, () => {
                this.updateTotalAmount();
            });
        }).catch(err => {
            Modal.error({
                title: 'Error',
                content: err.message || 'The detail query failed.',
            });
        });
    }

    updateTotalAmount = () => {
        const { step2 } = this.state.form;

        this.setState({
            total_amount: (step2.rate || 0) + 250 * (step2.accompanying_person || 0) + 70 * (step2.post_conference_activities_person || 0),
        });
    };

    checkLoginState = () => {
        let { userToken, history } = this.props;
        if (userToken) {
            return true;
        }

        history.push('/login');

        return false;
    };

    handleNext = async (step) => {
        if (!this.checkLoginState()) {
            console.warn('未登录');
            return;
        }

        if (this.state.step === step - 1) {
            if (step === 3) {
                const step2Form = this.state.step2FormRef.current;

                if (step2Form) {
                    try {
                        const result = await step2Form.validateFields();

                        this.setState({
                            form: {
                                ...this.state.form,
                                step2: {
                                    ...this.state.form.step2,
                                    ...result,
                                },
                            },
                        });
                    } catch (e) {
                        step2Form.scrollToField(e.errorFields[0].name[0]);
                        return;
                    }
                }
            } else if (step === 4) {
                const step3Form = this.state.step3FormRef.current;

                if (step3Form) {
                    try {
                        const result = await step3Form.validateFields();

                        this.setState({
                            form: {
                                ...this.state.form,
                                step3: {
                                    ...this.state.form.step3,
                                    ...result,
                                },
                            },
                        });
                    } catch (e) {
                        step3Form.scrollToField(e.errorFields[0].name[0]);
                        return;
                    }
                }
            } else if (step === 5) {
                const step4Form = this.state.step4FormRef.current;

                if (step4Form) {
                    try {
                        const result = await step4Form.validateFields();

                        const form = {
                            ...this.state.form,
                            step4: {
                                ...this.state.form.step4,
                                ...result,
                            },
                        };

                        if (form.step4.phone_number && !form.step4.phone_number_prefix) {
                            Modal.error({
                                title: 'Error',
                                content: 'Please select phone number prefix.',
                            });
                            return;
                        }

                        this.setState({
                            form: form,
                            loading: true,
                        });

                        const { history, userToken } = this.props;

                        let data = {
                            ...form.step2,
                            ...form.step3,
                            ...form.step4,
                        };

                        data['api_token'] = userToken;

                        for (const key in data) {
                            if (data[key] instanceof moment) {
                                data[key] = data[key].format('YYYY-MM-DD');
                            }
                        }

                        const formData = new FormData();
                        for (const key in data) {
                            if (key === 'front_page_of_the_passport') {
                                if (data[key] && data[key]['file']) {
                                    data[key] = data[key]['file'];
                                } else if (!this.state.id) {
                                    data[key] = undefined;
                                }
                            } else if (typeof data[key] === 'boolean') {
                                data[key] = Number(data[key]);
                            }

                            formData.append(key, data[key] == null ? '' : data[key]);
                        }

                        const options = {
                            url: '/registration/submission',
                            data: formData,
                            method: 'POST',
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        };

                        if (this.state.id) {
                            formData.append('_method', 'PUT');

                            options.url += `/${this.state.id}`;
                            options.data = formData;
                        }

                        axios.request(options).then(response => {
                            const { data } = response;

                            if (data.code !== 200) {
                                throw data;
                            }

                            const { id, checksum } = data.data;

                            if (this.state.order.payment_status === 1) {
                                Modal.success({
                                    title: 'Success',
                                    content: data.message || 'The update was successful.',
                                });
                                return;
                            }

                            this.setState({
                                id: id || this.state.id,
                                step: 5,
                                checksum,
                            });

                            if (!this.state.id) {
                                history.push(`/registration/submission?checksum=${checksum}`);
                            }
                        }).catch(err => {
                            Modal.error({
                                title: 'Error',
                                content: err.message || 'The options query failed.',
                            });
                        }).finally(() => {
                            this.setState({
                                loading: false,
                            });
                        });
                    } catch (e) {
                        if (e.errorFields && e.errorFields.length) {
                            step4Form.scrollToField(e.errorFields[0].name[0]);
                        }

                        return;
                    }
                }

                return;
            }
        }

        this.setState({
            step,
        });
    };

    handlePayment = () => {
        if (!this.state.id) {
            message.error('Please submit the form first.');
            return;
        }

        const { userToken } = this.props;

        this.setState({
            loading_payment: true,
        });

        axios.request({
            url: '/stripe-payment/generate',
            data: {
                id: this.state.id,
                api_token: userToken,
                cancel_url: `${window.location.origin}${window.location.pathname}#/registration/list`,
            },
            method: 'POST',
        }).then(({ data }) => {
            if (typeof data !== 'object') {
                return;
            }

            window.location.href = data.data.url;
        }).catch(err => {
            Modal.error({
                title: 'Error',
                content: err.message || 'request failure.',
            });
        }).finally(() => {
            this.setState({
                loading_payment: false,
            });
        });
    };

    handleBankTransferPayment (type) {
        if (!this.state.id) {
            message.error('Please submit the form first.');
            return;
        }

        const { history, userToken } = this.props;

        this.setState({
            loading: true,
        });

        axios.request({
            url: `/registration/submission/transfer-payment/${this.state.id}`,
            data: { type, api_token: userToken },
            method: 'POST',
        }).then(response => {
            const { data } = response;

            if (data.code !== 200) {
                throw data;
            }

            history.push('/registration/list');
        }).catch(err => {
            Modal.error({
                title: 'Error',
                content: err.message || 'The operation failed.',
            });
        }).finally(() => {
            this.setState({
                loading: false,
            });
        });
    }

    render () {
        const {
            step,
            form,
            order,
            config,
            loading,
            checksum,
            total_amount,
            step2FormRef,
            step3FormRef,
            step4FormRef,
            payment_status,
            loading_payment,
            front_page_of_the_passport_file_list,
        } = this.state;

        if (payment_status !== 0) {
            return 'Editing is not allowed in the current order status.';
        }

        const params = new URLSearchParams(this.props.location.search);

        return (
            <section className="registration-submission">
                <h1 className="title">
                    {step >= 5 ? (
                        <>Payment</>
                    ) : (
                        <>
                            Conference Registration Information
                        </>
                    )}
                </h1>

                <div className="container submission-info">
                    {step === 1 && (
                        <div className="items">
                            <div className="item">
                                <div className="title-text">
                                    Registration for 2024 IACR Scientific Conference & Chinese Cancer Registry
                                    Annual
                                    Conference opens on 18th July 2024.
                                </div>
                                <div className="content">
                                    <div className="line">Fees: Fees are displayed in US dollars</div>
                                    <div className="line">Your registration invoice currency will be in US Dollars.
                                        If
                                        you require an invoice
                                        in a different currency, kindly email us through service@bjreinforce.com
                                    </div>
                                    <div className="line">The host organization, IACR, is not registered for value
                                        added
                                        tax (VAT).
                                    </div>
                                    <div style={{ marginTop: 20 }}>
                                        <p style={{ margin: 0 }}>Link to IACR's fellowship application:</p>
                                        <p style={{ margin: 0 }}>
                                            <a
                                                href="https://datacollect.iarc.fr/redcap/surveys/?s=K9JWMR8PR88PHDT4"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >https://datacollect.iarc.fr/redcap/surveys/?s=K9JWMR8PR88PHDT4</a>
                                        </p>
                                    </div>

                                    <div style={{ marginTop: 20 }}>
                                        <p style={{ margin: 0 }}>Fellowship application guidelines:</p>
                                        <p style={{ margin: 0 }}>
                                            <a
                                                href="https://www.iacr.com.fr/images/doc/Fellowships/CP_Fellowship_guidelines_2012.pdf"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >https://www.iacr.com.fr/images/doc/Fellowships/CP_Fellowship_guidelines_2012.pdf</a>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="item">
                                <div className="title-text">
                                    Registration types:
                                </div>
                                <div className="content">
                                    <div className="line">Standard rate, valid from registration opening until 4th
                                        November 2024:
                                    </div>
                                    <div className="line"><span className="amount">650.00</span> US Dollars</div>
                                    <br></br>
                                    <div className="line">Local rate, valid from registration opening until 4th
                                        November
                                        2024:
                                    </div>
                                    <div className="line"><span className="amount">250.00</span> US Dollars</div>
                                    <br></br>
                                    <div className="line">Accompanying Person*</div>
                                    <div className="line">
                                        <p><span className="amount">250.00</span> US Dollars</p>
                                        <p className="help">*An Accompanying Person is a husband, wife, child, or
                                            partner who will be in Beijing, China with you. </p>
                                        <p className="help">*Accompanying person rate: This rate covers Welcome
                                            Reception, Coffee/ tea breaks and lunches on conference days,
                                            Conference Gala Dinner，access to the opening session / poster exhibits. </p>
                                    </div>
                                    <br></br>
                                    <div className="line">Post-conference activities * Group visit to <b>the Forbidden
                                        City + the Temple of Heaven</b> or <b>the Great Wall</b> on 7th November 2024
                                        (including transportation and entrance
                                        fees);
                                    </div>
                                    <div className="line"><span className="amount">70.00</span> US Dollars</div>
                                    <br></br>
                                    <div className="line">Only registered conference participants will be accredited
                                        to
                                        access the conference areas.
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="title-text">
                                    The Registration fee includes:
                                </div>
                                <div className="content">
                                    <div className="line">
                                        <p>Access to all conference program sessions, including the welcome
                                            reception, all conference sessions. Activities planned outside of the
                                            conference
                                            sessions are the following:</p>
                                        <ul>
                                            <li>
                                                <p>Pre-Conference workshops. There are two half-day pre-conference
                                                    workshops
                                                    to
                                                    be held on 4th November 2024 with limited slots. Reservation of
                                                    slots
                                                    will
                                                    be subject to the approval of the workshop working groups
                                                    (workshop
                                                    topics
                                                    and faculty TBC):</p>
                                                <ol>
                                                    <li>National estimation (Morning, 4th Nov 2024)</li>
                                                    <li>Cancer burden and lifetime risk (Afternoon, 4th Nov 2024)
                                                    </li>
                                                </ol>
                                            </li>
                                            <li>Welcome Reception 4th November 2024</li>
                                            <li>Conference gala dinner on 6th November 2024.</li>
                                        </ul>
                                    </div>
                                    <div className="line">Coffee / tea breaks and lunches on conference days (4-7
                                        November
                                        2024)
                                    </div>
                                    <div className="line">IACR 2024 posters exhibition</div>
                                    <div className="line">Conference documentation and collaterals (if given out)
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="title-text">
                                    The Registration fee DOES NOT include:
                                </div>
                                <div className="content">
                                    <div className="line">Accommodation expenses</div>
                                    <div className="line">Travel and visa expenses, airport fees and transfers</div>
                                    <div className="line">Travel insurance</div>
                                    <div className="line">Note: The conference sessions as well as the coffee / tea
                                        breaks and lunches on conference days and programmed social activities are
                                        only
                                        open to conference participants. Accompanying persons are requested to make
                                        their own arrangements. Conference hosts will provide links and contact
                                        details
                                        of travel and tourism providers.
                                    </div>
                                    <div className="line">Accommodation</div>
                                    <div className="line">The conference venue is the Beijing International Hotel {/* -
                                     Rooms can be booked through the following link: */}
                                    </div>
                                    <div className="line">
                                        <ul>
                                            <li>
                                                <p>Beijing International Hotel</p>
                                                <ol>
                                                    <li>Address: No.9 Jianguomennei Avenue, Dongcheng District, Beijing,
                                                        China
                                                    </li>
                                                    {/*  <li>Website: <a href="http://www.bjinternationalhotel.cn/"
                                                     target="_blank"
                                                     rel="noopener noreferrer">http://www.bjinternationalhotel.cn/</a>
                                                     </li>
                                                     <li>Double rooms from 90$ / Single at 80$</li>*/}
                                                </ol>
                                                <p>If you would like the organizing committee to help you book the
                                                    hotel,
                                                    please fill in the exact check-in and check-out dates on the
                                                    registration
                                                    page and choose the appropriate room type. You can pay the
                                                    accommodation
                                                    fee upon arrival at the hotel.</p>
                                                <p>Please note that the room you have selected will only be confirmed
                                                    once the payment for the order is completed.
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="line">For further enquiries and assistance (including requests
                                        for
                                        visa support certification / letters), please email the following:
                                    </div>
                                    <div className="line">IACR 2024 Conference Registration Secretariat:
                                        service@bjreinforce.com
                                    </div>
                                    <div className="line">IACR Secretariat: iacr@iarc.who.int</div>
                                    <div className="line">For assistance on the accommodations listed above: please
                                        contact the Tourism Secretariat through the email address available in the
                                        link/website.
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {step === 2 && (
                        <Form
                            ref={step2FormRef}
                            initialValues={form.step2}
                        >
                            <div className="items">
                                <div className="item">
                                    <div className="title-text">Registration Fees:</div>
                                    <div className="content">
                                        <FormItem
                                            name="rate"
                                            style={{ marginBottom: 0 }}
                                            rules={[
                                                {
                                                    message: (
                                                        <>
                                                            Please select Standard rate or Local rate. <br />
                                                            If you have already registered but wish to add an Accompanying Person or Group Visit, please send an email to service@bjreinforce.com. Please specify your current registration status and the additional services you need. We will provide you with individual assistance.
                                                        </>
                                                    ),
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Radio.Group
                                                onChange={e => {
                                                    this.setState({
                                                        form: {
                                                            ...form,
                                                            step2: {
                                                                ...form.step2,
                                                                rate: e.target.value,
                                                            },
                                                        },
                                                    }, () => {
                                                        this.updateTotalAmount();
                                                    });
                                                }}
                                            >
                                                <ul style={{ marginLeft: 0, marginBottom: 0 }}>
                                                    <li>
                                                        <Radio value={650}>
                                                            <span>Standard rate, valid from registration opening until 4th
                                                                November 2024:</span>
                                                            <ol style={{ marginLeft: 25, marginBottom: 0 }}>
                                                                <li style={{ listStyle: 'none' }}>
                                                                    <span className="amount">650.00</span> US
                                                                    Dollars
                                                                </li>
                                                            </ol>
                                                        </Radio>
                                                    </li>
                                                    <li>
                                                        <Radio value={250}>
                                                            <span>Local rate, valid from registration opening until 4th November 2024:</span>
                                                            <ol style={{ marginLeft: 25, marginBottom: 0 }}>
                                                                <li style={{ listStyle: 'none' }}>
                                                                    <span className="amount">250.00</span> US
                                                                    Dollars
                                                                </li>
                                                            </ol>
                                                        </Radio>
                                                    </li>
                                                </ul>
                                            </Radio.Group>
                                        </FormItem>
                                        <ul style={{ marginLeft: 10 }}>
                                            <li style={{ listStyle: 'none' }}>
                                                <p>Accompanying Person*</p>
                                                <ol style={{ marginLeft: 15 }}>
                                                    <li style={{ listStyle: 'none' }}>
                                                        <FormItem
                                                            name="accompanying_person"
                                                            label={(
                                                                <Space>
                                                                    <span className="amount">250.00</span>
                                                                    <span>US Dollars / Person</span>
                                                                </Space>
                                                            )}
                                                            style={{
                                                                marginBottom: 3,
                                                            }}
                                                        >
                                                            <InputNumber
                                                                min={0}
                                                                onChange={value => {
                                                                    this.setState({
                                                                        form: {
                                                                            ...form,
                                                                            step2: {
                                                                                ...form.step2,
                                                                                accompanying_person: value,
                                                                            },
                                                                        },
                                                                    });
                                                                }}
                                                            />
                                                        </FormItem>
                                                        <p className="help">*An Accompanying Person is a husband,
                                                            wife,
                                                            child, or partner who will be in Beijing, China with
                                                            you.</p>
                                                    </li>
                                                </ol>
                                            </li>
                                            <li style={{ listStyle: 'none' }}>
                                                <p>Post-conference activities * Group visit to the Forbidden
                                                    City + the Temple of Heaven or the Great Wall
                                                    on 7th November 2024 (including transportation and entrance
                                                    fees);</p>
                                                <ol style={{ marginLeft: 15 }}>
                                                    <li style={{ listStyle: 'none' }}>
                                                        <FormItem
                                                            name="post_conference_activities_person"
                                                            label={(
                                                                <Space>
                                                                    <span className="amount">70.00</span>
                                                                    <span>US Dollars / Person</span>
                                                                </Space>
                                                            )}
                                                        >
                                                            <InputNumber
                                                                min={0}
                                                                onChange={value => {
                                                                    this.setState({
                                                                        form: {
                                                                            ...form,
                                                                            step2: {
                                                                                ...form.step2,
                                                                                post_conference_activities_person: value,
                                                                            },
                                                                        },
                                                                    });
                                                                }}
                                                            />
                                                        </FormItem>
                                                    </li>
                                                    {form.step2.post_conference_activities_person > 0 && (
                                                        <li style={{ listStyle: 'none' }}>
                                                            <FormItem
                                                                name="post_conference_activities_attach"
                                                                label=""
                                                                rules={[
                                                                    {
                                                                        message: 'Please select.',
                                                                        required: true,
                                                                    },
                                                                ]}
                                                            >
                                                                <Radio.Group
                                                                    options={[
                                                                        {
                                                                            value: 1,
                                                                            label:
                                                                                <>
                                                                                    the Forbbiden City + the Temple of Heaven
                                                                                    <br />
                                                                                    Departure time: 1:00 PM, bus, back to hotel at 8:00 PM
                                                                                </>
                                                                        },
                                                                        {
                                                                            value: 2,
                                                                            label:
                                                                                <>
                                                                                    the Great Wall
                                                                                    <br />
                                                                                    Departure time: 1:00 PM, bus, back to hotel at 9:00 PM
                                                                                </>
                                                                        },
                                                                    ]}
                                                                    onChange={e => {
                                                                        this.setState({
                                                                            form: {
                                                                                ...form,
                                                                                step2: {
                                                                                    ...form.step2,
                                                                                    post_conference_activities_attach: e.target.value,
                                                                                },
                                                                            },
                                                                        });
                                                                    }}
                                                                />
                                                            </FormItem>
                                                        </li>
                                                    )}
                                                </ol>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="total-amount"
                                 style={{ marginTop: 20, textAlign: 'right', fontSize: 16 }}>
                                <span><b>Total Amount：</b></span>
                                <span>${(total_amount || 0).toFixed(2)}</span>
                            </div>
                        </Form>
                    )}

                    {step === 3 && (
                        <Form
                            ref={step3FormRef}
                            layout="vertical"
                            initialValues={form.step3}
                            scrollToFirstError
                        >
                            <FormItem
                                name="title"
                                label="Title"
                                rules={[
                                    {
                                        message: 'Please enter.',
                                        required: true,
                                    },
                                ]}
                            >
                                <Input placeholder="Please enter."/>
                            </FormItem>

                            <Row gutter={20}>
                                <Col span={12}>
                                    <FormItem
                                        name="first_name"
                                        label="First Name"
                                        rules={[
                                            {
                                                message: 'Please enter.',
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Please enter."/>
                                    </FormItem>
                                </Col>
                                <Col span={12}>
                                    <FormItem
                                        name="last_name"
                                        label="Last Name"
                                        rules={[
                                            {
                                                message: 'Please enter.',
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Please enter."/>
                                    </FormItem>
                                </Col>
                            </Row>

                            <FormItem
                                name="organization"
                                label="Organization"
                                rules={[
                                    {
                                        message: 'Please enter.',
                                        required: true,
                                    },
                                ]}
                            >
                                <Input placeholder="Please enter."/>
                            </FormItem>

                            <FormItem
                                name="position"
                                label="Position"
                                rules={[
                                    {
                                        message: 'Please enter.',
                                        required: true,
                                    },
                                ]}
                            >
                                <Input placeholder="Please enter."/>
                            </FormItem>

                            <FormItem
                                name="primary_address_country"
                                label="Primary Address - Country"
                                rules={[
                                    {
                                        message: 'Please select.',
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    options={(config.country_regions || []).map(v => ({
                                        value: v,
                                        label: v,
                                    }))}
                                    showSearch
                                    placeholder="Please selecte."
                                />
                            </FormItem>

                            <FormItem
                                name="primary_email"
                                label="Primary Email"
                                rules={[
                                    {
                                        message: 'Please enter.',
                                        required: true,
                                    },
                                ]}
                            >
                                <Input placeholder="Please enter."/>
                            </FormItem>

                            <FormItem
                                name="morning_workshop"
                                label="Morning Workshop"
                                rules={[
                                    {
                                        message: 'Please select.',
                                        required: true,
                                    },
                                ]}
                            >
                                <Radio.Group
                                    options={[
                                        {
                                            label: 'Yes, I would like to attend a morning pre-conference workshop',
                                            value: true,
                                        },
                                        {
                                            label: 'No',
                                            value: false,
                                        },
                                    ]}
                                />
                            </FormItem>

                            <FormItem
                                name="afternoon_workshop"
                                label="Afternoon Workshops"
                                rules={[
                                    {
                                        message: 'Please select.',
                                        required: true,
                                    },
                                ]}
                            >
                                <Radio.Group
                                    options={[
                                        {
                                            label: 'Yes, I would like to attend an afternoon pre-conference workshop',
                                            value: true,
                                        },
                                        {
                                            label: 'No',
                                            value: false,
                                        },
                                    ]}
                                />
                            </FormItem>

                            <FormItem
                                name="welcome_reception"
                                label="Welcome Reception"
                                rules={[
                                    {
                                        message: 'Please select.',
                                        required: true,
                                    },
                                ]}
                            >
                                <Radio.Group
                                    options={[
                                        {
                                            label: 'Yes, I would like to attend the Welcome Reception',
                                            value: true,
                                        },
                                        {
                                            label: 'No',
                                            value: false,
                                        },
                                    ]}
                                />
                            </FormItem>

                            <FormItem
                                name="gala_dinner"
                                label="Gala Dinner"
                                rules={[
                                    {
                                        message: 'Please select.',
                                        required: true,
                                    },
                                ]}
                            >
                                <Radio.Group
                                    options={[
                                        {
                                            label: 'Yes, I would like to attend the Gala Dinner',
                                            value: true,
                                        },
                                        {
                                            label: 'No',
                                            value: false,
                                        },
                                    ]}
                                />
                            </FormItem>

                            <FormItem
                                name="dietary_requirements"
                                label="Dietary Requirements"
                            >
                                <Checkbox.Group
                                    options={[
                                        {
                                            label: 'Vegetarian',
                                            value: 'Vegetarian',
                                        },
                                    ]}
                                />
                            </FormItem>

                            <FormItem
                                name="other_dietary_requirements_allergies"
                                label="Other Dietary Requirements / Allergies"
                            >
                                <Input placeholder="Please enter."/>
                            </FormItem>

                            <FormItem
                                name="special_assistance_requirements"
                                label="Special Assistance Requirements"
                            >
                                <Input placeholder="Please enter."/>
                            </FormItem>
                        </Form>
                    )}

                    {step === 4 && (
                        <Form
                            ref={step4FormRef}
                            layout="vertical"
                            initialValues={form.step4}
                            scrollToFirstError
                        >
                            <FormItem
                                name="visa_required"
                                label="VISA REQUIRED"
                                rules={[
                                    {
                                        message: 'Please select.',
                                        required: true,
                                    },
                                ]}
                                extra="Invitation letter requires the name, title, position, workplace, contact information (email and phone number) of the invitee, as well as personal information on the front page of the passport, including the name, gender, nationality, date of birth, passport number,  date of expiry, etc."
                            >
                                <Radio.Group
                                    options={[
                                        {
                                            label: 'Yes, I will need a visa',
                                            value: true,
                                        },
                                        {
                                            label: 'No',
                                            value: false,
                                        },
                                    ]}
                                    onChange={(e) => {
                                        this.setState({
                                            form: {
                                                ...form,
                                                step4: {
                                                    ...form.step4,
                                                    visa_required: e.target.value,
                                                },
                                            },
                                        });
                                    }}
                                />
                            </FormItem>

                            <FormItem
                                name="full_name_as_on_passport"
                                label="FULL NAME (AS ON PASSPORT)"
                                rules={[
                                    {
                                        message: 'Please enter.',
                                        required: true,
                                    },
                                ]}
                            >
                                <Input placeholder="Please enter."/>
                            </FormItem>

                            <FormItem
                                name="gender"
                                label="GENDER (same as shown on the passport)"
                                rules={[
                                    {
                                        message: 'Please enter.',
                                        required: true,
                                    },
                                ]}
                            >
                                <Input placeholder="Please entry."/>
                            </FormItem>

                            <FormItem
                                name="nationality"
                                label="NATIONALITY (ISSUING PASSPORT)"
                                rules={[
                                    {
                                        message: 'Please select.',
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    options={(config.country_regions || []).map(v => ({
                                        value: v,
                                        label: v,
                                    }))}
                                    showSearch
                                    placeholder="Please selecte."
                                />
                            </FormItem>

                            <FormItem
                                name="for_visa_support_letter"
                                label="AFFILIATION"
                            >
                                <Input placeholder="Please enter."/>
                            </FormItem>

                            {form.step4.visa_required && (
                                <>
                                    <FormItem
                                        name="date_of_birth"
                                        label="DATE OF BIRTH"
                                        rules={[
                                            {
                                                message: 'Please select.',
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <DatePicker placeholder="Please select."/>
                                    </FormItem>

                                    <FormItem
                                        name="issue_date_of_passport"
                                        label="ISSUE DATE OF PASSPORT"
                                        rules={[
                                            {
                                                message: 'Please select.',
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <DatePicker placeholder="Please select."/>
                                    </FormItem>

                                    <FormItem
                                        name="expiry_date_of_passport"
                                        label="EXPIRY DATE OF PASSPORT"
                                        rules={[
                                            {
                                                message: 'Please select.',
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <DatePicker placeholder="Please select."/>
                                    </FormItem>

                                    <FormItem
                                        name="passport_number"
                                        label="PASSPORT NUMBER"
                                        rules={[
                                            {
                                                message: 'Please enter.',
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Please enter."/>
                                    </FormItem>
                                    <FormItem
                                        name="phone_number"
                                        label="PHONE NUMBER"
                                        rules={[
                                            {
                                                message: 'Please enter.',
                                                required: true,
                                            },
                                        ]}
                                        className="form-item-phone-number"
                                    >
                                        <Input
                                            placeholder="Please enter."
                                            addonBefore={(
                                                <Select
                                                    style={{ width: 60 }}
                                                    value={form.step4.phone_number_prefix}
                                                    options={(config.phone_number_prefixes || []).map(v => ({
                                                        value: v,
                                                        label: `+${v}`,
                                                    }))}
                                                    showSearch
                                                    onChange={value => {
                                                        this.setState({
                                                            form: {
                                                                ...form,
                                                                step4: {
                                                                    ...form.step4,
                                                                    phone_number_prefix: value,
                                                                },
                                                            },
                                                        });
                                                    }}
                                                />
                                            )}
                                        />
                                    </FormItem>

                                    <FormItem
                                        name="front_page_of_the_passport"
                                        label="UPLOAD FRONT PAGE OF THE PASSPORT"
                                        rules={[
                                            {
                                                message: 'Please upload.',
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Upload
                                            accept=".jpg,.png,.pdf"
                                            fileList={front_page_of_the_passport_file_list}
                                            beforeUpload={file => {
                                                this.setState({
                                                    front_page_of_the_passport_file_list: [file],
                                                });

                                                return false;
                                            }}
                                        >
                                            <Button icon={<UploadOutlined/>}>Click to Upload</Button>
                                        </Upload>
                                    </FormItem>

                                    <FormItem
                                        name="other_info_required_by_chinese_embassy_for_visa_support"
                                        label="OTHER INFO REQUIRED BY CHINESE EMBASSY FOR VISA SUPPORT"
                                    >
                                        <Input placeholder="Please enter."/>
                                    </FormItem>
                                </>
                            )}

                            {/* <FormItem
                             name="accommodation_reservation"
                             label="ACCOMMODATION RESERVATION"
                             rules={[
                             {
                             message: 'Please select.',
                             required: true,
                             },
                             ]}
                             >
                             <Radio.Group
                             options={[
                             {
                             label: 'Yes',
                             value: true,
                             },
                             {
                             label: 'No',
                             value: false,
                             },
                             ]}
                             onChange={(e) => {
                             this.setState({
                             form: {
                             ...form,
                             step4: {
                             ...form.step4,
                             accommodation_reservation: e.target.value,
                             },
                             },
                             });
                             }}
                             />
                             </FormItem>

                             {form.step4.accommodation_reservation && (
                             <>
                             <FormItem
                             name="date_of_arrival_check_in"
                             label="DATE OF ARRIVAL / CHECK-IN"
                             rules={[
                             {
                             message: 'Please select.',
                             required: true,
                             },
                             ]}
                             >
                             <DatePicker placeholder="Please select."/>
                             </FormItem>

                             <FormItem
                             name="date_of_check_out"
                             label="DATE OF CHECK-OUT"
                             rules={[
                             {
                             message: 'Please select.',
                             required: true,
                             },
                             ]}
                             >
                             <DatePicker placeholder="Please select."/>
                             </FormItem>

                             <FormItem
                             name="other_room_request"
                             label="OTHER ROOM REQUEST"
                             >
                             <Input placeholder="Please enter."/>
                             </FormItem>
                             </>
                             )} */}

                            <FormItem
                                name="hotel_book_required"
                                style={{ paddingTop: 40 }}
                                label="HOTEL BOOK REQUIRED"
                                rules={[
                                    {
                                        message: 'Please select.',
                                        required: true,
                                    },
                                ]}
                                extra={
                                    <>
                                        <div>If you would like the organizing committee to help you book the hotel, please fill in the exact check-in and check-out dates on the registration page and choose the appropriate room type. You can pay the accommodation fee upon arrival at the hotel.</div>
                                        <div style={{ color: 'red' }}>
                                            Please note that the room you have selected will only be confirmed once the payment for the order is completed.
                                        </div>
                                    </>
                                }
                            >
                                <Radio.Group
                                    options={[
                                        {
                                            value: 1,
                                            label: 'Yes',
                                        },
                                        {
                                            value: 0,
                                            label: 'No',
                                        },
                                    ]}
                                    onChange={(e) => {
                                        this.setState({
                                            form: {
                                                ...form,
                                                step4: {
                                                    ...form.step4,
                                                    hotel_book_required: e.target.value,
                                                },
                                            },
                                        });
                                    }}
                                />
                            </FormItem>

                            {form.step4.hotel_book_required === 1 && (
                                <>
                                    <FormItem
                                        name="check_in_date"
                                        label="Check in date"
                                        rules={[
                                            {
                                                message: 'Please select.',
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <DatePicker/>
                                    </FormItem>

                                    <FormItem
                                        name="check_out_date"
                                        label="Check out date"
                                        rules={[
                                            {
                                                message: 'Please select.',
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <DatePicker/>
                                    </FormItem>

                                    <FormItem
                                        name="superior_double_room"
                                        label={
                                            <>
                                                The number of <strong>&nbsp;Superior Double Room (28 m²)
                                                CNY880&nbsp;</strong> to be reserved
                                            </>
                                        }
                                    >
                                        <InputNumber
                                            min={0}
                                            style={{ width: '7%' }}
                                        />
                                    </FormItem>

                                    <FormItem
                                        name="superior_twin_room"
                                        label={
                                            <>
                                                The number of <strong>&nbsp;Superior Twin Room (28 m²)
                                                CNY880&nbsp;</strong> to be reserved
                                            </>
                                        }
                                    >
                                        <InputNumber
                                            min={0}
                                            style={{ width: '7%' }}
                                        />
                                    </FormItem>

                                    <FormItem
                                        name="ancient_double_room"
                                        label={
                                            <>
                                                The number of <strong>&nbsp;Ancient Double Room (28 m²)
                                                CNY980&nbsp;</strong> to be reserved
                                            </>
                                        }
                                    >
                                        <InputNumber
                                            min={0}
                                            style={{ width: '7%' }}
                                        />
                                    </FormItem>

                                    <FormItem
                                        name="ancient_twin_room"
                                        label={
                                            <>
                                                The number of <strong>&nbsp;Ancient Twin Room (28 m²)
                                                CNY980&nbsp;</strong> to be reserved
                                            </>
                                        }
                                    >
                                        <InputNumber
                                            min={0}
                                            style={{ width: '7%' }}
                                        />
                                    </FormItem>

                                    <FormItem
                                        name="superior_deluxe_room"
                                        label={
                                            <>
                                                The number of <strong>&nbsp;Superior Deluxe Room (54 m²)
                                                CNY1180&nbsp;</strong> to be reserved
                                            </>
                                        }
                                    >
                                        <InputNumber
                                            min={0}
                                            style={{ width: '7%' }}
                                        />
                                    </FormItem>

                                    <FormItem
                                        name="superior_suite_room"
                                        label={
                                            <>
                                                The number of <strong>&nbsp;Superior Suite Room (62 m²)
                                                CNY1480&nbsp;</strong> to be reserved
                                            </>
                                        }
                                    >
                                        <InputNumber
                                            min={0}
                                            style={{ width: '7%' }}
                                        />
                                    </FormItem>

                                    <FormItem
                                        name="executive_deluxe_room"
                                        label={
                                            <>
                                                The number of <strong>&nbsp;Executive Deluxe Room (54 m²)
                                                CNY1380&nbsp;</strong> to be reserved
                                            </>
                                        }
                                    >
                                        <InputNumber
                                            min={0}
                                            style={{ width: '7%' }}
                                        />
                                    </FormItem>

                                    <FormItem
                                        name="executive_suite_room"
                                        label={
                                            <>
                                                The number of <strong>&nbsp;Executive Suite Room (62 m²)
                                                CNY1980&nbsp;</strong> to be reserved
                                            </>
                                        }
                                    >
                                        <InputNumber
                                            min={0}
                                            style={{ width: '7%' }}
                                        />
                                    </FormItem>

                                </>
                            )}
                        </Form>
                    )}

                    {step === 5 && (
                        <div style={{ paddingBottom: 220 }}>
                            <Space>
                                <span><b>Total Amount：</b></span>
                                <span>${(total_amount || 0).toFixed(2)}</span>
                            </Space>

                            <div style={{ display: 'flex', columnGap: 40, padding: '60px 0' }}>
                                <div>
                                    <Button
                                        type="link"
                                        size="large"
                                        icon={<LinkOutlined/>}
                                        onClick={this.handleNext.bind(this, 6)}
                                        className="payment-link-button"
                                    >Bank Transfer Payment</Button>
                                </div>
                                <div>
                                    <Button
                                        type="link"
                                        size="large"
                                        icon={<LinkOutlined/>}
                                        loading={loading_payment}
                                        onClick={this.handlePayment.bind(this)}
                                        className="payment-link-button"
                                    >Online Payment</Button>
                                </div>
                            </div>

                            <div className="items">
                                <div className="item">
                                    <div className="title-text">With regard to cancellations and refunds, we followed
                                        standard policies of academic conferences.
                                    </div>
                                    <div className="content">
                                        <div className="line">
                                            <p>Cancellation charges：</p>
                                            <ul className="list-decimal">
                                                <li>Before October 1, a refund fee of $50 will be charged per order</li>
                                                <li>Before October 15, a refund fee of 50% of the order amount will be
                                                    charged per order
                                                </li>
                                                <li>After October 15, No refund</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {step === 6 && (
                        <>
                            <Space size={20}>
                                <div>
                                    <span><b>Total Amount：</b></span>
                                    <span>${(total_amount || 0).toFixed(2)}</span>
                                </div>
                                <div>
                                    <span><b>Verification Code：</b></span>
                                    <span>{checksum}</span>
                                </div>
                            </Space>

                            <div className="items" style={{ marginTop: 20 }}>
                                <div className="item">
                                    <div className="title-text">Bank Transfer Payment Instructions:</div>
                                    <div className="content">
                                        <div className="line">
                                            <p>To complete your payment via bank transfer, please follow the steps
                                                below:</p>
                                            <ul className="list-decimal">
                                                <li>
                                                    <p>Transfer the payment to the following bank account:</p>
                                                    <div className="content">
                                                        <div className="line"><b>Account Name:</b> Dynalight
                                                            International
                                                            Ltd
                                                        </div>
                                                        <div className="line"><b>Bank Name:</b> The Hongkong and
                                                            Shanghai
                                                            Banking Corporation Limited (HSBC)
                                                        </div>
                                                        <div className="line"><b>Bank address:</b> HSBC Headquarter，
                                                            1 Queen’s Road Central，Central，Hong Kong
                                                        </div>
                                                        <div className="line"><b>Account Number:</b> 400 180725 838
                                                        </div>
                                                        <div className="line"><b>SWIFT Code:</b> HSBCHKHHHKH</div>
                                                        <div className="line"><b    >Bank Code:</b> 004</div>
                                                    </div>
                                                </li>
                                                <li>Reference your order number: Please include your Verification
                                                    Code
                                                    number
                                                    <b style={{
                                                        fontSize: 16,
                                                        marginLeft: 5,
                                                    }}>[{checksum || '--'}]</b> in
                                                    the payment reference/remarks section to ensure
                                                    your payment is correctly allocated.
                                                </li>
                                                <li>Confirmation: Once the transfer is complete, please send a copy
                                                    of
                                                    the
                                                    payment confirmation to [Your Email Address] for verification.
                                                </li>
                                                <li>Processing Time: Please allow 3-5 business days for the payment
                                                    to
                                                    be
                                                    processed. You will receive a confirmation email once your
                                                    payment
                                                    has
                                                    been verified and your order is being processed.
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="line">&nbsp;</div>
                                        <div className="line">If you have any questions or need further assistance,
                                            please
                                            contact our support team at service@bjreinforce.com.
                                        </div>
                                        <div className="line">&nbsp;</div>
                                        <div className="line">Thank you for your purchase!</div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    {step <= 6 && (
                        <div className="submit-button">
                            {step === 6 ? (
                                <>
                                    <Button
                                        type="primary"
                                        size="large"
                                        loading={loading}
                                        className="btn"
                                        onClick={this.handleBankTransferPayment.bind(this, 'later')}
                                    >Pay later</Button>

                                    <Button
                                        type="primary"
                                        size="large"
                                        loading={loading}
                                        className="btn"
                                        onClick={this.handleBankTransferPayment.bind(this, 'done')}
                                    >Done</Button>
                                </>
                            ) : (
                                <>
                                    {order.payment_status !== 1 && !params.has('checksum') && step > 1 && (
                                        <Button
                                            type="primary"
                                            size="large"
                                            loading={loading}
                                            disabled={loading}
                                            className="btn"
                                            onClick={this.handleNext.bind(this, step - 1)}
                                        >
                                            <ArrowLeftOutlined/> Previous
                                        </Button>
                                    )}

                                    {step < 5 && (
                                        <Button
                                            type="primary"
                                            size="large"
                                            loading={loading}
                                            className="btn"
                                            onClick={this.handleNext.bind(this, step + 1)}
                                        >
                                            {order.payment_status === 1 ? (
                                                <>
                                                    Submit
                                                </>
                                            ) : (
                                                <>
                                                    Next <ArrowRightOutlined/>
                                                </>
                                            )}
                                        </Button>
                                    )}

                                    {step === 5 && (
                                        <Button
                                            type="primary"
                                            size="large"
                                            loading={loading}
                                            className="btn"
                                            onClick={this.handleBankTransferPayment.bind(this, 'later')}
                                        >
                                            Pay Later
                                        </Button>
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </div>
            </section>
        );
    }
}

const mapStateToProps = state => {
    return {
        userInfo: state.userInfo,
        userToken: state.userToken,
    };
};

export default connect(mapStateToProps)(RegistrationSubmission);
