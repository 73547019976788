import React from 'react';
import './index.less'
import {Row, Col, Card} from 'antd';
import sourceData from './data'

export default class Congress extends React.Component {
  render() {
    const {listData, title} = sourceData
    return (
      <div className="committees container">
        <h3 className="title">{title}</h3>
        {listData.map((item, index) => {
          return <section key={index} className="content">
            <Row>
              <Col span={18} xs={24} sm={24} md={{span: 14, offset: 4}}>
                <h3 className="subject-title">{item.title}</h3>
              </Col>
              <Col span={18} xs={24} sm={24} md={{span: 14, offset: 8}}>
                <Card
                  className="full-width"
                  bordered={false}
                  cover={<img style={{height: "3.35rem"}} alt="example" src={item.cover}/>}>
                  <div dangerouslySetInnerHTML={{__html: item.content}}></div>
                </Card>
              </Col>
            </Row>
          </section>
        })}
      </div>
    )
  }
}