const data =[
    {
        year:"2017",
        date:"(Sept. 2-5)",
        title:"14th World Conference Geneva",
        subTitle:"Global perspectives in Esophageal diseases",
        hasView:true,
        key: 1,
        url:'http://www.oeso.org/geneva_conference2017'
    },
    {
        year:"2015",
        date:"(Aug. 31-Sept. 3)",
        title:"13th World Conference Monaco",
        subTitle:"The Esophagiome",
        detail:"An all inclusive journey through the esophagus machine and upper digestive tract, an exploration into the depth of knowledge…",
        hasView:true,
        key: 2,
        url:'http://www.oeso.org/monaco_conference2015'
    },{
        year:"2013",
        date:"(Aug. 27-30)",
        title:"12th World Conference Paris/UNESCO",
        subTitle:"Global perspectives in Esophageal diseases",
        detail:"",
        hasView:true,
        key: 3,
        url:'http://www.oeso.org/assets/12th-world-conference-parisunesco.pdf'
    },{
        year:"2012",
        date:"(Sept.1-4)",
        title:"11th World Conference Como",
        subTitle:"Reflux disease, from LES to UES, and beyond...",
        detail:"",
        hasView:true,
        key: 4,
        url:'http://www.oeso.org/pastcongresses/oesoweb2012/agenzia-pubblicita-italia.com/oeso/index7afc.html?option=com_content&view=article&id=46&Itemid=28'
    },{
        year:"2010",
        date:"(Aug. 28-31)",
        title:"10th World Congress Boston",
        subTitle:"Barrett's esophagus",
        detail:"A multi-disciplinary approach to unmet clinical needs in esophageal disease",
        hasView:true,
        key: "",
        url:"http://www.oeso.org/pastcongresses/boston-oeso2010.html"
    },{
        year:"2008",
        date:"(Apr. 6-9)",
        title:"9th World Congress Monaco",
        subTitle:"Gastro-esophageal reflux disease",
        detail:"From reflux to inflammation – to adenocarcinoma",
        hasView:true,
        key: "",
        url:"http://www.oeso.org/pastcongresses/boston-oeso.html"
    },{
        year:"2006",
        date:"(Sept. 3-6)",
        title:"8th World Congress Avignon",
        subTitle:"The Foregut: function-dysfunction",
        detail:"",
        hasView:true,
        key:""
    },{
        year:"2003",
        date:"(Aug. 31-Sept. 4)",
        title:"7th World Congress Paris",
        subTitle:"The Duodeno-Gastro-Esophageal reflux",
        detail:"",
        hasView:false,
        key:""
    },{
        year:"2000",
        date:"(Sept. 1-6)",
        title:"6th World Congress Paris/UNESCO",
        subTitle:"Barrett 2000 – Transition to Telemedicine",
        detail:"",
        hasView:false,
        key:""
    },{
        year:"1996",
        date:"(Sept. 3-7)",
        title:"5th World Congress Paris/UNESCO",
        subTitle:"The Esophago-Gastric Junction",
        detail:"",
        hasView:false,
        key:""
    },{
        year:"1993",
        date:"(Sept. 1-4)",
        title:"4th World Congress Paris/UNESCO",
        subTitle:"The esophageal mucosa",
        detail:"",
        hasView:false,
        key:""
    },{
        year:"1990",
        date:"(Jun. 19-23)",
        title:"3rd World Congress Paris",
        subTitle:"Primary esophageal motility disorders",
        detail:"",
        hasView:false,
        key:""
    },{
        year:"1987",
        date:"(Jun. 24-27)",
        title:"2nd World Congress Paris",
        subTitle:"Benign lesions of the esophagus and cancer",
        detail:"",
        hasView:false,
        key:""
    },{
        year:"1984",
        date:"(May. 17-19)",
        title:"1st World Congress Paris",
        subTitle:"Cancers of the esophagus in 1984 World Congresses",
        detail:"",
        hasView:false,
        key:""
    }
]
export default data;