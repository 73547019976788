import React from 'react'
import {HashRouter, Route, Switch, Redirect} from 'react-router-dom'
import App from './App'
import NoMatch from './pages/nomatch'
import Home from './pages/home';

import {connect} from 'react-redux'
import Routers from './routerMap'

class ORouter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: this.props.userInfo ? this.props.userInfo.status : null
        }
    }

    renderRoute = (item) => {
        let token = this.props.userToken;

        return (
          <Route
              path={item.path}
              key={item.path}
              render={props => {
                  return !item.auth
                      ? <item.component {...props} />
                      : token
                          ? <item.component {...props} />
                          : <Redirect to={{pathname: '/login', state: {from: props.location}}}/>;
              }}/>
        )
    };

    render() {
        return (
          <HashRouter>
              <App>
                  <Switch>
                      {Routers.map((items, index) => {
                          if (items.children && items.children.length) {
                              return (
                                  <Route key={items.path} path={items.path} render={(props) => (
                                      <items.component {...props}>
                                          <Switch>
                                              {items.children.map((item, key) => {
                                                  return this.renderRoute(item)
                                              })}
                                              <Route path="/" exact component={Home}/>
                                              <Route component={NoMatch}/>
                                          </Switch>
                                      </items.component>
                                  )}/>
                              )
                          }

                          return this.renderRoute(items)
                      })}
                  </Switch>
              </App>
          </HashRouter>
        );
    }
}

const mapStateToProps = state => {
    return {
        userInfo: state.userInfo,
        userToken: state.userToken
    }
};

export default connect(mapStateToProps)(ORouter)
