import React, {Component} from 'react';
import {connect} from 'react-redux'
import axios from '../../axios/index';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import LoginFrom                         from '../../components/Login'
import {Row, Col, Card, notification,}   from 'antd';
import { userInfo, resendActivationCode} from '../../redux/action';
import './index.less';
import logo from '../../resource/images/logo.png';
import {contactEmail} from '../../config';

class Login extends Component {
    state = {
        logo: logo,
        loading: false,
        hideMenu: true,
        isMobile: true,
        collapsed: false,
        currentUser: {
            name:'',
            avatar: 'https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png'
        }
    };

    // 重发邮件
    sendEmail = (props) => {
        const {dispatch} = this.props;

        dispatch(resendActivationCode(props))
    };

    // 用户登录
    loginReq = (params) => {
        this.setState({
            loading: true,
        });

        axios.ajax({
            url: '/login',
            method: 'post',
            data: {
                params
            }
        }).then(res => {
            if (res.code === 200) {
                const {dispatch} = this.props;

                dispatch(userInfo(res.data.api_token, 2, res.data.status, res.data.pay_status));

                return;
            }

            notification.error({
                message: 'System Tips',
                description: res.message || 'Login failed',
            });
        }).finally(() => {
            this.setState({
                loading: false,
            });
        });
    };

    render() {
        return (
            <div className="login">
                <Header {...this.state} />
                <div className="container login-box">
                    <div className="fill-remaining-space "></div>
                    <div className="login-content">
                        <Row gutter={24} type="flex" align="middle">
                            <Col xs={24} sm={24} md={{span: 20, offset: 4}}>
                                <Card bordered={false}>
                                    <h3 className="subject-title">Please Log in</h3>
                                </Card>
                            </Col>

                            <Col span={12} xs={24} sm={24} md={{span: 10, offset: 4}}>
                                <LoginFrom
                                    ref="login"
                                    loading={this.state.loading}
                                    loginSubmit={this.loginReq}
                                    sendEmailHandle={this.sendEmail}
                                />
                            </Col>

                            <Col span={12} xs={24} sm={24} md={{span: 8, offset: 2}}>
                                <Card bordered={false}>
                                    <h4 className="md-title">PRESENTERS (ORAL AND POSTERS): FOR INQUIRIES, PLEASE CONTACT</h4>
                                    <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <div className="fill-remaining-space"/>
                </div>
                <Footer {...this.state} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        userInfo: state.userInfo,
        userToken: state.userToken
    }
};

export default connect(mapStateToProps)(Login)
