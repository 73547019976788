import React from 'react';
import {Row,Col} from 'antd';
import './index.less';
import MessageItem from '../home/componets/MessageItem';
import sourceData from '../home/data'
export default class Welcome extends React.Component{
    
    render(h) {
        const {messages}= sourceData;
        return(<div className="container welcome-page">
           <h1 className="title">WELCOME MESSAGE</h1>
           <Row gutter={24} type="flex">
                {
                  messages.map((item,index) => { 
                    return <Col key={index} span={12} xs={24} sm={24} md={12} style={{marginBottom:"0.3rem"}}> <MessageItem item={item}></MessageItem></Col>
                  })
                }
              </Row>
        </div>)
        
    }
}