import React, {Component} from 'react';
import {Row, Col, Card}   from 'antd';
import {NavLink}          from 'react-router-dom';
import {contactEmail} from '../../../config';

export default class Wait extends Component {
    render() {
        return (
            <div className="container" style={{padding: '160px 0'}}>
                <Row style={{margin: '0.8rem 0'}}>
                    <Col xs={24} sm={24} md={{span: 20, offset: 4}}>
                        <h3 className="subject-title">Are you having trouble signing in?</h3>
                    </Col>
                    <Col xs={24} sm={24} md={{span: 20, offset: 4}}>
                        <Card bordered={false}>
                            <div style={{marginBottom: '0.3rem'}}>
                                <p>The message has been successfully sent to your mailbox.</p>
                                <p>Please log in to your email to check your email and reset your password.</p>
                                <NavLink to="/home">Back to home</NavLink>
                            </div>
                            <h5 className="title-absolute">
                                <div className="title-text">Message not received</div>
                            </h5>
                            <p>If you do not receive the email within 2-3 minutes, we recommend that you check the
                                "trash
                                can" or retrieve it.</p>
                            <p>In case of any problems, please contact <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
                            </p>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}
