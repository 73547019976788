import React, {Component} from 'react';
import './index.less';
import ListData from './data'
import {List, Row, Col} from 'antd';

export default class Past extends Component {

    hasLink = (url) => {
        if (url) {
            return ([<a href={url}>Click to view</a>])
        }
    };

    render() {
        return (
          <div className="past-page ">
              <h3 className="title">PAST CONGRESS</h3>
              <div className="ad container">
                  <img alt="图片" className="full-width"
                       src={require('../../../resource/images/pastcongress_banner.png')}/>
              </div>
              <List
                className="demo-loadmore-list "
                itemLayout="vertical"
                dataSource={ListData}
                renderItem={item => (
                  <Row>
                      <Col className="container">
                          <List.Item extra={
                              <div className="extra-content">
                                  <h3 className="item-title">{item.title}</h3>
                                  <div>{item.subTitle}</div>
                                  <div title={item.detail}>{item.detail}</div>
                                  <a href={item.url}>→ Click to view</a>
                              </div>}>
                              <List.Item.Meta
                                title={<p className="item-year">{item.year}</p>}
                                description={<p>{item.date}</p>}
                              />
                          </List.Item>
                      </Col>
                  </Row>
                )}
              />

          </div>
        )
    }
}