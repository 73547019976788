// 引入createStore创建store，引入applyMiddleware 来使用中间件
import {createStore, applyMiddleware} from 'redux';

import thunk from 'redux-thunk'
// 安装redux-devtools-extension的可视化工具。
import {composeWithDevTools} from 'redux-devtools-extension';
// 持久化存储
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet' // 协调器
// 引入所有的reducer
import reducer from './../reducer';


const persistConfig = {
    key: 'root',
    storage: storage,
    stateReconciler: hardSet,
    // stateReconciler: autoMergeLevel2 // 查看 'Merge Process' 部分的具体情况
};
const myPersistReducer = persistReducer(persistConfig, reducer);

function configureStore() {
    let store = createStore(myPersistReducer, composeWithDevTools(applyMiddleware(thunk)));
    let persistor = persistStore(store);
    return {persistor, store}
}

export default configureStore;
