import React from 'react';
import {Row, Col, Card} from 'antd';
import sourceData from './data'
import "./index.less";

export default class Venue extends React.Component {

    // 渲染数据项
    renderListData = (item, index) => {
        if (index % 2 === 0) {
            return <Row key={index} gutter={24} type="flex" justify={"center"} align={"bottom"}>
                <Col span={12} xs={24} sm={24} md={12}>
                    <Card bordered={false}>
                        <div className="line"></div>
                        <div dangerouslySetInnerHTML={{__html: item.content}}></div>
                    </Card>
                </Col>
                <Col span={12} xs={24} sm={24} md={12}>
                    <Card bordered={false} className="cover"
                          cover={<img alt="图片" style={{width: "5.16rem", height: "3.16rem", padding: "0.2rem"}}
                                      className="card-item_cover" src={item.cover}/>}>
                    </Card>
                </Col>
            </Row>
        }
        return <Row key={index} gutter={24} type="flex" justify={"center"} align={"bottom"}>
            <Col span={12} xs={24} sm={24} md={12}>
                <Card bordered={false} className="cover"
                      cover={<img alt="图片" style={{width: "5.16rem", height: "3.16rem", padding: "0.2rem"}}
                                  className="card-item_cover" src={item.cover}/>}>
                </Card>
            </Col>
            <Col span={12} xs={24} sm={24} md={12}>
                <Card bordered={false}>
                    <div className="line"></div>
                    <div dangerouslySetInnerHTML={{__html: item.content}}></div>
                </Card>
            </Col>
        </Row>
    };

    render() {
        const {listData} = sourceData;
        return (
          <section className="venue-part container">
              <div className="venue">
                  <h1 className="title">CONGRESS VENUE</h1>
              </div>
              <div className="ad">
                  <img alt="pic" className="full-width"
                       src={require('../../../resource/images/congressvenue_banner.png')}/>
              </div>
              <div className="venue-content">
                  {
                      listData.map((item, index) => {
                          return this.renderListData(item, index)
                      })
                  }
              </div>
          </section>
        )
    }
}