import React from 'react';
import { connect } from 'react-redux';
import { Form, message, Upload, Button, Col, Row, notification, Input } from 'antd';
import { withRouter } from 'react-router';
import axios from '../../../axios/api.request';
import { host, baseUrl } from '../../../config';
import { userInfo as getUserInfo } from '../../../redux/action';
import { PlusOutlined } from '@ant-design/icons';

function beforeUpload (file) {
    const isJPG = file.type === 'image/jpeg';
    const isPNG = file.type === 'image/png';
    const isPDF = file.type === 'application/pdf';

    const isPic = isJPG || isPNG || isPDF;

    if (!isPic) {
        message.error('You can only upload JPG, PDF PNG,file!');
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
        message.error('Image must smaller than 5MB!');
    }
    return isPic && isLt5M;
}

class Reload extends React.Component {
    state = {
        fileList: [],
    };

    formRef = React.createRef();

    handleChange = (info) => {
        let { fileList, file } = info;
        const status = file.status;
        if (status !== 'uploading') {
            fileList = fileList.filter((v) => {
                if (v.response) {
                    return v.response.code === 200;
                }
                return false;
            });
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
        //重新设置state
        this.setState({ fileList: fileList });
    };

    normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    componentWillMount () {
        const { userInfo } = this.props;
        let { dispatch } = this.props;
        dispatch(getUserInfo(this.props.userToken, this.props.userToken));
        let files;
        if (userInfo.regiatration_type === 3) { //  Young investigator
            files = userInfo.young_file;
        } else if (userInfo.regiatration_type === 4) { // Fellow
            files = userInfo.fellow_file;
        } else if (userInfo.regiatration_type === 2) { // OESO Member

        }
        if (files) {
            let defaultFileList = [];
            // fileList
            let fileList = files.split(',') || [];
            fileList.forEach((v, k) => {
                defaultFileList.push({
                    uid: k.toString(),
                    name: v.name,
                    status: 'done',
                    response: {
                        code: 200,
                        data: `${v}`,
                        message: 'Uploaded successfully',
                    }, // custom error message to show
                    url: `${host}${v}`,
                });
            });
            this.setState({
                fileList: defaultFileList,
            });
        }

    }

    investigatorTpl (type) {
        const { register_status, regiatration_type, oeso_member } = this.props.userInfo;
        const oType = Number(regiatration_type);
        const oStatus = Number(register_status);
        const uploadFileApi = `${baseUrl}/upload`;
        const label = 'related documents';
        const uploadProps = {
            action: uploadFileApi + '?api_token=' + this.props.userToken,
            onChange: this.handleChange,
            multiple: true,
            listType: 'picture-card',
            method: 'post',
            name: 'file',
            defaultFileList: this.state.fileList,
            fileList: this.state.fileList,

        };
        return (
            <div>
                <h3 className="title-absolute">
                    <div
                        className="title-text">{oType === 4 ? 'Fellow' : oType === 2 ? 'OESO Member' : 'Young Investigator'}</div>
                </h3>
                {oType === 2 ? (
                    <Form.Item
                        name="oeso_member"
                        label={label}
                        rules={[
                            {
                                message: 'Please input OESO Member!',
                                required: true
                            },
                        ]}
                        initialValue={oeso_member ? oeso_member : null}
                    >
                        <Input
                            disabled={oStatus !== 2}
                            placeholder="Please provide your OESO member No.(shown on the receipt of payment)"
                        />
                    </Form.Item>
                ) : (
                    <Form.Item
                        name="fellow_file"
                        label={label}
                        rules={[
                            {
                                message: 'Please upload files!',
                                required: true
                            },
                        ]}
                        initialValue={this.state.fileList ? this.state.fileList : null}
                        valuePropName="fellow_file"
                        getValueFromEvent={this.normFile}
                    >
                        <Upload beforeUpload={beforeUpload} {...uploadProps}>
                            {
                                oStatus === 2 ? <div>
                                    <PlusOutlined/>
                                    <div className="ant-upload-text">Upload</div>
                                </div> : ''
                            }
                        </Upload>
                        {oStatus === 2 && <p style={{ padding: '15px 0' }}>※The following files are allowed: pdf ,jpg,png</p>}
                    </Form.Item>
                )}
            </div>
        );
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { regiatration_type } = this.props.userInfo;
        const oType = Number(regiatration_type);
        this.formRef.current.validateFields().then((values) => {
            let params = {
                card_message: '',
                api_token: this.props.userToken,
            };
            if (oType === 2) {
                params.card_message = values.oeso_member;
            } else {
                let fileList = [];
                this.state.fileList.forEach(item => {
                    fileList.push(item.response.data);
                });
                params.card_message = fileList.join(',');
            }

            axios.request({
                url: '/upload_again',
                method: 'post',
                data: params,
            }).then(res => {
                let type = 'success';
                if (Number(res.data.code) === 200) {
                    let { dispatch } = this.props;
                    dispatch(getUserInfo(this.props.userToken));
                } else {
                    type = 'error';
                }
                notification[type]({
                    message: 'System Tips',
                    description: res.data.message || '',
                });
            });
        }).catch(() => {
            //
        });
    };

    render () {
        const { young_file, fellow_file, register_status } = this.props.userInfo;
        const Ostatus = Number(register_status);
        return (
            <div className="forgot-page">
                <div className="container">
                    <div className="login-content">
                        <Row gutter={24} type="flex" align="middle">
                            <Col md={{ span: 20, offset: 4 }}>
                                <h1
                                    className="subject-title"
                                >{Ostatus === 2 ? 'The supporting documents you uploaded are not approved, please re-upload.' : 'Your registration information is under review, please wait patiently.'}</h1>
                            </Col>
                            <Col span={12} xs={24} sm={24} md={{ span: 8, offset: 4 }}>
                                <Form ref={this.formRef} layout="vertical" scrollToFirstError>
                                    {(fellow_file || young_file) ? this.investigatorTpl(1) : ''}
                                    {register_status === 2 && (
                                        <Form.Item>
                                            <Button onClick={this.handleSubmit} className="submit-btn" block
                                                    type="primary">重新上传</Button>
                                        </Form.Item>
                                    )}
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        userInfo: state.userInfo,
        userToken: state.userToken,
    };
};

export default connect(mapStateToProps)(withRouter(Reload));
