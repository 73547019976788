import React from 'react'

import './index.less'
import {Button, notification} from "antd";
import axios from "../../../axios/api.request";

// const Step = Steps.Step;
export default class Success extends React.Component {
    constructor(props) {
        super(props);
        let {params} = this.props.match.params;
        this.state = {
            params: params,
            codeResult:  1,
            countDown: '',
            count: 60,
            timer: {},
        }
    }
    componentWillMount(){
        let code_result =localStorage.getItem('code_result_1');
        if (code_result === null || code_result === 1) {
            code_result = 1
        } else  {
            code_result = 0;
        }
        let count = Number(localStorage.getItem('count_1') || 60);
        this.setState({
            codeResult: code_result,
            count: count,
            timer: {}
        });
    }
    componentDidMount() {
        let isRun = Number(localStorage.getItem('code_valid_1'));
        if ((!this.state.codeResult) && isRun) {
            this.countTime()
        }
    }
    componentWillUnmount() {
        localStorage.removeItem('code_result_1');
        localStorage.removeItem('count_1');
        localStorage.removeItem('code_valid_1');
        this.setState({
            codeResult: 1,
            count: 60,
            timer: {}
        });
        clearTimeout(this.state.timer);
    }
    toNavigate = () => {
        let {push} = this.props.history;
        push('/login');
    };
    getCodeHandler = () => {
        let that = this;
        const email = this.state.params;
        axios.request({
            url: '/send',
            method: 'post',
            data: {
                email: email
            }
        }).then((res) => {
            let resData = res.data;
            if (Number(resData.code) === 200) {
                that.countTime();
                localStorage.setItem('code_valid_1', 1);
            }
            notification.info({
                message: 'system notice',
                description: res.data.message || 'register success'
            })

        })
    };

    //60秒验证码
    countTime = () => {
        var vm = this;
        let count = vm.state.count;
        if (count === 0) {
            this.setState({
                codeResult: 1,
                count: 60
            })
        } else {
            this.setState({
                codeResult: 0,
                count: count - 1
            });
            vm.state.timer = setTimeout(() => {
                vm.countTime();
            }, 1000);
        }
        localStorage.setItem('code_result_1', this.state.codeResult);
        localStorage.setItem('count_1', this.state.count);
        this.setState({
            countDown: "Resend activation email(" + count + ")s",
        });
    };
    render() {
        let {params} = this.state;
        return (
          <div className="container register-success steptwo">
              <h1 className="title">Create New Account</h1>
              <dl className="user-info">
                  <dt>Welcome to IACR 2024 !</dt>
              </dl>
              <dl className="user-info">
                  <dd>
                      <span>Please check</span>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a>&nbsp;&nbsp;{params}&nbsp;&nbsp;</a>
                      <span>to activate your account.</span>
                  </dd>
              </dl>
              <div className="next-btn_div">
                  <Button type="button" className="ant-btn next-btn ant-btn-primary ant-btn-lg"
                          onClick={this.toNavigate}>
                      <span>I have completed activation</span>
                  </Button>
                  <div className="bottom-text">
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      {this.state.codeResult ?<a onClick={this.getCodeHandler}>Resend activation email</a>:<p>{this.state.countDown}</p>}
                  </div>
              </div>
          </div>
        )
    }
}
