import React, {Component} from 'react';
import {Steps, Button} from 'antd';
import './index.less';
import connect from "react-redux/es/connect/connect";
import { LeftOutlined } from '@ant-design/icons';

const Step = Steps.Step;


class StepTwo extends Component {
    constructor(props) {
        super(props);
        let {params} =  this.props.match.params;
        this.state = {
            params: JSON.parse(params)
        }
    }
    componentWillMount() {
        let {history} = this.props;
        if (this.props.userToken) {
            history.push("/register/instruction")
        }
    }
    back() {
        window.location.href = '#/register/StepOne';
    }

    toLogin = () =>{
        window.location.href = `#/common/register/success/${JSON.stringify(this.state.params)}`;
    };

    render() {
        let {params} = this.state;
        return (
          <div className="steptwo">
              <div className="container ">
                  <h1 className="title"> Create New Account</h1>
                  <div className="steps-wrap">
                      <Steps progressDot current={2}>
                          <Step title="Instruction"/>
                          <Step title="Registration"/>
                          <Step title="Complete"/>
                      </Steps>
                  </div>
                  <div className="prev-btn_div">
                      <Button type="primary" onClick={this.back}>
                          <LeftOutlined/> Back
                      </Button>
                  </div>

                  <section className="confirm">
                      <h3>Welcome to OESO!</h3>
                      <p>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          Your Account <a>{params.email}</a> has been registered,please verify it by clicking the activation link that has been sent to
                          this email address.
                      </p>
                  </section>

                  <div className="next-btn_div">
                      <Button type="primary" size="large" className="next-btn" onClick={this.toLogin}>I have completed
                          activation</Button>
                  </div>


                  <section className="explain">
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a>If you do not receive the activation link</a>
                      <p>If you do not receive our mail to activate account, the following are some of the possible
                          reasons for the problem.</p>
                      <p>1. You typed in an incorrect email address.</p>
                      <p>2. Slow mail processing at your email server.</p>
                      <p>3. Your mailbox has exceeded quota.</p>
                      <p>4. Spam filters configured at your mailbox or email server have marked the email as spam.</p>
                      <p>5. There are connection issue with your email server.</p>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <p>6. <a>{params.email}</a>
                          could not address issues related to your mailbox or mail server. You could probably try to
                          use an email account such as Hotmail, Outlook,
                          Gmail, or Yahoo! Mail. You will be able to change email address with your account later.
                      </p>
                  </section>
              </div>
          </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        userToken: state.userToken
    }
};
export default connect(mapStateToProps)(StepTwo);
