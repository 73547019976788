import React, { PureComponent } from 'react';
import { Menu, Space } from 'antd';
import { UserOutlined, LoginOutlined } from '@ant-design/icons';
import HeaderDropdown from '../HeaderDropdown';
import { NavLink } from 'react-router-dom';

export default class GlobalHeaderRight extends PureComponent {
    changeReadState = clickedItem => {
        const { id } = clickedItem;
        const { dispatch } = this.props;
        dispatch({
            type: 'global/changeNoticeReadState',
            payload: id,
        });
    };

    render () {
        const {
            currentUser,
            onMenuClick,
            // onMenuClickHandle,
        } = this.props;

        const menu = (
            <Menu className="menu" selectedKeys={[]} onClick={onMenuClick}>
                <Menu.Item key="/admin/pre">
                    abstract upload
                </Menu.Item>
                <Menu.Item key="/poster/upload">
                    poster upload
                </Menu.Item>
                <Menu.Divider/>
                <Menu.Item key="/logout">
                    <LoginOutlined/> logout
                </Menu.Item>
            </Menu>
        );

        return (
            <Space className="right">
                <HeaderDropdown
                    overlay={
                        <Menu className="menu">
                            <Menu.Item>
                                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                <a href="https://www.iacr2024.com/static/media/program-at-a-glance.jpg" target="_blank">Program at a glance</a>
                            </Menu.Item>
                        </Menu>
                    }
                >
                    <div className="action account">
                        <span className="name">Conference Guide</span>
                    </div>
                </HeaderDropdown>

                {currentUser.name ? (
                    <Space>
                        <div className="account">
                            <NavLink to="/registration/list">Account</NavLink>
                        </div>

                        <HeaderDropdown overlay={menu}>
                            <div className="action account">
                                <UserOutlined
                                    style={{ color: '#333', fontSize: '18px' }}
                                    className="avatar"
                                />
                                <span className="name">{currentUser.name}</span>
                            </div>
                        </HeaderDropdown>
                    </Space>
                ) : (
                    <div>
                        <NavLink style={{ marginRight: 20 }} to="/login">Log in</NavLink>
                    </div>
                )}
            </Space>
        );
    }
}
