import React from 'react';
import { Form, Input, Row, Col, Button, Card } from 'antd';
import { connect } from 'react-redux';
import { logout } from '../../../redux/action';
import axios from '../../../axios';

import './index.less';

class RegistrationForm extends React.Component {
    formRef = React.createRef();

    state = {
        loading: false,
        confirmDirty: false,
        autoCompleteResult: [],
    };

    handleConfirmBlur = (e) => {
        const value = e.target.value;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };
    // 新密码校验
    compareToFirstPassword = (rule, value, callback) => {
        if (value && value !== this.formRef.current.getFieldValue('new_password')) {
            callback('Two passwords that you enter is inconsistent!');
        } else {
            callback();
        }
    };
    // 确认密码校验
    validateToNextPassword = (rule, value, callback) => {
        if (value && this.state.confirmDirty) {
            this.formRef.current.validateFields(['confirm_password']);
        }
        callback();
    };
    // 返回
    goBack = () => {
        window.history.go(-1);
    };

    render () {
        return (
            <div className="update-password container">
                <h1 className="title">PERSONAL INFORMATION</h1>
                <h3 className="subject-title">Change your password</h3>
                <h5>You will change the password for: {this.props.userInfo.email}</h5>
                <Row>
                    <Col md={{ span: 10, offset: 7 }}>
                        <Card bordered={false}>
                            <Form
                                ref={this.formRef}
                                layout="vertical"
                                scrollToFirstError
                                onFinish={values => {
                                    values['api_token'] = this.props.userToken;

                                    this.setState({ loading: true });
                                    axios.ajax({
                                        url: '/change_password',
                                        method: 'post',
                                        data: {
                                            values,
                                        },
                                    }).then(res => {
                                        if (res.code === 200) {
                                            let { dispatch, history } = this.props;
                                            dispatch(logout());
                                            history.push('/login');
                                        }
                                        this.setState({ loading: false });
                                    }).catch(() => {
                                        this.setState({ loading: false });
                                    });
                                }}
                            >
                                <Form.Item
                                    name="current_password"
                                    label="current password"
                                    rules={[
                                        {
                                            message: 'Please input your current password!',
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input.Password type="password"/>
                                </Form.Item>
                                <Form.Item
                                    name="new_password"
                                    label="Password"
                                    rules={[
                                        {
                                            required: true, message: 'Please input your new password!',
                                        },
                                        {
                                            validator: this.validateToNextPassword,
                                        },
                                    ]}
                                >
                                    <Input.Password type="password"/>
                                </Form.Item>
                                <Form.Item
                                    name="confirm_password"
                                    label="Confirm Password"
                                    rules={[
                                        {
                                            required: true, message: 'Please confirm your password!',
                                        },
                                        {
                                            validator: this.compareToFirstPassword,
                                        },
                                    ]}
                                >
                                    <Input.Password type="password" onBlur={this.handleConfirmBlur}/>
                                </Form.Item>
                                <Form.Item>
                                    <Row>
                                        <Col xs={10} sm={10} md={{ span: 10 }}>
                                            <Button
                                                onClick={this.goBack}
                                                className="submit-btn cancel-btn"
                                                block
                                            >Cancel</Button>
                                        </Col>
                                        <Col xs={{ span: 11, offset: 2 }} sm={{ span: 11, offset: 2 }}
                                             md={{ span: 12, offset: 2 }}>
                                            <Button
                                                loading={this.state.loading}
                                                className="submit-btn"
                                                block
                                                type="primary"
                                                htmlType="submit"
                                            >Change Password</Button>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </div>);
    }
}

const mapStateToProps = state => {
    return {
        userInfo: state.userInfo,
        userToken: state.userToken,
    };
};

export default connect(mapStateToProps)(RegistrationForm);
