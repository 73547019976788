import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {logout} from '../../redux/action';
import RightContent from './RightContent';
import {withRouter} from 'react-router'
import './index.less';

class GlobalHeader extends PureComponent {
    componentWillUnmount() {
        // this.triggerResizeEvent.cancel();
    }

    triggerResizeEvent() {
        const event = document.createEvent('HTMLEvents');
        event.initEvent('resize', true, false);
        window.dispatchEvent(event);
    }

    toggle = () => {
        const {collapsed, onCollapse} = this.props;
        onCollapse(!collapsed);
        this.triggerResizeEvent();
    };
    onMenuClickHandler = () => {
        let {history} = this.props;
        let pathname = history.location.pathname;
        let {currentUser} = this.props;
        if (currentUser.payStatus === 1 && pathname !== '/admin/fee') {
            history.push('/admin/information/update');
        } else {
            history.push('/admin/fee');
        }
    };

    handleMenuClick = ({key}) => {
        const {dispatch, history} = this.props;
        if (key === '/logout') {
            dispatch(logout());
            history.push('/login');

            return;
        }

        if (key === '/poster/upload') {
            history.push('/poster/upload');

            return;
        }

        const {currentUser} = this.props;

        if (currentUser.payStatus === 1) {
            history.push('/admin/pre');
            return;
        }

        this.props.onClickMenu(key)
    };

    render() {
        const {isMobile, logo} = this.props;
        return (
          <div className="header">
              {isMobile && (
                <Link to="/" className="logo" key="logo">
                    <img src={logo} alt="logo"/>
                </Link>
              )}

              <RightContent
                  onMenuClick={this.handleMenuClick.bind(this)}
                  onMenuClickHandle={this.onMenuClickHandler}
                  {...this.props}
              />
          </div>
        );
    }
}

export default connect()(withRouter(GlobalHeader))
